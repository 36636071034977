import { instance } from "./index";

const downloadManual = () => {
    return window.open(`${process.env.REACT_APP_API_URL}/manual/download`);
};

const downloadManager = {
    downloadManual,
};

export default downloadManager;
