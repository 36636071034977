import React from "react";
import styled from "styled-components";

import { RoundButton } from "../common";
import FaceImg from "../../assets/images/sad-face.png";
import { ModalButtonWrap, ModalBody } from "./Modal";

const TITLE_TEXT = "Free Trial 버전 무료 체험 기간이 \nN일 후, 종료됩니다.";
const MODAL_TEXT =
    "이용권 종료 시 프리미엄 기능 배너가 사라져요! \n계속이용하려면, 구독해 주세요.";
const SubscribeModal = () => {
    const onModalBtnClick = () => {
        console.log("click subscribe button");
    };

    return (
        <ModalBody $width={480}>
            <ModalBodyContent>
                <img src={FaceImg} alt={"free trial img"} />
                <p className="title">{TITLE_TEXT}</p>
                <p>{MODAL_TEXT}</p>
            </ModalBodyContent>
            <ModalButtonWrap>
                <BtnWrap>
                    <ModalBtn onClick={onModalBtnClick}>구독하기</ModalBtn>
                </BtnWrap>
            </ModalButtonWrap>
        </ModalBody>
    );
};

const ModalBodyContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        display: block;
        width: 56px;
    }

    p {
        white-space: pre-wrap;
        margin-top: 12px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.01em;
        color: var(--gray-700);
        &.title {
            margin-top: 20px;
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            color: var(--gray-black);
        }
    }
`;

const BtnWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ModalBtn = styled(RoundButton)`
    padding: 0 56px;
`;

export default SubscribeModal;
