import { instance } from "./index";

import { IMAGE_MODAL_TAB_NAMES } from "../constants";

const getImages = (bannerId) => {
    return instance.get(`/image/banner/${bannerId}`);
};
const getImageById = (imageId) => {
    return instance.get(`/image/${imageId}`);
};
const createImage = (param) => {
    const { create_type } = param;
    let payload = {
        BannerId: param.BannerId,
        exposure: true,
        exposure_period: false,
        create_type: create_type,
    };
    if (create_type === IMAGE_MODAL_TAB_NAMES.VIDEO) {
        payload.is_video = true;
        payload.file_url = param.video_url;
        payload.autoPlay = param.autoPlay;
        payload.loopPlay = param.loopPlay;
    } else {
        payload.is_video = false;
        payload.set_target = param.set_target;
        if (param.url) payload.url = param.url;
        payload.texts = param.texts;

        if (create_type === IMAGE_MODAL_TAB_NAMES.URL) {
            payload.file_url = param.imageExternalUrl
                ? param.imageExternalUrl
                : "";
        } else {
            payload.file_url = param.file_url;
        }
    }
    return instance.post(`/image`, payload);
};

const updateImage = (data) => {
    const { imageId, param } = data;
    const { create_type } = param;

    let payload = {
        BannerId: param.BannerId,
        exposure: param.exposure,
        exposure_period: param.exposure_period,
        create_type: create_type,
    };
    if (param.period_start) payload.period_start = param.period_start;
    if (param.period_end) payload.period_end = param.period_end;

    if (create_type === IMAGE_MODAL_TAB_NAMES.VIDEO) {
        payload.is_video = true;
        payload.file_url = param.video_url;
        payload.autoPlay = param.autoPlay;
        payload.loopPlay = param.loopPlay;
    } else {
        if (param.is_video !== null) payload.is_video = param.is_video;
        payload.set_target = param.set_target;
        // if (param.url) {
        //     payload.url = param.url;
        // }
        payload.url = param.url;
        payload.texts = param.texts;

        if (create_type === IMAGE_MODAL_TAB_NAMES.URL) {
            payload.file_url = param.imageExternalUrl
                ? param.imageExternalUrl
                : "";
        } else {
            payload.file_url = param.file_url;
        }
    }

    return instance.patch(`/image/${imageId}`, payload);
};

const uploadImage = (formData) => {
    return instance.post(`/image/upload`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};
const updateImageGroup = (param) => {
    return instance.patch(`/image`, param);
};
const removeImage = (param) => {
    const { ids, BannerId } = param;
    return instance.delete(`/image`, {
        data: {
            ids,
            BannerId,
        },
    });
};

const imageManager = {
    getImages,
    getImageById,
    createImage,
    uploadImage,
    updateImage,
    updateImageGroup,
    removeImage,
};

export default imageManager;
