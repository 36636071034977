import React, { useState, useEffect } from "react";
import {
    Input,
    Textarea,
    Radio,
    Checkbox,
    SelectBox,
} from "../../components/common";
import {
    InfoLabel,
    Tooltip,
    Chip,
    SubscribeDimmer,
    Notice,
} from "../../components";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import { EffectFade } from "swiper";
import { useDispatch, useSelector } from "react-redux";
import {
    resetSelectedProducts,
    setSelectedProducts,
} from "../../redux/ProductSlice";
import { updateBannerGroupState } from "../../redux/BannerGroupSlice";
import { openModal } from "../../redux/ModalSlice";
import { MODAL_PAYLOAD, DEFAULT_CUSTOM_CODE } from "../../constants";

import IcTrash from "../../assets/images/icons/ic-trash.svg";
import IcRefresh from "../../assets/images/icons/ic_refresh@2x.png";
import {
    setCheckCategories,
    setChips,
    setSpreadedCategories,
    setCategories,
    emptyCategories,
} from "../../redux/CategorySlice";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { openConfirm } from "../../redux/ConfirmSlice";
import paymentManager from "../../apis/paymentManager";
import categoryManager from "../../apis/categoryManager";

const SPEED_OPTIONS = [
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
    { label: 7, value: 7 },
    { label: 8, value: 8 },
    { label: 9, value: 9 },
    { label: 10, value: 10 },
    { label: 11, value: 11 },
    { label: 12, value: 12 },
    { label: 13, value: 13 },
    { label: 14, value: 14 },
    { label: 15, value: 15 },
];

const BannerConfig = ({ openTutorialModal }) => {
    const dispatch = useDispatch();
    const [customTab, setCustomTab] = useState("html");
    const { data: paymentInfo, error: getPaymentInfoError } = useQuery({
        queryKey: ["paymentInfo"],
        queryFn: paymentManager.getPayment,
    });
    const { data: categoryRes, refetch } = useQuery({
        queryKey: ["categories"],
        queryFn: categoryManager.getCategories,
    });
    const selectedProducts = useSelector(
        (state) => state.product.selectedProducts
    );
    const { checkedCategories, chips, spreadedCategories, linkedCategories } =
        useSelector((state) => state.category);
    const bannerGroupState = useSelector((state) => state.bannerGroup);

    useEffect(() => {
        if (categoryRes) {
            dispatch(setCategories(categoryRes));
            if (!Object.keys(spreadedCategories).length) {
                initSpreadableCategories(categoryRes);
            }
        }
    }, [categoryRes]);

    useEffect(() => {
        chipsGenerator();
    }, [checkedCategories]);

    const onChangeInput = (e, key) => {
        const val = e.target.value;
        dispatch(updateBannerGroupState({ [key]: val }));
    };

    const openCategoryConnectModal = () => {
        if (paymentInfo.version === "Free") {
            dispatch(
                openConfirm({
                    confirmText: "구독하기",
                    hasImage: true,
                    isSingleButton: true,
                    notCloseOnClickDimmed: true,
                    text: `[카테고리 연결]은 프리미엄 전용 서비스입니다.\n구독하시면 해당 기능을 이용하실 수 있습니다.`,
                    confirmFunc: () => {
                        openPayment();
                    },
                })
            );
        } else if (paymentInfo.version === "Free Trial") {
            dispatch(
                openConfirm({
                    confirmText: "구독하기",
                    hasImage: true,
                    isSingleButton: true,
                    notCloseOnClickDimmed: true,
                    text: `${paymentInfo.d_day}일 후 [카테고리 연결]서비스가 종료됩니다.\n구독하시면 해당 기능을 이용하실 수 있습니다.`,
                    confirmFunc: () => {
                        openPayment();
                    },
                    closeFunc: () => {
                        dispatch(
                            openModal({ ...MODAL_PAYLOAD.CATEGORY_CONNECT })
                        );
                    },
                })
            );
        } else {
            dispatch(openModal({ ...MODAL_PAYLOAD.CATEGORY_CONNECT }));
        }
        refetch();
    };

    const openProductConnectModal = async () => {
        if (paymentInfo.version === "Free") {
            dispatch(
                openConfirm({
                    confirmText: "구독하기",
                    hasImage: true,
                    isSingleButton: true,
                    notCloseOnClickDimmed: true,
                    text: `[상품 연결]은 프리미엄 전용 서비스입니다.\n구독하시면 해당 기능을 이용하실 수 있습니다.`,
                    confirmFunc: () => {
                        openPayment();
                    },
                })
            );
        } else if (paymentInfo.version === "Free Trial") {
            dispatch(
                openConfirm({
                    confirmText: "구독하기",
                    hasImage: true,
                    isSingleButton: true,
                    notCloseOnClickDimmed: true,
                    text: `${paymentInfo.d_day}일 후 [상품 연결]서비스가 종료됩니다.\n구독하시면 해당 기능을 이용하실 수 있습니다.`,
                    confirmFunc: () => {
                        openPayment();
                    },
                    closeFunc: () => {
                        dispatch(
                            openModal({ ...MODAL_PAYLOAD.PRODUCT_SEARCH })
                        );
                    },
                })
            );
        } else {
            dispatch(openModal({ ...MODAL_PAYLOAD.PRODUCT_SEARCH }));
        }
    };

    const onChangeRadio = (e, key) => {
        const val = e.target.value;
        if (val === "custom") {
            if (paymentInfo.version === "Free") {
                e.preventDefault();
                e.target.checked = false;

                return dispatch(
                    openConfirm({
                        confirmText: "구독하기",
                        hasImage: true,
                        isSingleButton: true,
                        notCloseOnClickDimmed: true,
                        text: `[Custom] 효과 설정은 프리미엄 전용 서비스입니다.\n구독하시면 해당 기능을 이용하실 수 있습니다.`,
                        confirmFunc: () => {
                            openPayment();
                        },
                    })
                );
            } else if (paymentInfo.version === "Free Trial") {
                dispatch(
                    openConfirm({
                        confirmText: "구독하기",
                        hasImage: true,
                        isSingleButton: true,
                        notCloseOnClickDimmed: true,
                        text: `${paymentInfo.d_day}일 후 [Custom] 효과 서비스가 종료됩니다.\n구독하시면 해당 기능을 계속 이용하실 수 있습니다.`,
                        confirmFunc: () => {
                            openPayment();
                        },
                    })
                );
            }
        }
        dispatch(updateBannerGroupState({ [key]: val }));
    };

    const openPayment = async () => {
        const result = await paymentManager.createPayment({
            redirect_url: window.location.href,
        });
        window.open(result, "_blank");
    };

    const onChangeCheckbox = (e, key) => {
        dispatch(updateBannerGroupState({ [key]: e.target.checked }));
    };

    const removeLinkedProduct = (product) => {
        const arr = selectedProducts.filter(
            (item) => item.product_no !== product.product_no
        );
        dispatch(setSelectedProducts(arr));
    };

    const removeLinkedCategory = (category) => {
        const arr = linkedCategories.filter(
            (item) => item.category_no !== category.category_no
        );
    };

    const removeAllLinkedProduct = () => {
        dispatch(resetSelectedProducts());
    };

    const onTimeSelect = (value) => {
        dispatch(updateBannerGroupState({ speed: value }));
    };

    const resetCustomCode = () => {
        dispatch(updateBannerGroupState({ banner_code: DEFAULT_CUSTOM_CODE }));
    };

    const resetCustomSwiperConfig = () => {
        dispatch(
            updateBannerGroupState({
                swiper_config: "",
            })
        );
    };

    const onChipClick = (item) => {
        checkItem(item.self, false);
    };

    const resetAllCategories = () => {
        dispatch(emptyCategories());
    };

    // modal에서 옮겨짐, redux로 관리
    const initSpreadableCategories = (categories) => {
        let spreadObj = {};
        let obj = {};
        categories.forEach((cate) => {
            spreadObj[cate.category_no] = cate;
            obj[cate.category_no] = false;
            if (cate.children.length > 0) {
                initSpreadableCategories(cate.children);
            }
        });
        dispatch(setCheckCategories(obj));
        dispatch(setSpreadedCategories(spreadObj));
    };

    const chipsGenerator = () => {
        let checkedArr = [];
        let chips = [];
        for (let categoryNo in checkedCategories) {
            if (checkedCategories[categoryNo]) {
                checkedArr.push(spreadedCategories[categoryNo]);
            }
        }
        checkedArr.forEach((item) => {
            if (item) {
                // 선택한 분류에 하위 분류가 있는 경우 선택이 되지 않는 이슈가 있어 임시 조치
                // if (item.children?.length === 0) {
                    let parentCategoryNos = [];
                    let parentCategoryNames = [];
                    pushParent(item, parentCategoryNos, parentCategoryNames);
                    chips.push({
                        self: item,
                        parentCategoryNos: parentCategoryNos.reverse(),
                        parentCategoryNames: parentCategoryNames.reverse(),
                    });
                // }
            }
        });
        dispatch(setChips(chips));
    };

    const pushParent = (item, parentCategoryNos, parentCategoryNames) => {
        if (item.parent_category_no === 1) return;
        const parentNode = spreadedCategories[item.parent_category_no];
        parentCategoryNos.push(parentNode.category_no);
        parentCategoryNames.push(parentNode.category_name);
        if (parentNode.parent_category_no !== 1) {
            pushParent(parentNode, parentCategoryNos, parentCategoryNames);
        }
    };

    const checkItem = (item, isChecked) => {
        let copyCategories = JSON.parse(JSON.stringify(checkedCategories));
        copyCategories[item.category_no] = isChecked;
        checkChildren(item, isChecked, copyCategories);
    };

    const checkChildren = (item, isChecked, copyCategories) => {
        item.children.forEach((child) => {
            copyCategories[child.category_no] = isChecked;
            if (child.children.length > 0) {
                child.children.forEach((grandChild) => {
                    copyCategories[grandChild.category_no] = isChecked;
                    if (grandChild.children.length > 0) {
                        grandChild.children.forEach((lastDepthChild) => {
                            copyCategories[lastDepthChild.category_no] =
                                isChecked;
                        });
                    }
                });
            }
        });
        checkParent(item, isChecked, copyCategories);
    };

    const checkParent = (item, isChecked, copyCategories) => {
        const parentNode = spreadedCategories[item.parent_category_no];
        if (isChecked) {
            if (item.parent_category_no !== 1) {
                let isAllCheck = true;
                if (
                    parentNode.children.length === 1 &&
                    !copyCategories[parentNode.children[0].category_no]
                ) {
                    isAllCheck = false;
                } else {
                    for (let i = 0; i < parentNode.children.length; i++) {
                        const self = parentNode.children[i];
                        if (self.category_no !== item.category_no) {
                            if (!copyCategories[self.category_no]) {
                                isAllCheck = false;
                            }
                        }
                    }
                }
                copyCategories[parentNode.category_no] = isAllCheck;
                checkParent(parentNode, isChecked, copyCategories);
            } else {
                dispatch(setCheckCategories(copyCategories));
            }
        } else {
            if (item.parent_category_no !== 1) {
                copyCategories[parentNode.category_no] = false;
                checkParent(parentNode, isChecked, copyCategories);
            } else {
                dispatch(setCheckCategories(copyCategories));
            }
        }
    };

    return (
        <>
            <div className="bnr_defult_set set_sction">
                <div className="title_wrap">
                    <div className="title_area">
                        <h2>배너 기본 설정</h2>
                    </div>
                </div>
                <div className="setting_area iv_box">
                    <table className="in_table">
                        <colgroup>
                            <col width="160"></col>
                            <col width=""></col>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>
                                    배너 그룹명 <span>*</span>
                                </th>
                                <td>
                                    <div className="wrap">
                                        <Input
                                            onChange={(e) =>
                                                onChangeInput(e, "group_name")
                                            }
                                            size={"s"}
                                            maxLength={15}
                                            value={bannerGroupState.group_name}
                                            error={
                                                bannerGroupState.group_name
                                                    .length >= 15
                                            }
                                            errorText={`배너그룹명은 문자, 숫자, 공백을 포함해 15자 이내로 입력해 주세요.`}
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>배너 그룹 설명</th>
                                <td>
                                    <div className="wrap">
                                        <Textarea
                                            onChange={(e) =>
                                                onChangeInput(
                                                    e,
                                                    "group_describe"
                                                )
                                            }
                                            height={140}
                                            // maxLength={100}
                                            isResizeable={false}
                                            // error={
                                            //     bannerGroupState.group_describe
                                            //         .length >= 100
                                            // }
                                            text={
                                                bannerGroupState.group_describe
                                            }
                                            // errorText={`배너 그룹 설명은 문자, 숫자, 공백을 포함해 100자 이내로 입력해 주세요.`}
                                        />
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <th>
                                    <TooltipTHWrap>
                                        <span>배너 노출 순서</span>
                                        <Tooltip
                                            text={`기본 : 이미지 목록 순서 대로 노출됩니다.\n랜덤 : 등록한 이미지가 랜덤으로 노출됩니다. `}
                                        />
                                    </TooltipTHWrap>
                                </th>
                                <td>
                                    <div className="wrap radio-flex-wrap">
                                        <Radio
                                            id={"default"}
                                            isChecked={
                                                bannerGroupState.exposure_order ===
                                                "default"
                                            }
                                            name={"exposure_order"}
                                            label={"기본"}
                                            value={"default"}
                                            onChange={(e) =>
                                                onChangeRadio(
                                                    e,
                                                    "exposure_order"
                                                )
                                            }
                                        />
                                        <Radio
                                            id={"random"}
                                            isChecked={
                                                bannerGroupState.exposure_order ===
                                                "random"
                                            }
                                            name={"exposure_order"}
                                            label={"랜덤"}
                                            value={"random"}
                                            onChange={(e) =>
                                                onChangeRadio(
                                                    e,
                                                    "exposure_order"
                                                )
                                            }
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <TooltipTHWrap>
                                        <span>배너 효과 설정</span>
                                        <Tooltip
                                            text={`배너 이미지의 전환 효과를 설정해 주세요.\n[Custom] 기능은 소스를 편집하여\n디자인과 기능을 마음껏 수정할 수 있습니다. `}
                                        />
                                    </TooltipTHWrap>
                                </th>
                                <td>
                                    <div className="wrap cell_gr">
                                        <div className="radios_wrap bnr_ef radio-flex-wrap p-20">
                                            <Radio
                                                id={"slide"}
                                                isChecked={
                                                    bannerGroupState.banner_sort ===
                                                    "slide"
                                                }
                                                name={"banner_sort"}
                                                label={"Slider"}
                                                value={"slide"}
                                                onChange={(e) =>
                                                    onChangeRadio(
                                                        e,
                                                        "banner_sort"
                                                    )
                                                }
                                            />
                                            <Radio
                                                id={"fade"}
                                                isChecked={
                                                    bannerGroupState.banner_sort ===
                                                    "fade"
                                                }
                                                name={"banner_sort"}
                                                label={"Fade in/out"}
                                                value={"fade"}
                                                onChange={(e) =>
                                                    onChangeRadio(
                                                        e,
                                                        "banner_sort"
                                                    )
                                                }
                                            />
                                            <Radio
                                                id={"custom"}
                                                isChecked={
                                                    bannerGroupState.banner_sort ===
                                                    "custom"
                                                }
                                                name={"banner_sort"}
                                                label={"Custom"}
                                                value={"custom"}
                                                onChange={(e) =>
                                                    onChangeRadio(
                                                        e,
                                                        "banner_sort"
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="slider_control">
                                            <div
                                                className={`control_chk ${
                                                    bannerGroupState.banner_sort ===
                                                    "custom"
                                                        ? "hide"
                                                        : ""
                                                }`}
                                            >
                                                <div className="com_chk bnr_close">
                                                    <Checkbox
                                                        id={"is_repeat"}
                                                        value={"is_repeat"}
                                                        name={"is_repeat"}
                                                        onChange={(e) =>
                                                            onChangeCheckbox(
                                                                e,
                                                                "is_repeat"
                                                            )
                                                        }
                                                        label={
                                                            "슬라이드 반복 재생"
                                                        }
                                                        isChecked={
                                                            bannerGroupState.is_repeat
                                                        }
                                                    />
                                                </div>
                                                <div className="com_chk bnr_close">
                                                    <Checkbox
                                                        id={"is_speed"}
                                                        value={"is_speed"}
                                                        name={"is_speed"}
                                                        onChange={(e) =>
                                                            onChangeCheckbox(
                                                                e,
                                                                "is_speed"
                                                            )
                                                        }
                                                        label={
                                                            "슬라이드 속도 설정하기"
                                                        }
                                                        isChecked={
                                                            bannerGroupState.is_speed
                                                        }
                                                    />
                                                </div>
                                                {bannerGroupState.is_speed ? (
                                                    <div className="speed_select">
                                                        <SelectBox
                                                            options={
                                                                SPEED_OPTIONS
                                                            }
                                                            size={"s"}
                                                            onSelect={
                                                                onTimeSelect
                                                            }
                                                            value={
                                                                bannerGroupState.speed
                                                            }
                                                        />
                                                        <span className="unit">
                                                            초
                                                        </span>
                                                    </div>
                                                ) : null}
                                            </div>
                                            {bannerGroupState.banner_sort !==
                                            "custom" ? (
                                                <EffectPreviewWrap>
                                                    {bannerGroupState.banner_sort ===
                                                    "slide" ? (
                                                        <Swiper
                                                            spaceBetween={0}
                                                            modules={[Autoplay]}
                                                            effect={"slide"}
                                                            speed={800}
                                                            key={"slide"}
                                                            className={
                                                                "swiper2"
                                                            }
                                                            autoplay={{
                                                                delay: bannerGroupState.speed
                                                                    ? bannerGroupState.speed *
                                                                      1000
                                                                    : 3000,
                                                                disableOnInteraction: false,
                                                            }}
                                                            loop={
                                                                bannerGroupState.is_repeat
                                                            }
                                                            onSlideChange={() => {}}
                                                            onSwiper={(
                                                                swiper
                                                            ) => {}}
                                                        >
                                                            {[1, 2].map(
                                                                (item) => (
                                                                    <SwiperSlide
                                                                        key={
                                                                            item
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={`full-div color-${item}`}
                                                                        >
                                                                            <p>{`Slider 좌우 롤링 효과\n미리보기 ${item}`}</p>
                                                                        </div>
                                                                    </SwiperSlide>
                                                                )
                                                            )}
                                                        </Swiper>
                                                    ) : bannerGroupState.banner_sort ===
                                                      "fade" ? (
                                                        <Swiper
                                                            spaceBetween={0}
                                                            modules={[
                                                                Autoplay,
                                                                EffectFade,
                                                            ]}
                                                            effect={"fade"}
                                                            className={
                                                                "swiper1"
                                                            }
                                                            key={"fade"}
                                                            speed={1200}
                                                            autoplay={{
                                                                delay: bannerGroupState.speed
                                                                    ? bannerGroupState.speed *
                                                                      1000
                                                                    : 3000,
                                                                disableOnInteraction: false,
                                                            }}
                                                            loop={
                                                                bannerGroupState.is_repeat
                                                            }
                                                            onSlideChange={() => {}}
                                                            onSwiper={(
                                                                swiper
                                                            ) => {}}
                                                        >
                                                            {[1, 2].map(
                                                                (item) => (
                                                                    <SwiperSlide
                                                                        key={
                                                                            item
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={`full-div color-${item}`}
                                                                        >
                                                                            <p>{`페이드 인/아웃 효과\n미리보기 ${item}`}</p>
                                                                        </div>
                                                                    </SwiperSlide>
                                                                )
                                                            )}
                                                        </Swiper>
                                                    ) : null}
                                                </EffectPreviewWrap>
                                            ) : (
                                                <div className="custum_in">
                                                    <h3 className="custom-menu-title">
                                                        HTML
                                                    </h3>
                                                    <NoticeWrap>
                                                        <Notice
                                                            text={`소스를 편집하여 원하는 기능을 마음껏 적용해 보세요! 아래 코드를 복사하여 배너를 노출시키고 싶은 위치게 삽입해 주세요.`}
                                                        />
                                                        <button
                                                            className="reset_button"
                                                            onClick={
                                                                resetCustomCode
                                                            }
                                                        >
                                                            <img
                                                                src={IcRefresh}
                                                                alt="리셋"
                                                            />
                                                            <span>
                                                                코드 초기화
                                                            </span>
                                                        </button>
                                                    </NoticeWrap>
                                                    <div className="custum_area">
                                                        <textarea
                                                            className="cus_textarea"
                                                            value={
                                                                bannerGroupState.banner_code
                                                            }
                                                            onChange={(e) =>
                                                                onChangeInput(
                                                                    e,
                                                                    "banner_code"
                                                                )
                                                            }
                                                        ></textarea>
                                                    </div>

                                                    <JSEditorCheckboxWrap>
                                                        <Checkbox
                                                            id={
                                                                "use_swiper_config"
                                                            }
                                                            value={
                                                                "use_swiper_config"
                                                            }
                                                            name={
                                                                "use_swiper_config"
                                                            }
                                                            onChange={(e) =>
                                                                onChangeCheckbox(
                                                                    e,
                                                                    "use_swiper_config"
                                                                )
                                                            }
                                                            label={
                                                                "Swiper 슬라이더 동작 방식 설정하기"
                                                            }
                                                            isChecked={
                                                                bannerGroupState.use_swiper_config
                                                            }
                                                        />
                                                    </JSEditorCheckboxWrap>
                                                </div>
                                            )}
                                        </div>
                                        {bannerGroupState.use_swiper_config &&
                                        bannerGroupState.banner_sort ===
                                            "custom" ? (
                                            <div className="slider_control">
                                                <div className="custum_in">
                                                    <h3 className="custom-menu-title">
                                                        Swiper 동작 방식 상세
                                                        설정
                                                    </h3>
                                                    <NoticeWrap>
                                                        <Notice
                                                            text={`Swiper 슬라이더의 동작 방식을 설정하는 영역입니다. <b>JSON형식으로 코드를 입력</b>해 주세요.<br/>소스는 반드시 사이트 (<a href="https://www.jsonformatter.io/" target="_blank">https://www.jsonformatter.io/</a>)에서 <b>단순화(Minify) 후 입력</b>해 주세요.<br/><span class="example-text">예시) {"effect":"slide","autoplay":{"delay":2000}}</span>`}
                                                        />
                                                        <button
                                                            className="reset_button"
                                                            onClick={
                                                                resetCustomSwiperConfig
                                                            }
                                                        >
                                                            <img
                                                                src={IcRefresh}
                                                                alt="리셋"
                                                            />
                                                            <span>
                                                                코드 초기화
                                                            </span>
                                                        </button>
                                                    </NoticeWrap>
                                                    <div className="custum_area">
                                                        <textarea
                                                            className="cus_textarea sibal"
                                                            value={
                                                                bannerGroupState.swiper_config ||
                                                                ""
                                                            }
                                                            onChange={(e) =>
                                                                onChangeInput(
                                                                    e,
                                                                    "swiper_config"
                                                                )
                                                            }
                                                        ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className=" set_sction">
                <div className="title_wrap">
                    <div className="title_area">
                        <h2>카테고리 연결하기</h2>
                        <InfoLabel
                            onClick={(e) =>
                                openTutorialModal(
                                    MODAL_PAYLOAD.TUTORIAL.CATEGORY_CONNECT
                                )
                            }
                            text={"카테고리 연결 사용법"}
                        />
                    </div>
                    <button
                        className="iv_btn del_btn"
                        onClick={resetAllCategories}
                    >
                        <div className="delete_icon">
                            <img src={IcTrash} alt="삭제" />
                        </div>
                        전체 삭제
                    </button>
                </div>
                <div className="bnr_state iv_box">
                    <table className="in_table cate_connect">
                        <colgroup>
                            <col width="160" />
                            <col width="" />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>카테고리 검색</th>
                                <td>
                                    <div className="wrap">
                                        <button
                                            type="button"
                                            className="iv_search_btn"
                                            onClick={openCategoryConnectModal}
                                        >
                                            카테고리 검색
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    연결된 카테고리
                                    {/*<p className="max_len"> </p>*/}
                                </th>
                                <td>
                                    {paymentInfo?.version === "Free" ? (
                                        <SubscribeDimmer />
                                    ) : null}
                                    <div
                                        className={`wrap ${
                                            paymentInfo?.version === "Free"
                                                ? "has-padding"
                                                : ""
                                        }`}
                                    >
                                        {chips?.length > 0 ? (
                                            <div className="connect_wrap">
                                                <div className="in-data">
                                                    {chips.map(
                                                        (item, index) => (
                                                            <Chip
                                                                size={"l"}
                                                                key={index}
                                                                type={"round"}
                                                                text={`${item.parentCategoryNames.join(
                                                                    " > "
                                                                )} ${
                                                                    item
                                                                        .parentCategoryNames
                                                                        .length
                                                                        ? " > "
                                                                        : ""
                                                                }`}
                                                                emphasisText={
                                                                    item.self
                                                                        .category_name
                                                                }
                                                                onClick={(e) =>
                                                                    onChipClick(
                                                                        item
                                                                    )
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="no-content-text-wrap">
                                                <p>
                                                    [카테고리 검색]을 눌러
                                                    카테고리를 연결해 주세요.
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className=" set_sction">
                <div className="title_wrap">
                    <div className="title_area">
                        <h2>상품 연결하기</h2>
                        <InfoLabel
                            onClick={(e) =>
                                openTutorialModal(
                                    MODAL_PAYLOAD.TUTORIAL.PRODUCT_CONNECT
                                )
                            }
                            text={"상품 연결 사용법"}
                        />
                    </div>
                    <button
                        className="iv_btn del_btn"
                        onClick={removeAllLinkedProduct}
                    >
                        <div className="delete_icon">
                            <img src={IcTrash} alt="삭제" />
                        </div>
                        전체 삭제
                    </button>
                </div>
                <div className="bnr_state iv_box">
                    <table className="in_table prd_connect">
                        <colgroup>
                            <col width="160" />
                            <col width="" />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>상품 검색</th>
                                <td>
                                    <div className="wrap">
                                        <button
                                            type="button"
                                            className="iv_search_btn"
                                            onClick={openProductConnectModal}
                                        >
                                            연결할 상품 검색
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    연결된 상품
                                    <p className="max_len">최대 150개</p>
                                </th>
                                <td>
                                    {paymentInfo?.version === "Free" ? (
                                        <SubscribeDimmer />
                                    ) : null}

                                    <div
                                        className={`wrap ${
                                            paymentInfo?.version === "Free"
                                                ? "has-padding"
                                                : ""
                                        }`}
                                    >
                                        {selectedProducts?.length > 0 ? (
                                            <div className="connect_wrap">
                                                <div className="in-data">
                                                    {selectedProducts.map(
                                                        (item) => (
                                                            <Chip
                                                                size={"l"}
                                                                key={
                                                                    item.product_no
                                                                }
                                                                type={"square"}
                                                                text={`${item.product_name}\n${item.product_code}`}
                                                                onClick={(e) =>
                                                                    removeLinkedProduct(
                                                                        item
                                                                    )
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="no-content-text-wrap">
                                                <p>
                                                    [연결할 상품 검색]을 눌러
                                                    상품를 연결해 주세요.
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

const TabBarWrap = styled.div`
    display: flex;
    div.custom-tab {
        position: relative;
        cursor: pointer;
        border: 1px solid var(--gray-100-e-9-e-9-ed, #e9e9ed);
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom: 0;
        width: 68px;
        height: 28px;

        display: flex;
        align-items: center;
        justify-content: center;

        color: #9b9faf;
        text-align: center;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: -0.13px;

        &.active {
            color: #7386e8;
            &:after {
                content: "";
                background-color: #7386e8;
                position: absolute;
                width: 100%;
                height: 2px;
                left: 0;
                bottom: -1px;
                z-index: 1;
            }
        }
    }
`;

const EffectPreviewWrap = styled.div`
    width: 320px;
    height: 160px;
    margin-top: 20px;
    border: 1px solid #9da9ee;
    border-radius: 3px;

    .swiper-wrapper {
        overflow: visible !important;
    }
    .swiper-fade {
        .swiper-slide {
            &:not(&.swiper-slide-active) {
                opacity: 0 !important;
                transition: opacity 0.3s ease-in-out;
            }
        }
    }

    & > div.swiper {
        width: 100%;
        height: 100%;
    }

    .full-div {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        &.color-1 {
            background: var(--primary-100);
        }

        &.color-2 {
            background: var(--primary-500);
            p {
                color: #fff;
            }
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: -0.01em;
            color: #8a8e9c;
            white-space: break-spaces;
        }
    }
`;

const JSEditorCheckboxWrap = styled.div`
    margin-top: 12px;
`;

const NoticeWrap = styled.div`
    display: flex;
    width: 100%;
    margin-top: 10px;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    & > div {
        flex: 1;
    }
    button.reset_button {
        flex-basis: 114px;
    }
`;

const TooltipTHWrap = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;

export default BannerConfig;
