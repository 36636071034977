import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ReactDatePicker from "react-datepicker";
import Input from "../Input/Input";

const DatePicker = () => {
    const [startDate, setStartDate] = useState(new Date());
    return (
        <ReactDatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            customInput={<CustomDatePicker />}
        />
    );
};

const CustomDatePicker = React.forwardRef((props, ref) => {
    return <DatePickerWrap value={""} onChange={() => {}}></DatePickerWrap>;
});

const DatePickerWrap = styled(Input)``;

DatePicker.propTypes = {};
DatePicker.defaultProps = {};

export default DatePicker;
