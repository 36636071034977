import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ModalBody } from "./Modal";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { SquareButton } from "../common";
import { Label } from "../common";
import { Tooltip } from "../index";
import { Link } from "react-router-dom";
import { showToast } from "../../redux/ToastSlice";
import IcCheck from "../../assets/images/icons/ic-check-18.svg";
import moment from "moment";
import axios from "axios";
import {
    INNERVIEW_BANNER_APPSTORE_LINK,
    CHANGE_PAYMENT_URL,
} from "../../constants";

import paymentManager from "../../apis/paymentManager";

const PREMIUM_BENEFITS = [
    "쉬운 배너 커스텀",
    "카데고리 배너 연결",
    "상품 배너 연결",
    "배너 내 텍스트 입력",
    "배너 클릭 데이터 확인",
    "배너 영상 등록",
    "배너 템플릿 제공",
    "배너 등록&관리",
];

const UserPlanInfoModal = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const [paymentInfo, setPaymentInfo] = useState({});

    useEffect(() => {
        getPaymentInfo();
    }, []);

    const getPaymentInfo = async () => {
        const data = await queryClient.getQueryData(["paymentInfo"]);
        setPaymentInfo(data);
    };

    const onClickPostPayment = async () => {
        try {
            const result = await paymentManager.createPayment({
                redirect_url: window.location.href,
            });
            window.open(result, "_blank");
        } catch (e) {
            console.log(e);
            dispatch(showToast("에러가 발생했습니다.\n이너뷰에 문의해주세요."));
        }
    };

    const openAppStore = () => {
        window.open(INNERVIEW_BANNER_APPSTORE_LINK, "_blank");
    };

    return (
        <ModalBody>
            <PaddingWrap>
                <DescriptionWrap>
                    <DescriptionList>
                        <li>
                            <p>
                                이너뷰 배너관리앱은 정기 구독 서비스로, 결제일을
                                기준으로 매월 자동 결제가 진행됩니다.
                            </p>
                        </li>
                        <li>
                            <p>
                                정기 결제를 취소하셔도 보유한 이용권의
                                잔여일까지 사용이 가능합니다.
                            </p>
                        </li>
                        <li>
                            <p>
                                기 능 오류 및 결제/환불 관련 문의는{" "}
                                <span onClick={openAppStore}>
                                    이너뷰에 문의하기
                                </span>
                                를 눌러 질문을 남겨주세요.
                            </p>
                        </li>
                    </DescriptionList>
                </DescriptionWrap>

                <InfoTableWrap>
                    <div className={"row"}>
                        <div className={"item"}>
                            <div className="key">
                                <span className={"text"}>이용 버전</span>
                            </div>
                            <div className="val">
                                <span className={"text"}>
                                    {paymentInfo.version}
                                </span>
                            </div>
                        </div>
                        <div className={"item"}>
                            <div className="key">
                                <span className={"text"}>정기 결제</span>
                                <Tooltip
                                    text={`정기 결제 해제는 관리자 > [앱] > [구매 내역]>[정기결제 관리]\n경로로 해지하실 수 있습니다.`}
                                />
                            </div>
                            <div className="val">
                                <span className={"text"}>
                                    {paymentInfo.use_regular_payment
                                        ? "사용 중"
                                        : "미사용"}
                                </span>
                                <SquareButton
                                    size={"xs"}
                                    type={"fill"}
                                    onClick={onClickPostPayment}
                                >
                                    정기 결제 신청
                                </SquareButton>
                            </div>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"item"}>
                            <div className="key">
                                <span className={"text"}>이용 기간</span>
                            </div>
                            <div className="val">
                                <span className={"text"}>
                                    {paymentInfo.version === "Free"
                                        ? "-"
                                        : `${paymentInfo.start_date} ~ ${paymentInfo.end_date}`}
                                </span>
                            </div>
                        </div>
                        <div className={"item"}>
                            <div className="key">
                                <span className={"text"}>결제 수단</span>
                                <Tooltip
                                    text={`결제 수단 변경은 정기 결제 해지 후, Premium 기간 만료 전,\n변경된 카드로 다시 정기결제를 신청해 주세요.`}
                                />
                            </div>
                            <div className="val">
                                <span className={"text"}>
                                    {paymentInfo.payment_method === "card"
                                        ? "카드 결제"
                                        : "무료 이용"}
                                </span>
                            </div>
                        </div>
                    </div>
                </InfoTableWrap>

                <BenefitsWrap>
                    <h2>
                        {paymentInfo.version === "Premium" ? (
                            <>
                                <span>{paymentInfo.mall_id}</span> 님이 누리고
                                있는 혜택
                            </>
                        ) : (
                            <>이용권을 결제하면 누릴 수 있는 혜택</>
                        )}
                    </h2>
                    <BenefitsItemWrap>
                        <Label size={"m"}>Premium ver.</Label>

                        <ul className="benefit-list">
                            {PREMIUM_BENEFITS.map((item, index) => (
                                <li className="benefit-item" key={index}>
                                    <img src={IcCheck} alt="icon" />
                                    <span>{item}</span>
                                </li>
                            ))}
                        </ul>
                    </BenefitsItemWrap>
                </BenefitsWrap>
            </PaddingWrap>
        </ModalBody>
    );
};

const BenefitsItemWrap = styled.div`
    margin-top: 16px;
    padding: 24px;
    background: var(--primary-50);
    border-radius: 4px;

    ul {
        margin-top: 16px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 8px;
        li {
            display: flex;
            align-items: center;
            flex-basis: 23.5%;
            gap: 8px;

            span {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: -0.01em;
                color: #282b39;
            }
        }
    }
`;

const BenefitsWrap = styled.div`
    margin-top: 32px;
    h2 {
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.01em;

        span {
            color: var(--primary-500);
        }
    }
`;

const InfoTableWrap = styled.div`
    margin-top: 20px;
    div.row {
        width: 100%;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #eff1fa;

        &:first-of-type {
            border-top: 1px solid #eff1fa;
        }

        .item {
            flex-basis: 50%;
            height: 60px;
            display: flex;
            align-items: center;

            span.text {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: -0.01em;
                color: #282b39;
            }

            .key {
                flex-basis: 160px;
                background-color: var(--primary-50);
                display: flex;
                align-items: center;
                height: 100%;
                padding: 20px;
                gap: 4px;
            }
            .val {
                flex: 1;
                padding: 20px;
                display: flex;
                align-items: center;
                gap: 16px;
            }
        }
    }
`;

const DescriptionList = styled.ol`
    list-style: none;

    li {
        position: relative;
        padding-left: 18px;
        margin-bottom: 4px;
        &:last-of-type {
            margin-bottom: 0;
        }

        &:before {
            content: "";
            display: block;
            width: 6px;
            height: 6px;
            background-color: var(--primary-500);
            border-radius: 50px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
        }

        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            display: flex;
            align-items: center;
            letter-spacing: -0.01em;
            color: #282b39;
            white-space: nowrap;

            a {
                display: inline-block;
                margin-left: 4px;
                color: #7386e8;
                font-weight: 700;
            }

            span {
                cursor: pointer;
                padding: 0 8px;
                border-radius: 20px;
                border: 1px solid var(--primary-500);
                color: var(--primary-500);
                margin: 0 4px;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                display: flex;
                height: 22px;
                align-items: center;
            }
        }
    }
`;

const DescriptionWrap = styled.div`
    background-color: var(--gray-50);
    padding: 20px;
    border-radius: 4px;
`;

const PaddingWrap = styled.div`
    padding: 12px 0 24px;
`;

export default UserPlanInfoModal;
