import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedProducts: [],
};

const productSlice = createSlice({
    name: "product",
    initialState: initialState,
    reducers: {
        setSelectedProducts: (state, action) => {
            state.selectedProducts = action.payload;
        },
        resetSelectedProducts: (state) => {
            state.selectedProducts = [];
        },
    },
});

export const { setSelectedProducts, resetSelectedProducts } =
    productSlice.actions;
export default productSlice.reducer;
