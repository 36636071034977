import React, { useState } from "react";
import styled from "styled-components";

import { useNavigate } from "react-router-dom";

import { setCookie } from "../utils";

import TutorialImg1 from "../assets/images/tutorials/tuto-1@3x.png";
import TutorialImg2 from "../assets/images/tutorials/tuto-2@3x.png";
import TutorialImg3 from "../assets/images/tutorials/tuto-3@3x.png";
import TutorialImg4 from "../assets/images/tutorials/tuto-4@3x.png";
import TutorialImg5 from "../assets/images/tutorials/tuto-5@3x.png";
import TutorialImg6 from "../assets/images/tutorials/tuto-6@3x.png";
import TutorialImg7 from "../assets/images/tutorials/tuto-7@3x.png";
import TutorialImg8 from "../assets/images/tutorials/tuto-8@3x.png";
import TutorialImg9 from "../assets/images/tutorials/tuto-9@3x.png";
import TutorialImg10 from "../assets/images/tutorials/tuto-10@3x.png";

const TUTORIAL_PAGE_NAV = {
    PREV: "prev",
    NEXT: "next",
};

const Tutorial = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();

    const moveTutorialPage = (to) => {
        if (to === TUTORIAL_PAGE_NAV.PREV) {
            setCurrentPage(currentPage - 1);
        } else {
            if (currentPage === 10) {
                setCookie("isTutorialDone", true);
                navigate("/");
            } else {
                setCurrentPage(currentPage + 1);
            }
        }
    };

    const skipTutorial = () => {
        setCookie("isTutorialDone", true);
        navigate("/");
    };

    const returnImageByPage = () => {
        switch (currentPage) {
            case 1:
                return <img src={TutorialImg1} alt="" />;
            case 2:
                return <img src={TutorialImg2} alt="" />;
            case 3:
                return <img src={TutorialImg3} alt="" />;
            case 4:
                return <img src={TutorialImg4} alt="" />;
            case 5:
                return <img src={TutorialImg5} alt="" />;
            case 6:
                return <img src={TutorialImg6} alt="" />;
            case 7:
                return <img src={TutorialImg7} alt="" />;
            case 8:
                return <img src={TutorialImg8} alt="" />;
            case 9:
                return <img src={TutorialImg9} alt="" />;
            case 10:
                return <img src={TutorialImg10} alt="" />;
        }
    };

    return (
        <TutorialFullScreen>
            <div className="img-wrap">
                {returnImageByPage()}
                <CloseButton
                    onClick={skipTutorial}
                    className={`page-${currentPage}`}
                />
                <PrevButton
                    onClick={() => moveTutorialPage(TUTORIAL_PAGE_NAV.PREV)}
                    className={`page-${currentPage}`}
                />
                <NextButton
                    onClick={() => moveTutorialPage(TUTORIAL_PAGE_NAV.NEXT)}
                    className={`page-${currentPage}`}
                />
            </div>
        </TutorialFullScreen>
    );
};

const TutorialFullScreen = styled.div`
    text-align: center;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.9);
    div.img-wrap {
        display: inline-block;
        font-size: 0;
        position: relative;
        overflow: auto;
        img {
            width: 1920px;
            height: 1080px;
            display: block;
            margin: 0 auto;
            user-select: none;
            pointer-events: none;
        }
    }
`;

const CloseButton = styled.button`
    position: absolute;
    width: 76px;
    height: 76px;
    background-color: transparent;
    //border: 2px solid #000;

    &.page-1 {
        top: 450px;
        left: 334px;
    }

    &.page-2 {
        top: 438px;
        left: 664px;
    }

    &.page-3 {
        top: 293px;
        left: 1573px;
    }

    &.page-4 {
        top: 142px;
        left: 1444px;
    }

    &.page-5 {
        top: 162px;
        left: 1424px;
    }

    &.page-6 {
        top: 110px;
        left: 1622px;
    }

    &.page-7 {
        top: 290px;
        left: 897px;
    }

    &.page-8 {
        top: 304px;
        left: 1589px;
    }

    &.page-9 {
        top: 68px;
        left: 724px;
    }

    &.page-10 {
        top: 100px;
        left: 1505px;
    }
`;

const PrevButton = styled.button`
    position: absolute;
    width: 122px;
    height: 48px;
    background-color: transparent;
    //background-color: red;
    &.page-1 {
        display: none;
    }

    &.page-2 {
        top: 622px;
        left: 380px;
    }

    &.page-3 {
        top: 453px;
        left: 1269px;
    }

    &.page-4 {
        top: 303px;
        left: 1160px;
    }

    &.page-5 {
        top: 346px;
        left: 1140px;
    }

    &.page-6 {
        top: 270px;
        left: 1338px;
    }

    &.page-7 {
        top: 450px;
        left: 613px;
    }
    &.page-8 {
        top: 464px;
        left: 1305px;
    }

    &.page-9 {
        top: 252px;
        left: 440px;
    }

    &.page-10 {
        top: 332px;
        left: 1221px;
    }
`;

const NextButton = styled.button`
    position: absolute;
    width: 122px;
    height: 48px;
    background-color: transparent;
    //background-color: blue;

    &.page-1 {
        top: 610px;
        left: 248px;
    }

    &.page-2 {
        top: 622px;
        left: 578px;
    }

    &.page-3 {
        top: 453px;
        left: 1487px;
    }

    &.page-4 {
        top: 303px;
        left: 1358px;
    }

    &.page-5 {
        top: 346px;
        left: 1338px;
    }

    &.page-6 {
        top: 270px;
        left: 1536px;
    }

    &.page-7 {
        top: 450px;
        left: 811px;
    }
    &.page-8 {
        top: 464px;
        left: 1503px;
    }

    &.page-9 {
        top: 252px;
        left: 638px;
    }

    &.page-10 {
        top: 332px;
        left: 1419px;
    }
`;

export default Tutorial;
