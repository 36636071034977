import { instance } from "./index";

const getFolders = () => {
    return instance.get("/folder");
};
const createFolder = (param) => {
    return instance.post("/folder", param);
};
const updateFolderName = (param) => {
    const { folderId, title } = param;
    return instance.patch(`/folder/title/${folderId}`, { title });
};
const updateFolderOrder = (param) => {
    return instance.patch("/folder/order", param);
};
const removeFolder = (folderId) => {
    return instance.delete(`/folder/${folderId}`);
};
const getBackupFolder = (folderId) => {
    return instance.get(`/folder/backup/${folderId}`);
};
const restoreFolder = (param) => {
    return instance.post(`/folder/restore`, param, {
        headers: {
            "Content-Type": "application/json",
        },
    });
};

const folderManager = {
    getFolders,
    createFolder,
    updateFolderName,
    updateFolderOrder,
    removeFolder,
    getBackupFolder,
    restoreFolder,
};

export default folderManager;

export {
    getFolders,
    createFolder,
    updateFolderName,
    updateFolderOrder,
    removeFolder,
    getBackupFolder,
    restoreFolder,
};
