import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import ROUTES from "../constants/routes";

import Root from "./Root";
import FolderDetail from "./folderDetail/FolderDetail";
import ErrorPage from "./Error";
import Index from "./main/index";
import BannerGroup from "./bannerGroup/index";
import Tutorial from "./Tutorial";
import Maintenance from "./Maintenance";

const route = () => {
    const router = createBrowserRouter([
        {
            path: ROUTES.ROOT.path,
            element: <Root />,
            errorElement: <ErrorPage />,
            children: [
                {
                    index: true,
                    element: <Index />,
                },
                {
                    path: ROUTES.FOLDER_DETAIL.path,
                    element: <FolderDetail />,
                },
                {
                    path: ROUTES.BANNER_GROUP_CREATE.path,
                    element: <BannerGroup />,
                },
                {
                    path: ROUTES.BANNER_GROUP_UPDATE.path,
                    element: <BannerGroup />,
                },
            ],
        },
        {
            path: ROUTES.TUTORIAL.path,
            element: <Tutorial />,
        },
        {
            path: "/error",
            element: <ErrorPage />,
        },
        {
            path: ROUTES.MAINTENANCE.path,
            element: <Maintenance />,
        },
    ]);

    return <RouterProvider router={router} />;
};

export default route;
