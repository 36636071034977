import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const LABEL_PROPS_BY_SIZE = {
    m: {
        height: "22px",
        padding: "0 8px",
        lineHeight: "18px",
    },
    s: {
        height: "20px",
        padding: "0 6px",
        lineHeight: "16px",
    },
};

const Label = ({ size, children }) => {
    return <LabelItem $size={size}>{children}</LabelItem>;
};

const LabelItem = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    height: ${(props) => LABEL_PROPS_BY_SIZE[props.$size].height};
    padding: ${(props) => LABEL_PROPS_BY_SIZE[props.$size].padding};
    font-size: 12px;
    letter-spacing: -0.01em;
    line-height: ${(props) => LABEL_PROPS_BY_SIZE[props.$size].lineHeight};
    font-weight: 500;
    background: var(--primary-500);
    border-radius: 3px;
`;

Label.propTypes = {
    size: PropTypes.oneOf(["m", "s"]),
};

Label.defaultProps = {
    size: "m",
};

export default Label;
