import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
    categories: [],
    linkedCategories: [],
    spreadedCategories: {},
    checkedCategories: {},
    chips: [],
};

const categorySlice = createSlice({
    name: "category",
    initialState: initialState,
    reducers: {
        setCategories: (state, action) => {
            state.categories = action.payload;
        },
        setCheckCategories: (state, action) => {
            Object.assign(state.checkedCategories, { ...action.payload });
        },
        setSpreadedCategories: (state, action) => {
            Object.assign(state.spreadedCategories, { ...action.payload });
        },
        setChips: (state, action) => {
            // Object.assign(state.chips, [...action.payload]);
            state.chips = action.payload;
        },
        setLinkedCategories: (state, action) => {
            state.linkedCategories = action.payload;
        },
        emptyCategories: (state) => {
            state.linkedCategories = [];
            state.chips = [];
            let checkedCategories = Object.assign({}, state.checkedCategories);
            for (const key in checkedCategories) {
                if (checkedCategories.hasOwnProperty(key)) {
                    checkedCategories[key] = false;
                }
            }
            state.checkedCategories = checkedCategories;
        },
        resetCategories: (state) => {
            state.linkedCategories = [];
            state.spreadedCategories = {};
            state.checkedCategories = {};
            state.chips = [];
        },
    },
});

export const {
    emptyCategories,
    setCategories,
    setSpreadedCategories,
    setLinkedCategories,
    setChips,
    setCheckCategories,
    resetCategories,
} = categorySlice.actions;
export default categorySlice.reducer;
