import React from "react";
import styled from "styled-components";

import ProductConnectTutorialImg from "../../../assets/images/product-connect-tutorial@2x.png";

const ProductConnectTutorialModal = () => {
    return (
        <TutorialImageWrap>
            <img src={ProductConnectTutorialImg} alt="tutorial img" />
        </TutorialImageWrap>
    );
};

const TutorialImageWrap = styled.div`
    width: 100%;
    overflow: auto;
    height: 100%;
    max-height: 800px;
    &::-webkit-scrollbar {
        width: 12px;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--gray-400);
        border-radius: 100px;
        border-right: 4px solid #fff;
        border-left: 4px solid #fff;
        border-top: 2px solid #fff;
        border-bottom: 2px solid #fff;
    }
    &::-webkit-scrollbar-track {
        width: 12px;
        background-color: transparent;
    }

    img {
        width: 1400px;
        display: block;
        user-select: none;
        pointer-events: none;
    }
`;

export default ProductConnectTutorialModal;
