import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

const FolderList = ({
    folderItems,
    openableCategories,
    onClickFolderIcon,
    onCheckItem,
}) => {
    const dispatch = useDispatch();
    const checkedCategories = useSelector(
        (state) => state.category.checkedCategories
    );
    if (!folderItems) return;
    const generateUl = (items) => {
        return (
            <ul>
                {items.map((item) => (
                    <li
                        key={item.category_no}
                        className={`${
                            item.children.length ? "cate_child" : "no_child"
                        } ${
                            openableCategories[item.category_no] ? "active" : ""
                        }`}
                    >
                        <div className="cate_chk">
                            <input
                                onChange={(e) =>
                                    onCheckItem(item, e.target.checked)
                                }
                                type="checkbox"
                                checked={checkedCategories[item.category_no]}
                                id={item.category_no}
                                value={checkedCategories[item.category_no]}
                            />
                            <label htmlFor={item.category_no} />
                            <span
                                className="folder"
                                onClick={(e) => onClickFolderIcon(item)}
                            ></span>
                            <label
                                htmlFor={item.category_no}
                                className={"check_text"}
                            >
                                {item.category_name}
                            </label>
                        </div>

                        {item.children.length ? (
                            <div className="down_cate">
                                {generateUl(item.children)}
                            </div>
                        ) : null}
                    </li>
                ))}
            </ul>
        );
    };

    return <>{generateUl(folderItems)}</>;
};

export default FolderList;
