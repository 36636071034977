import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import IconTooltip from "../../assets/images/icons/ic-tooltip.svg";

const Tooltip = ({ text }) => {
    return (
        <TooltipWrap tabIndex="0">
            <img src={IconTooltip} alt="tooltip icon" />
            <TooltipContentWrap>
                <TooltipBox>
                    <p>{text}</p>
                </TooltipBox>
            </TooltipContentWrap>
        </TooltipWrap>
    );
};

const TooltipWrap = styled.div`
    position: relative;
    display: flex;
    &:focus {
        & > div {
            opacity: 1;
            visibility: visible;
        }
    }
`;

const TooltipContentWrap = styled.div`
    position: absolute;
    left: 100%;
    top: -4px;
    padding: 0 10px;
    z-index: 20;
    opacity: 0;
    visibility: hidden;
    transition: all 0.25s ease-in-out;
`;

const TooltipBox = styled.div`
    padding: 8px 12px;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 4px;

    p {
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #fff;
        white-space: pre;
    }
`;

Tooltip.propTypes = {
    text: PropTypes.string.isRequired,
};
Tooltip.defaultProps = {};

export default Tooltip;
