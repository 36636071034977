import React, { useState, useEffect } from "react";
import { ModalButtonWrap, ModalBody } from "./Modal";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../redux/ToastSlice";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { removeCloseFunc } from "../../redux/ModalSlice";
import folderManager from "../../apis/folderManager";

import "./style/_add-folder-modal.scss";
import { Input, RoundButton } from "../common";
import { resetFolderName, setFolderName } from "../../redux/FolderSlice";

const AddFolderModal = ({ handleCloseModal, isUpdate, folder }) => {
    const { folderName } = useSelector((state) => state.folder);
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const addFolderMutation = useMutation(folderManager.createFolder, {
        onSuccess: () => {
            dispatch(showToast("폴더가 생성되었습니다."));
            handleCloseModal();
            dispatch(resetFolderName());
            queryClient.invalidateQueries({ queryKey: ["getFolders"] });
        },
    });
    const updateFolderMutation = useMutation(folderManager.updateFolderName, {
        onSuccess: () => {
            dispatch(showToast("폴더명이 수정되었습니다."));
            handleCloseModal();
            dispatch(resetFolderName());
            queryClient.invalidateQueries({ queryKey: ["getFolders"] });
        },
    });

    useEffect(() => {
        if (isUpdate) {
            dispatch(setFolderName(folder.title));
        }
    }, []);

    const onInputChange = (e) => {
        dispatch(setFolderName(e.target.value));
    };
    const onCancelClick = () => {
        handleCloseModal();
    };
    const onSaveClick = async () => {
        if (!folderName.trim().length) {
            return dispatch(showToast("폴더명을 입력해주세요."));
        }

        if (folderName.length > 15) {
            return;
        }

        dispatch(removeCloseFunc());

        if (isUpdate) {
            await updateFolderMutation.mutateAsync({
                folderId: folder.id,
                title: folderName,
            });
        } else {
            await addFolderMutation.mutateAsync({ title: folderName });
        }
    };

    return (
        <ModalBody id="AddFolderModalContent">
            <div className="folder_wrap">
                <div className="folder_txt">
                    <span>폴더명</span>
                </div>
                <div className="input_wrap">
                    <div className="floder_input">
                        <Input
                            error={folderName.length > 15}
                            placeHolder={"15자 이내로 입력해주세요."}
                            value={folderName}
                            maxLength={16}
                            errorText={
                                "폴더명은 문자, 숫자, 공백을 포함해 15자 이내로 입력해 주세요."
                            }
                            onChange={onInputChange}
                        />
                    </div>
                </div>
            </div>
            <ModalButtonWrap className="cancel_save">
                <RoundButton type={"outline"} onClick={onCancelClick}>
                    취소
                </RoundButton>
                <RoundButton
                    type={"fill"}
                    onClick={onSaveClick}
                    isDisabled={
                        folderName.length > 15 ||
                        addFolderMutation.isLoading ||
                        updateFolderMutation.isLoading
                    }
                >
                    저장
                </RoundButton>
            </ModalButtonWrap>
        </ModalBody>
    );
};

export default AddFolderModal;
