import React, { useState } from "react";
import styled from "styled-components";

import { Checkbox, Input } from "../../common";
import _ from "lodash";

import { useDispatch, useSelector } from "react-redux";
import { updateImageModalState } from "../../../redux/ImageModalSlice";
import ReactPlayer from "react-player";

const VideoUpload = () => {
    const imageModalState = useSelector((state) => state.imageModal);
    const dispatch = useDispatch();
    const onCheckChange = (e) => {
        const id = e.target.id;
        const val = e.target.checked;
        dispatch(
            updateImageModalState({
                [id]: val,
            })
        );
    };

    const onChangeInput = (e) => {
        dispatch(
            updateImageModalState({
                video_url: e.target.value,
            })
        );
    };

    const returnVideoPlayer = () => {
        const { video_url } = imageModalState;

        if (video_url.includes("youtu") || video_url.includes("vimeo")) {
            return (
                <ReactPlayer
                    url={imageModalState.video_url}
                    controls={true}
                    config={{
                        vimeo: { playerOptions: { background: true } },
                    }}
                />
            );
        } else {
            return null;
        }
    };

    return (
        <>
            <div className="attach_wrap">
                <div className="img_wrap">
                    <p>{`영상 링크를 입력하시면\n미리보기가 가능합니다.`}</p>
                    {imageModalState.video_url.length > 0 ? (
                        <>{returnVideoPlayer()}</>
                    ) : null}
                </div>
            </div>
            <UPLOAD_TABLE>
                <table className="table_in">
                    <colgroup>
                        <col width="160px" />
                        <col width="" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>영상 링크</th>
                            <td>
                                <div className="link_in on">
                                    <Input
                                        onChange={onChangeInput}
                                        size={"m"}
                                        value={imageModalState.video_url}
                                        placeHolder={"URL을 입력해주세요."}
                                    />
                                    <p className="explan_txt">
                                        유튜브(YOUTUBE)와 비메오(VIMEO) 링크만
                                        입력해 주세요.
                                    </p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>링크 연결 방법</th>
                            <td>
                                <div className="checks_wrap">
                                    <Checkbox
                                        id={"autoPlay"}
                                        isChecked={imageModalState.autoPlay}
                                        label={"자동 재생"}
                                        name={"playCheck"}
                                        onChange={onCheckChange}
                                    />
                                    <Checkbox
                                        id={"loopPlay"}
                                        isChecked={imageModalState.loopPlay}
                                        label={"반복 재생"}
                                        name={"playCheck"}
                                        onChange={onCheckChange}
                                    />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </UPLOAD_TABLE>
        </>
    );
};

const ThumbnailImage = styled.img`
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    z-index: 1;
`;

const UPLOAD_TABLE = styled.div`
    margin-bottom: 212px;
    .checks_wrap {
        display: flex;
        align-items: center;
        gap: 40px;
    }
    .table_in {
        width: 100%;
        tr {
            border-bottom: 1px solid #eff1fa;
            min-height: 60px;
            &:first-child {
                border-top: 1px solid #eff1fa;
            }
            th {
                background: #f9f9fd;
                padding: 20px;
                text-align: left;
                height: 60px;
                overflow: visible;
                position: relative;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: -0.01em;
                color: #282b39;
                vertical-align: top;
            }
            td {
                height: 60px;
                overflow: visible;
                padding: 10px 20px;
                position: relative;
                input[type="text"] {
                    width: 100%;
                }
                .link_in {
                    .explan_txt {
                        display: block;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 20px;
                        letter-spacing: -0.01em;
                        color: #8a8e9c;
                        margin-top: 4px;
                    }
                }
            }
        }
    }
`;

export default VideoUpload;
