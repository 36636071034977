import React, { useState, useEffect } from "react";

import { ModalButtonWrap, ModalBody } from "./Modal";

import IcLight from "../../assets/images/icons/ic-light@2x.png";
import "./style/_category-connect-modal.scss";
import { useDispatch, useSelector } from "react-redux";
import { Chip } from "../index";
import {
    setCheckCategories,
    setChips,
    setLinkedCategories,
} from "../../redux/CategorySlice";
import FolderList from "../folderList/FolderList";

const CategoryConnectModal = ({ handleCloseModal }) => {
    const dispatch = useDispatch();
    const [openableCategories, setOpenableCategories] = useState({}); // 펼치기 닫기 용
    const [latestCategories, setLatestCategories] = useState([]); // 최신 정보 비교용
    const { checkedCategories, chips, categories, spreadedCategories } = useSelector((state) => state.category);
    const updateCategories = [];

    useEffect(() => {
        if (categories) {
            const latestCateNo = initOpenableCategories(categories);
            setLatestCategories(latestCateNo);
        }
    }, [categories]);

    useEffect(() => {
        if(latestCategories) {
            chipsGenerator();
        }
    }, [checkedCategories, latestCategories]);

    const onChipClick = (item) => {
        checkItem(item.self, false);
    };

    const onClickFolderIcon = (category) => {
        const categoryNo = category.category_no;
        setOpenableCategories((prevState) => {
            const updatedState = { ...prevState };
            updatedState[categoryNo] = !updatedState[categoryNo];
            return updatedState;
        });
    };

    const initOpenableCategories = (categories) => {
        categories.forEach((cate) => {
            updateCategories.push(cate.category_no);
            if (cate.children.length > 0) {
                setOpenableCategories((prevState) => {
                    return { ...prevState, [cate.category_no]: false };
                });
                initOpenableCategories(cate.children);
            }
        });
        return updateCategories;
    };

    const checkChildren = (item, isChecked, copyCategories) => {
        // 선택한 분류에 하위 분류가 있는 경우 선택이 되지 않는 이슈가 있어 임시 조치
        // item.children.forEach((child) => {
        //     copyCategories[child.category_no] = isChecked;
        //     if (child.children.length > 0) {
        //         child.children.forEach((grandChild) => {
        //             copyCategories[grandChild.category_no] = isChecked;
        //             if (grandChild.children.length > 0) {
        //                 grandChild.children.forEach((lastDepthChild) => {
        //                     copyCategories[lastDepthChild.category_no] =
        //                         isChecked;
        //                 });
        //             }
        //         });
        //     }
        // });
        checkParent(item, isChecked, copyCategories);
    };

    const checkParent = (item, isChecked, copyCategories) => {
        const parentNode = spreadedCategories[item.parent_category_no];
        if (isChecked) {
            // 선택한 분류에 하위 분류가 있는 경우 선택이 되지 않는 이슈가 있어 임시 조치
            // if (item.parent_category_no !== 1) {
            //     let isAllCheck = true;
            //     if (
            //         parentNode.children.length === 1 &&
            //         !copyCategories[parentNode.children[0].category_no]
            //     ) {
            //         isAllCheck = false;
            //     } else {
            //         for (let i = 0; i < parentNode.children.length; i++) {
            //             const self = parentNode.children[i];
            //             if (self.category_no !== item.category_no) {
            //                 if (!copyCategories[self.category_no]) {
            //                     isAllCheck = false;
            //                 }
            //             }
            //         }
            //     }
            //     copyCategories[parentNode.category_no] = isAllCheck;
            //     checkParent(parentNode, isChecked, copyCategories);
            // } else {
                dispatch(setCheckCategories(copyCategories));
            // }
        } else {
            // 선택한 분류에 하위 분류가 있는 경우 선택이 되지 않는 이슈가 있어 임시 조치
            // if (item.parent_category_no !== 1) {
            //     copyCategories[parentNode.category_no] = false;
            //     checkParent(parentNode, isChecked, copyCategories);
            // } else {
                dispatch(setCheckCategories(copyCategories));
            // }
        }
    };

    const checkItem = (item, isChecked) => {
        let copyCategories = JSON.parse(JSON.stringify(checkedCategories));
        copyCategories[item.category_no] = isChecked;
        checkChildren(item, isChecked, copyCategories);
    };

    const linkCategory = () => {
        // console.log(checkedCategories);
        let linkedArr = [];
        for (let key in checkedCategories) {
            if (checkedCategories[key]) {
                linkedArr.push(spreadedCategories[key]);
            }
        }
        dispatch(setLinkedCategories(linkedArr));
        handleCloseModal();
    };

    const chipsGenerator = () => {
        let checkedArr = [];
        let chips = [];
        for (let categoryNo in checkedCategories) {
            if (checkedCategories[categoryNo]) {
                checkedArr.push(spreadedCategories[categoryNo]);
            }
        }
        checkedArr.forEach((item) => {
            if (item) {
                // 선택한 분류에 하위 분류가 있는 경우 선택이 되지 않는 이슈가 있어 임시 조치
                // if (item.children?.length === 0) {
                    let parentCategoryNos = [];
                    let parentCategoryNames = [];
                    pushParent(item, parentCategoryNos, parentCategoryNames);
                    latestCategories.map((latestNo) => {
                        if(latestNo === item.category_no) {
                            chips.push({
                                self: item,
                                parentCategoryNos: parentCategoryNos.reverse(),
                                parentCategoryNames: parentCategoryNames.reverse(),
                            });
                        }
                    })
                // }
            }
        });
        dispatch(setChips(chips));
    };

    const pushParent = (item, parentCategoryNos, parentCategoryNames) => {
        if (item.parent_category_no === 1) return;
        const parentNode = spreadedCategories[item.parent_category_no];
        parentCategoryNos.push(parentNode.category_no);
        parentCategoryNames.push(parentNode.category_name);
        if (parentNode.parent_category_no !== 1) {
            pushParent(parentNode, parentCategoryNos, parentCategoryNames);
        }
    };

    return (
        <ModalBody id="categoryConnectModalContent">
            <div className="iv_txt">
                <ul>
                    <li>
                        <span>
                            <img src={IcLight} alt="아이콘" />
                        </span>
                        <p>
                            배너를 적용할 카테고리를 선택해 주세요. 해당 상품
                            리스트 페이지에 배너가 노출됩니다.
                        </p>
                    </li>
                </ul>
            </div>
            <div className="category_wrap">
                <div className="category_list">
                    {categories && (
                        <FolderList
                            folderItems={categories}
                            openableCategories={openableCategories}
                            onClickFolderIcon={onClickFolderIcon}
                            onCheckItem={checkItem}
                        />
                    )}
                </div>
            </div>

            <div className="cate_add">
                <div className="txt_area">
                    <h3>연결 카테고리</h3>
                    {/*<div className="count">*/}
                    {/*    (<span className="counting">{chips.length}</span>/*/}
                    {/*    <span className="all_count">30</span>)*/}
                    {/*</div>*/}
                </div>
                <div className="add_wrap">
                    <div className="coun_wrap">
                        <div className="in-data">
                            {chips.map((item, index) => (
                                <Chip
                                    size={"l"}
                                    key={index}
                                    type={"round"}
                                    text={`${item.parentCategoryNames.join(
                                        " > "
                                    )} ${
                                        item.parentCategoryNames.length
                                            ? " > "
                                            : ""
                                    }`}
                                    emphasisText={item.self.category_name}
                                    onClick={(e) => onChipClick(item)}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className="add_btn">
                <button type="button" className="iv_btn" onClick={linkCategory}>
                    추가
                </button>
            </div>
        </ModalBody>
    );
};

export default CategoryConnectModal;
