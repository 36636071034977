import { instance } from "./index";
import moment from "moment";
import axios from "axios";

const getPayment = () => {
    return instance.get(`/payment`);
};

const createPayment = (params) => {
    const { redirect_url } = params;

    return instance.post(`/payment`, {
        order_name: "배너관리앱",
        order_amount: "12500",
        automatic_payment: "T", //정기 결제, 단기 결제는 F인데 그런 케이스 현재 없음
        redirect_url: redirect_url.split("?")[0],
    });
};

const moveToPayment = (mall_id) => {
    let db_uid = moment().format("YYYYMMDD-HHmmssSSS");
    return instance({
        url: "/payment/pay",
        baseURL: process.env.REACT_APP_API_URL,
        method: "POST",
        data: {
            mall_id,
            db_uid: db_uid,
            order_name: "배너관리앱",
            order_amount: "12500",
            return_url: `https://api.ivmakes.com/payment/order/${db_uid}`,
            automatic_payment: "T",
        },
    });
};

const paymentManager = {
    getPayment,
    createPayment,
    moveToPayment,
};

export default paymentManager;
