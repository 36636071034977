import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import { Input, Radio } from "../../common";
import { Tooltip } from "../../index";
import { useDispatch, useSelector } from "react-redux";
import { updateImageModalState } from "../../../redux/ImageModalSlice";
import { openConfirm } from "../../../redux/ConfirmSlice";
import paymentManager from "../../../apis/paymentManager";

import IcBannerTextRemove from "../../../assets/images/icons/ic-btn-minus@2x.png";
import IcBannerTextAdd from "../../../assets/images/icons/ic-btn-add@2x.png";
import { useQueryClient } from "@tanstack/react-query";
import ErrorIcon from "../../../assets/images/icons/ic-input-warning.svg";

const ImageURLUpload = ({
    onUrlInputChange,
    onRadioChange,
    onBannerTextsInputChange,
    removeRow,
    addRow,
    imgError,
    setImgError,
}) => {
    const imageModalState = useSelector((state) => state.imageModal);
    const [paymentInfo, setPaymentInfo] = useState({});
    const [alreadyCheck, setAlreadyCheck] = useState(false);
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const textareaRefs = useRef([]);

    useEffect(() => {
        getPaymentInfo();
    }, []);

    useEffect(() => {
        textareaRefs.current.forEach((textareaRef) => {
            if (textareaRef) {
                textareaRef.style.height = "40px";
                textareaRef.style.height = `${textareaRef.scrollHeight}px`;
            }
        });
    }, [imageModalState.texts]);

    const getPaymentInfo = async () => {
        const result = await queryClient.getQueryData(["paymentInfo"]);
        setPaymentInfo(result);
    };

    const checkUserVersion = () => {
        if (alreadyCheck) {
            return;
        }
        if (paymentInfo.version === "Free") {
            dispatch(
                openConfirm({
                    confirmText: "구독하기",
                    hasImage: true,
                    isSingleButton: true,
                    notCloseOnClickDimmed: true,
                    text: `[배너 텍스트 입력]은 프리미엄 전용 서비스입니다.\n구독하시면 해당 기능을 이용하실 수 있습니다.`,
                    confirmFunc: () => {
                        openPayment();
                        setAlreadyCheck(true);
                    },
                })
            );
        } else if (paymentInfo.version === "Free Trial") {
            setAlreadyCheck(true);
            dispatch(
                openConfirm({
                    confirmText: "구독하기",
                    hasImage: true,
                    isSingleButton: true,
                    notCloseOnClickDimmed: true,
                    text: `${paymentInfo.d_day}일 후 [배너 텍스트 입력] 서비스가 종료됩니다.\n구독하시면 해당 기능을 계속 이용하실 수 있습니다.`,
                    confirmFunc: () => {
                        openPayment();
                    },
                })
            );
        }
    };

    //todo 계정을 refresh하는 방법에 대해
    const openPayment = async () => {
        const result = await paymentManager.createPayment({
            redirect_url: window.location.href,
        });
        window.open(result, "_blank");
    };

    const onExternalUrlInput = (e) => {
        dispatch(
            updateImageModalState({
                imageExternalUrl: e.target.value,
            })
        );
    };

    const imgLoadError = (e) => {
        setImgError(true);
    };

    const imgLoadSuccess = (e) => {
        setImgError(false);
    };

    return (
        <>
            <div className="attach_wrap">
                <div className="img_wrap">
                    <p>{`이미지 링크를 입력하시면\n미리보기가 가능합니다.`}</p>
                    {imageModalState.imageExternalUrl ? (
                        <ThumbnailImage
                            src={imageModalState.imageExternalUrl}
                            alt=""
                            onError={imgLoadError}
                            onLoad={imgLoadSuccess}
                        />
                    ) : null}
                </div>

                <ImageLinkInputContainer>
                    <p className="input_txt">이미지 링크</p>
                    <Input
                        onChange={onExternalUrlInput}
                        size={"m"}
                        value={imageModalState.imageExternalUrl}
                        // error={
                        //     imageModalState.imageExternalUrl.length > 0 &&
                        //     imgError
                        // }
                        // errorText={"이미지 링크를 확인해주세요."}
                    />
                    <p className="guide_desc">ex) https://www.cafe24.com</p>
                </ImageLinkInputContainer>
            </div>
            <div className="upload_table">
                <table className="table_in">
                    <colgroup>
                        <col width="160px" />
                        <col width="" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>연결 링크 (URL)</th>
                            <td>
                                <Input
                                    onChange={onUrlInputChange}
                                    size={"m"}
                                    value={imageModalState.url}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>링크 연결 방법</th>
                            <td>
                                <div className="radios_wrap">
                                    <Radio
                                        name={"linkOpenMethod"}
                                        id={"_self"}
                                        onChange={onRadioChange}
                                        value={"_self"}
                                        isChecked={
                                            imageModalState.set_target ===
                                            "_self"
                                        }
                                        label={"현재 창에서 열기"}
                                    />
                                    <Radio
                                        name={"linkOpenMethod"}
                                        id={"_blank"}
                                        onChange={onRadioChange}
                                        value={"_blank"}
                                        isChecked={
                                            imageModalState.set_target ===
                                            "_blank"
                                        }
                                        label={"새 창으로 열기"}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <div className="flex-key-wrap">
                                    배너 텍스트 입력
                                    <Tooltip
                                        text={`텍스트박스의 숫자는 텍스트의 문단 구분을 의미합니다.\n최대 5개 문단으로 분류하여 사용할 수 있으며,\n다양한 HTML를 입력하여 텍스트를 꾸밀 수 있습니다.`}
                                    />
                                </div>
                            </th>
                            <td>
                                <MaxHeightWrap>
                                    <BannerTextWrap onClick={checkUserVersion}>
                                        {imageModalState.texts.map(
                                            (bannerText, index) => (
                                                <div
                                                    className="text-row"
                                                    key={index}
                                                >
                                                    <div className="key-wrap">
                                                        <span>
                                                            텍스트_0{index + 1}
                                                        </span>
                                                    </div>
                                                    <div className="input-wrap">
                                                        <TextAreaWrap>
                                                            <ResizeableTextarea
                                                                // maxLength={1001}
                                                                onChange={(e) =>
                                                                    onBannerTextsInputChange(
                                                                        e,
                                                                        index
                                                                    )
                                                                }
                                                                value={
                                                                    bannerText
                                                                }
                                                                ref={(ref) => {
                                                                    textareaRefs.current[
                                                                        index
                                                                    ] = ref;
                                                                }}
                                                                disabled={
                                                                    paymentInfo.version ===
                                                                    "Free"
                                                                }
                                                                // className={
                                                                //     bannerText.length >
                                                                //     1000
                                                                //         ? "error"
                                                                //         : ""
                                                                // }
                                                            />
                                                            {/*{bannerText.length >*/}
                                                            {/*1000 ? (*/}
                                                            {/*    <>*/}
                                                            {/*        <img*/}
                                                            {/*            src={*/}
                                                            {/*                ErrorIcon*/}
                                                            {/*            }*/}
                                                            {/*            alt="input error icon"*/}
                                                            {/*        />*/}
                                                            {/*        <p*/}
                                                            {/*            className={*/}
                                                            {/*                "warning-text"*/}
                                                            {/*            }*/}
                                                            {/*        >*/}
                                                            {/*            배너*/}
                                                            {/*            텍스트는*/}
                                                            {/*            문자,*/}
                                                            {/*            숫자,*/}
                                                            {/*            공백을*/}
                                                            {/*            포함해*/}
                                                            {/*            1,000자*/}
                                                            {/*            이내로*/}
                                                            {/*            입력해주세요.*/}
                                                            {/*        </p>*/}
                                                            {/*    </>*/}
                                                            {/*) : null}*/}
                                                        </TextAreaWrap>
                                                        {index >= 2 ? (
                                                            <button
                                                                onClick={(e) =>
                                                                    removeRow(
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        IcBannerTextRemove
                                                                    }
                                                                    alt="remove icon"
                                                                />
                                                            </button>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            )
                                        )}

                                        {imageModalState.texts.length < 5 && (
                                            <div className="add-btn-wrap">
                                                <button onClick={addRow}>
                                                    <img
                                                        src={IcBannerTextAdd}
                                                        alt="add btn icon"
                                                    />
                                                </button>
                                            </div>
                                        )}
                                    </BannerTextWrap>
                                </MaxHeightWrap>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
};

const TextAreaWrap = styled.div`
    position: relative;
    font-size: 0;
    width: 100%;
    text-align: left;

    img {
        position: absolute;
        z-index: 1;
        right: 12px;
        top: 12px;
    }

    p {
        margin-top: 4px;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: var(--error-300);
    }
`;

const ResizeableTextarea = styled.textarea`
    width: 100%;
    resize: none;
    overflow: hidden;
    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
        color 0.2s ease-in-out;
    border-radius: 3px;
    border: 1px solid var(--gray-100);
    background-color: var(--white);
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
    padding: 6px 16px;
    color: var(--gray-black);

    &:focus {
        border-color: var(--primary-400);
        box-shadow: 0 0 10px rgba(115, 134, 232, 0.15);
        caret-color: var(--primary-400);
        &::placeholder {
            color: transparent;
        }
    }

    &:disabled {
        border-color: var(--gray-100);
        background-color: var(--gray-50);
        color: var(--gray-300);
    }

    &::placeholder {
        font-weight: 400;
        color: var(--gray-400);
    }

    &.error {
        padding-right: 40px;
        color: var(--error-300);
        border-color: var(--error-200);
        box-shadow: 0 0 10px rgba(233, 98, 114, 0.15);
    }
`;

const ImageLinkInputContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p.input_txt {
        margin-bottom: 8px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #282b39;
    }

    p.guide_desc {
        margin-top: 4px;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: #8a8e9c;
    }
`;

const ThumbnailImage = styled.img`
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    z-index: 1;
    background-color: #fff;
`;

const MaxHeightWrap = styled.div`
    max-height: 200px;
    height: 100%;
    overflow: hidden;
`;

const BannerTextWrap = styled.div`
    padding: 20px;
    width: 100%;
    background: #f9f9fa;
    border-radius: 4px;
    height: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 12px;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--gray-400);
        border-radius: 100px;
        border-right: 4px solid #f9f9fa;
        border-left: 4px solid #f9f9fa;
        border-top: 2px solid #f9f9fa;
        border-bottom: 2px solid #f9f9fa;
    }
    &::-webkit-scrollbar-track {
        width: 12px;
        background-color: transparent;
    }

    div.add-btn-wrap {
        margin-top: 16px;
        text-align: center;
        img {
            display: block;
            width: 40px;
        }
    }

    div.text-row {
        margin-top: 8px;
        display: flex;
        align-items: flex-start;
        gap: 8px;

        &:first-of-type {
            margin-top: 0;
        }

        div.key-wrap {
            flex-basis: 100px;
            padding-top: 6px;
            span {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: -0.01em;
                color: #282b39;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        div.input-wrap {
            flex: 1;
            display: flex;
            align-items: center;
            gap: 8px;

            & > div {
                flex: 1;
            }

            img {
                display: block;
                width: 24px;
            }
        }
    }
`;

const CustomInput = styled(Input)`
    input {
        &:disabled {
            background-color: #fff;
        }
    }
`;

export default ImageURLUpload;
