import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isOpen: false,
    hasImage: false,
    closeDisabled: false,
    title: "",
    text: "",
    cancelFunc: null,
    cancelText: "취소",
    confirmFunc: null,
    confirmText: "",
    isSingleButton: false,
    notCloseOnClickDimmed: false,
    closeFunc: null,
};

const confirmSlice = createSlice({
    name: "confirm",
    initialState: initialState,
    reducers: {
        openConfirm: (state, action) => {
            if (!action.payload) return console.log("no payload");
            const {
                text,
                cancelFunc,
                cancelText,
                confirmFunc,
                confirmText,
                isSingleButton,
                hasImage,
                title,
                closeDisabled,
                closeFunc,
                notCloseOnClickDimmed,
            } = action.payload;
            state.isOpen = true;
            state.text = text;
            state.confirmFunc = confirmFunc;
            state.closeDisabled = closeDisabled;
            state.confirmText = confirmText;
            state.cancelFunc = cancelFunc || null;
            state.cancelText = cancelText || "취소";
            state.closeFunc = closeFunc || null;
            state.isSingleButton = isSingleButton;
            state.hasImage = hasImage;
            state.title = title;
            state.notCloseOnClickDimmed = notCloseOnClickDimmed;
            if (action.payload.width) state.width = action.payload.width;
        },
        closeConfirm: (state, action) => {
            return initialState;
        },
    },
});

export const { openConfirm, closeConfirm } = confirmSlice.actions;
export default confirmSlice.reducer;
