import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const CHIP_TYPE_SQUARE = "square";
const CHIP_TYPE_ROUND = "round";

const Chip = ({ text, size, type, emphasisText, onClick }) => {
    if (size === "s") {
        return (
            <ChipSmall>
                <p>{text}</p>
                <CloseButton onClick={onClick}>
                    <span />
                </CloseButton>
            </ChipSmall>
        );
    }
    if (type === CHIP_TYPE_SQUARE) {
        return (
            <ChipSquare>
                <p>{text}</p>
                <CloseButton onClick={onClick}>
                    <span></span>
                </CloseButton>
            </ChipSquare>
        );
    } else {
        return (
            <ChipRound>
                <p>
                    {text} {emphasisText ? <span>{emphasisText}</span> : null}
                </p>
                <CloseButton onClick={onClick}>
                    <span></span>
                </CloseButton>
            </ChipRound>
        );
    }
};

const CloseButton = styled.button`
    position: absolute;
    display: flex;
    width: 30px;
    height: 30px;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);

    span {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &:after {
            display: inline-block;
            content: "\\00d7";
            font-size: 14px;
            color: var(--gray-500);
            line-height: 1;
        }
    }
`;
const ChipSmall = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 28px;
    background: var(--gray-50);
    border: 1px solid var(--gray-200);
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 26px;
    p {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.01em;
        color: #8a8e9c;
    }
    button {
        width: 28px;
        height: 28px;
        span {
            font-size: 12px;
        }
    }
`;
const ChipRound = styled.div`
    position: relative;
    display: inline-flex;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 12px;
    padding-right: 32px;
    background: #ffffff;
    border: 1px solid var(--primary-300);
    border-radius: 50px;
    align-items: center;
    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: var(--gray-black);
        white-space: break-spaces;

        span {
            font-weight: 700;
            color: var(--primary-500);
        }
    }

    button {
        width: 32px;
        height: 32px;
    }
`;
const ChipSquare = styled.div`
    position: relative;
    display: inline-block;
    max-width: 200px;
    width: 100%;
    font-size: 0;
    padding: 10px 32px 10px 12px;
    border: 1px solid var(--primary-300);
    border-radius: 4px;
    background: #ffffff;
    p {
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: var(--gray-black);
        white-space: break-spaces;
    }
`;

Chip.propTypes = {
    text: PropTypes.string.isRequired,
    emphasisText: PropTypes.string,
    size: PropTypes.oneOf(["l", "s"]),
    type: PropTypes.oneOf([CHIP_TYPE_SQUARE, CHIP_TYPE_ROUND]),
    onClick: PropTypes.func,
};

Chip.defaultProps = {
    text: "",
    size: "l",
    type: CHIP_TYPE_SQUARE,
};

export default Chip;
