import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import Logo from "../assets/images/home_logo@2x.png";
import SadFace from "../assets/images/sad-face.png";
import { INNERVIEW_BANNER_APPSTORE_LINK } from "../constants";
import { useSearchParams } from "react-router-dom";

const Error = () => {
    const error = useRouteError();
    const [searchParams] = useSearchParams();
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [isButtonVisible, setIsButtonVisible] = useState(false);
    useEffect(() => {
        setError();
    }, []);

    const setError = () => {
        console.log(error);
        if (error) {
            if (error.status === 404) {
                setTitle("잘못된 요청입니다!");
                setMessage("비정상적인 접근 혹은 요청입니다.");
            }
        } else {
            if (searchParams.size === 0) {
                setTitle("잘못된 요청입니다!");
                setMessage("비정상적인 접근 혹은 요청입니다.");
            } else {
                const status = searchParams.get("status");
                if (status === "401") {
                    setTitle("토큰을 가져오는 중 오류가 발생했습니다!");
                    setMessage(
                        `[관리자] > [앱] > [관리하기]로 다시 접속해 주세요.\n오류가 지속적으로 발생할 경우, 아래 버튼을 눌러\n문의를 남겨 주시면 빠르게 해결해 드리겠습니다.`
                    );
                    setIsButtonVisible(true);
                } else if (status === "402") {
                    setTitle("앱 설치 오류가 발생했습니다!");
                    setMessage(
                        "앱 설치는 대표운영자 계정으로만 가능합니다.\n대표운영자 계정으로 접속하여 다시 시도해주세요."
                    );
                } else if (status === "401_mallId") {
                    setTitle("쇼핑몰 아이디가 없습니다!");
                    setMessage(
                        "[관리자] > [앱] > [관리하기] 경로로 접속해 주세요."
                    );
                }
            }
        }
    };

    const openAppStore = () => {
        window.open(INNERVIEW_BANNER_APPSTORE_LINK, "_blank");
    };

    return (
        <ErrorPageContainer>
            <HeaderWrap>
                <img id={"logo"} src={Logo} alt="logo" />
            </HeaderWrap>
            <ErrorPageWrap>
                <ContentWrap>
                    <>
                        <img src={SadFace} alt="icon" />
                        <h1>{title}</h1>
                        <p>{message}</p>
                        {isButtonVisible ? (
                            <button onClick={openAppStore}>
                                이너뷰에 문의하기
                            </button>
                        ) : null}
                    </>
                </ContentWrap>
            </ErrorPageWrap>
        </ErrorPageContainer>
    );
};

const ContentWrap = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        display: block;
        width: 64px;
        margin-bottom: 32px;
        pointer-events: none;
    }

    h1 {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        color: #282b39;
        margin-bottom: 24px;
    }

    p {
        color: #696c77;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: -0.18px;
        max-width: 400px;
        word-break: keep-all;
    }

    button {
        margin-top: 32px;
        padding: 0 56px;
        height: 48px;
        border-radius: 50px;
        background: var(--primary-500, #7386e8);

        color: var(--white, #fff);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
    }
`;

const ErrorPageContainer = styled.div`
    padding-top: 80px;
    width: 100vw;
    height: 100vh;
    min-width: 600px;
    min-height: 500px;
`;

const ErrorPageWrap = styled.div`
    width: 100%;
    height: 100%;
    padding: 40px;
    background-color: #f8f8f9;
`;

const HeaderWrap = styled.div`
    min-width: 660px;
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    background-color: var(--primary-1000);
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 32px;

    img#logo {
        width: 46px;
        pointer-events: none;
    }
`;

export default Error;
