import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";
import { store } from "./redux/store";

import theme from "./styles/theme";
import GlobalStyle from "./styles/GlobalStyle";
import route from "./routes";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import Modal from "./components/modal/Modal";
import Confirm from "./components/confirm/Confirm";
import DatePickerModal from "./components/datePickerModal/DatePickerModal";

const App = () => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false, // default: true
                retry: (failureCount, error) => {
                    if(error.response?.data?.code === 'AUTH' && error.response?.data?.message === 'Expired user.') {
                        return false;
                    }
                    return failureCount < 3;
                },
            },
        },
    });
    return (
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <ThemeProvider theme={theme["light"]}>
                    <GlobalStyle />
                    {route()}
                    <StyledToastContainer closeButton={false} />
                    <Modal />
                    <Confirm />
                    <DatePickerModal />
                </ThemeProvider>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </Provider>
    );
};

const StyledToastContainer = styled(ToastContainer)`
    --toastify-color-dark: var(--gray-black);
    .Toastify__toast {
        min-height: 0;
        padding: 8px 20px;
    }
    .Toastify__toast-body {
        padding: 0;

        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: -0.01em;
    }
    .Toastify__toast-icon {
        width: 24px;
        margin-inline-end: 8px;
    }
`;

export default App;
