import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ErrorIcon from "../../../assets/images/icons/ic-input-warning.svg";

const Input = ({
    onChange,
    value,
    error,
    errorText,
    size,
    placeHolder,
    isDisabled,
    maxLength,
}) => {
    return (
        <InputWrap $size={size}>
            <input
                className={error ? "error" : ""}
                onChange={onChange}
                value={value}
                maxLength={maxLength}
                placeholder={placeHolder}
                disabled={isDisabled}
            />
            {error ? (
                <>
                    <img src={ErrorIcon} alt="input error icon" />
                    <p className={"warning-text"}>{errorText}</p>
                </>
            ) : null}
        </InputWrap>
    );
};
const InputWrap = styled.div`
    position: relative;
    font-size: 0;
    width: 100%;
    //max-width: 400px;
    text-align: left;

    img {
        position: absolute;
        z-index: 1;
        right: 12px;
        top: ${(props) => (props.$size === "m" ? "12px" : "10px")};
    }
    p {
        margin-top: 4px;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: var(--error-300);
    }

    input {
        transition: all 0.2s ease-in-out;
        width: 100%;
        height: ${(props) => (props.$size === "m" ? "40px" : "36px")};
        background: #ffffff;
        border: 1px solid var(--gray-100);
        border-radius: 3px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.01em;
        padding: 0 16px;
        color: var(--gray-black);

        &:focus {
            border-color: var(--primary-400);
            box-shadow: 0 0 10px rgba(115, 134, 232, 0.15);
            caret-color: var(--primary-400);
            &::placeholder {
                color: transparent;
            }
        }

        &:disabled {
            border-color: var(--gray-100);
            background-color: var(--gray-50);
            color: var(--gray-300);
        }

        &::placeholder {
            font-weight: 400;
            color: var(--gray-400);
        }

        &.error {
            padding-right: 40px;
            color: var(--error-300);
            border-color: var(--error-200);
            box-shadow: 0 0 10px rgba(233, 98, 114, 0.15);
        }
    }
`;

Input.propTypes = {
    onChange: PropTypes.func.isRequired,
    size: PropTypes.oneOf(["m", "s"]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeHolder: PropTypes.string,
    isDisabled: PropTypes.bool,
    error: PropTypes.bool,
    errorText: PropTypes.string,
    maxLength: PropTypes.number,
};

Input.defaultProps = {
    error: false,
    placeHolder: "",
    size: "m",
    isDisabled: false,
    errorText: "텍스트 형식 오류",
};

export default Input;
