import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";

import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import "swiper/css";
import "swiper/css/effect-fade";
import "./styles/_override.scss";

const container = document.getElementById("root");
createRoot(container).render(<App />);
