import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { useSelector, useDispatch } from "react-redux";
import { closeModal } from "../../redux/ModalSlice";
import { resetImageModal } from "../../redux/ImageModalSlice";

import SubscribeModal from "./SubscribeModal";
import ScriptInstallModal from "./ScriptInstallModal";
import BannerRestoreModal from "./BannerRestoreModal";
import AddFolderModal from "./AddFolderModal";
import FolderConfirmModal from "./FolderConfirmModal";
import BannerCodeModal from "./BannerCodeModal";
import CategoryConnectModal from "./CategoryConnectModal";
import ProductSearchModal from "./ProductSearchModal";
import ImageManageModal from "./ImageManageModal";
import BannerGroupTutorialModal from "./tutorial/BannerGroupTutorialModal";
import BannerConfigTutorialModal from "./tutorial/BannerConfigTutorialModal";
import ImageManageTutorialModal from "./tutorial/ImageManageTutorialModal";
import ProductConnectTutorialModal from "./tutorial/ProductConnectTutorialModal";
import CategoryConnectTutorialModal from "./tutorial/CategoryConnectTutorialModal";
import UserPlanInfoModal from "./UserPlanInfoModal";
import WarningModal from "./WarningModal";
import ExpireTrailInfoModal from "./ExpireTrailInfoModal";
import ExpireTrailWarnModal from "./ExpireTrailWarnModal";

import CloseIcon from "../../assets/images/icons/ic-close.svg";

import { MODAL_TYPES } from "../../constants";

const modalComponents = {
    [MODAL_TYPES.SUBSCRIBE]: SubscribeModal,
    [MODAL_TYPES.SCRIPT_INSTALL]: ScriptInstallModal,
    [MODAL_TYPES.BANNER_RESTORE]: BannerRestoreModal,
    [MODAL_TYPES.ADD_FOLDER]: AddFolderModal,
    [MODAL_TYPES.FOLDER_CONFIRM]: FolderConfirmModal,
    [MODAL_TYPES.BANNER_CODE]: BannerCodeModal,
    [MODAL_TYPES.CATEGORY_CONNECT]: CategoryConnectModal,
    [MODAL_TYPES.PRODUCT_SEARCH]: ProductSearchModal,
    [MODAL_TYPES.IMAGE_MANAGE]: ImageManageModal,
    [MODAL_TYPES.USER_PLAN_INFO]: UserPlanInfoModal,
    [MODAL_TYPES.WARNING]: WarningModal,
    [MODAL_TYPES.EXPIRE_TRAIL_INFO]: ExpireTrailInfoModal,
    [MODAL_TYPES.EXPIRE_TRAIL_WARN]: ExpireTrailWarnModal,
    [MODAL_TYPES.TUTORIAL.BANNER_GROUP]: BannerGroupTutorialModal,
    [MODAL_TYPES.TUTORIAL.BANNER_CONFIG]: BannerConfigTutorialModal,
    [MODAL_TYPES.TUTORIAL.IMAGE_MANAGE]: ImageManageTutorialModal,
    [MODAL_TYPES.TUTORIAL.PRODUCT_CONNECT]: ProductConnectTutorialModal,
    [MODAL_TYPES.TUTORIAL.CATEGORY_CONNECT]: CategoryConnectTutorialModal,
};

const Modal = ({ className }) => {
    const [isDimmerClicked, setIsDimmerClicked] = useState(false);
    const { isOpen, modalType, modalProps, title, closeFunc } = useSelector(
        (state) => state.modal
    );
    const { isInitialized } = useSelector((state) => state.imageModal);
    const dispatch = useDispatch();
    const { folderName } = useSelector((state) => state.folder);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Escape" && isOpen) {
                handleCloseModal();
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [isOpen, dispatch]);

    const handleCloseModal = () => {
        if(modalProps.modalPrevented){return}
        if (closeFunc) {
            if (modalType === MODAL_TYPES.ADD_FOLDER) {
                closeFunc(() => {
                    dispatch(closeModal());
                    dispatch(resetImageModal());
                }, folderName);
            } else if (modalType === MODAL_TYPES.IMAGE_MANAGE) {
                closeFunc(() => {
                    dispatch(closeModal());
                    dispatch(resetImageModal());
                }, isInitialized);
            } else {
                closeFunc(() => {
                    dispatch(closeModal());
                    dispatch(resetImageModal());
                });
            }
        } else {
            dispatch(closeModal());
            dispatch(resetImageModal());
        }
    };

    const clickContainer = (e) => {
        e.stopPropagation();
    };

    const handleMouseDown = (e) => {
        e.stopPropagation();
        if (e.target.className.includes("dimmer-layout")) {
            setIsDimmerClicked(true);
        }
    };

    const handleMouseUp = (e) => {
        e.stopPropagation();
        if (isDimmerClicked) {
            setIsDimmerClicked(false);
            handleCloseModal();
        }
    };

    if (!isOpen || !modalType) {
        return null;
    }

    const ModalComponent = modalComponents[modalType];

    return (
        <Dimmer
            className={`dimmer-layout ${className}`}
            // onClick={clickDimmer}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
        >
            <TableDisplay className={"dimmer-layout"}>
                <ModalContainer
                    className={"modal-container"}
                    $width={modalProps.width}
                    onClick={clickContainer}
                >
                    <ModalTitleWrap $title={title}>
                        <h1>{title ? title : ""}</h1>
                        {
                            !modalProps.modalPrevented &&
                            <button onClick={handleCloseModal}>
                                <img src={CloseIcon} alt="modal close icon" />
                            </button>
                        }
                    </ModalTitleWrap>
                    <ModalComponent
                        handleCloseModal={handleCloseModal}
                        {...modalProps}
                    />
                </ModalContainer>
            </TableDisplay>
        </Dimmer>
    );
};

const TableDisplay = styled.div`
    display: table;
    width: 100%;
`;

const Dimmer = styled.div`
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ModalContainer = styled.article`
    box-sizing: content-box;
    min-width: 320px;
    max-width: ${(props) => (props.$width ? `${props.$width}px` : "auto")};
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(98, 120, 233, 0.1);
    background-color: #ffffff;
    border: 1px solid #cbcbcb;
    border-radius: 10px;
`;

export const ModalBody = styled.div`
    padding: ${(props) => (props.$title ? "32px 40px 16px" : "20px 40px 16px")};
    width: 100%;
    max-height: 778px;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 12px;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--gray-400);
        border-radius: 100px;
        border-right: 4px solid #fff;
        border-left: 4px solid #fff;
        border-top: 2px solid #fff;
        border-bottom: 2px solid #fff;
    }
    &::-webkit-scrollbar-track {
        width: 12px;
        background-color: transparent;
    }
`;

export const ModalButtonWrap = styled.div`
    padding: 16px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ModalTitleWrap = styled.div`
    width: 100%;
    height: 68px;
    background-color: ${(props) =>
        props.$title ? "var(--primary-100)" : "#fff"};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 40px;
    padding-right: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    h1 {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.01em;
        color: var(--gray-black);
    }
    button {
        width: 68px;
        height: 68px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
export default Modal;
