import React from "react";
import styled from "styled-components";
import { ModalButtonWrap, ModalBody } from "./Modal";

import { RoundButton } from "../common";
import SadFace from "../../assets/images/sad-face.png";
import paymentManager from "../../apis/paymentManager";
import { showToast } from "../../redux/ToastSlice";
import { useDispatch } from "react-redux";
//
const ExpireTrailWarnModal = () => {
    const dispatch = useDispatch();

    const onClickContact = async () => {
        try {
            const result = await paymentManager.createPayment({
                redirect_url: window.location.href,
            });
            window.open(result, "_blank");
        } catch (e) {
            console.log(e);
            dispatch(showToast("에러가 발생했습니다.\n이너뷰에 문의해주세요."));
        }
    };


    return (
        <ModalBody>
            <ImgWrapping><img src={SadFace} alt="icon" /></ImgWrapping>
            <ModalTextTitle>무료 기능 지원 종료 안내</ModalTextTitle>
            <ModalText>
                배너관리앱은 결제 이후 사용하실 수 있습니다.<br />
                쇼핑몰 배너를 효과적으로 운영하려면<br />
                구독해 주세요.
            </ModalText>
            <ModalButtonWrap>
                <RoundButton onClick={onClickContact}>구독하기</RoundButton>
            </ModalButtonWrap>
        </ModalBody>
    );
};
const ImgWrapping = styled.div`
    text-align: center;
    img{width: 56px; height: 56px;}
`;
const ModalTextTitle = styled.h1`
    padding-top: 20px;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.01em;
    color: #282B39;
    text-align: center;
`;

const ModalText = styled.p`
    margin-top: 12px;
    margin-bottom: 8px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #696C77;
    white-space: pre-line;
`;

export default ExpireTrailWarnModal;
