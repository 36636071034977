import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isOpen: false,
    modalType: null,
    title: "",
    modalProps: null,
    closeFunc: null,
};

const modalSlice = createSlice({
    name: "modal",
    initialState: initialState,
    reducers: {
        openModal: (state, action) => {
            if (!action.payload) return console.log("no payload");
            const { modalType, modalProps } = action.payload;
            const isOpen = true;
            const title = modalProps.title || "";
            const closeFunc = action.payload.closeFunc || null;
            Object.assign(state, {
                modalType,
                isOpen,
                modalProps,
                title,
                closeFunc,
            });
        },
        closeModal: (state) => {
            Object.assign(state, { ...initialState });
        },
        removeCloseFunc: (state) => {
            state.closeFunc = null;
        },
    },
});

export const { removeCloseFunc, openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;
