import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ErrorIcon from "../../../assets/images/icons/ic-input-warning.svg";

const Textarea = ({
    onChange,
    text,
    height,
    error,
    errorText,
    maxLength,
    isResizeable,
}) => {
    return (
        <TextFieldWrap>
            <TextField
                $isResizeable={isResizeable}
                $height={height}
                className={error ? "error" : ""}
                onChange={onChange}
                cols={"40"}
                maxLength={maxLength}
                value={text}
            />
            {error ? (
                <>
                    <img src={ErrorIcon} alt="input error icon" />
                    <p className={"warning-text"}>{errorText}</p>
                </>
            ) : null}
        </TextFieldWrap>
    );
};

const TextFieldWrap = styled.div`
    position: relative;
    font-size: 0;
    text-align: left;

    img {
        position: absolute;
        z-index: 1;
        right: 12px;
        top: 12px;
    }
    p {
        margin-top: 4px;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: var(--error-300);
    }
`;

const TextField = styled.textarea`
    display: block;
    width: 100%;
    resize: ${(props) => (props.$isResizeable ? "vertical" : "none")};
    max-height: 280px;
    height: ${(props) => (props.$height ? props.$height + "px" : "auto")};
    background: #ffffff;
    border: 1px solid var(--gray-100);
    border-radius: 3px;
    padding: 16px;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: var(--gray-black);

    &:focus {
        border-color: var(--primary-400);
        box-shadow: 0 0 10px rgba(115, 134, 232, 0.15);
        caret-color: var(--primary-400);
        &::placeholder {
            color: transparent;
        }
    }

    &:disabled {
        border-color: var(--gray-100);
        background-color: var(--gray-50);
        color: var(--gray-300);
    }

    &.error {
        padding-right: 40px;
        color: var(--error-300);
        border-color: var(--error-200);
        box-shadow: 0 0 10px rgba(233, 98, 114, 0.15);
    }

    &::-webkit-scrollbar {
        width: 12px;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--gray-400);
        border-radius: 100px;
        border-right: 4px solid #fff;
        border-left: 4px solid #fff;
        border-top: 2px solid #fff;
        border-bottom: 2px solid #fff;
    }
    &::-webkit-scrollbar-track {
        width: 12px;
        background-color: transparent;
    }
`;

Textarea.propTypes = {
    onChange: PropTypes.func.isRequired,
    text: PropTypes.string,
    height: PropTypes.number,
    error: PropTypes.bool,
    errorText: PropTypes.string,
    isResizeable: PropTypes.bool,
};

Textarea.defaultProps = {
    isResizeable: true,
};

export default Textarea;
