import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const RemoveButton = ({ onClick, isDisabled }) => {
    return <Button onClick={onClick} disabled={isDisabled} />;
};

const Button = styled.button`
    position: relative;
    transition: all 0.15s ease-in-out;
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border: 1px solid var(--gray-200);
    border-radius: 3px;

    &::before {
        content: " ";
        width: 8px;
        height: 2px;
        border-radius: 2px;
        display: block;
        background-color: var(--primary-400);
        transition: all 0.15s ease-in-out;
    }

    &:hover {
        &:not(:disabled) {
            border: 1px solid #bcbcbc;
            &::before {
                background-color: var(--primary-500);
            }
        }
    }

    &:disabled {
        border: 1px solid var(--gray-100);
        background-color: var(--gray-50);
        &::before {
            background-color: var(--gray-300);
        }
    }
`;

RemoveButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
};

RemoveButton.defaultProps = {
    isDisabled: false,
};

export default RemoveButton;
