import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import LightIcon from "../../assets/images/icons/ic-light@2x.png";

const Notice = ({ text }) => {
    return (
        <NoticeContainer>
            <p dangerouslySetInnerHTML={{ __html: text }}></p>
        </NoticeContainer>
    );
};

const NoticeContainer = styled.div`
    padding: 10px 16px;
    background: var(--gray-50);
    border-radius: 4px;

    p {
        position: relative;
        padding-left: 28px;
        background-image: url(${LightIcon});
        background-size: 20px;
        background-position: top left;
        background-repeat: no-repeat;

        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: var(--gray-800);
        white-space: pre-wrap;

        a {
            text-decoration: underline;
        }

        b {
            font-weight: 700 !important;
        }

        span.example-text {
            color: #afb2c1 !important;
        }
    }
`;

Notice.propTypes = {
    text: PropTypes.string.isRequired,
};
Notice.defaultProps = {};
export default Notice;
