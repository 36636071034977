import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const GlobalStyle = createGlobalStyle`
  ${reset}
  
  *, *::before, *::after {
    box-sizing: border-box;
  }
  
  * {
    font-family: 'Noto Sans KR', sans-serif !important;
  }
  
  body {
    min-width: 320px !important;
    overflow: hidden;
  }
  
  #root {
    position: relative;
    overflow: hidden;
  }
  
  th, td {
    vertical-align: middle;
  }
  
  button {
    cursor: pointer;
    background: none;
    border: 0;
    outline: 0;
    padding: 0;
    &:disabled {
      cursor: not-allowed;
    }
  }
  
  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }
  
  input, textarea {
    outline: none;
    &:disabled {
      cursor: not-allowed;
    }
  }
  
  h1, p, span {
    letter-spacing: -0.01em;
  }

  .displaynone{display: none !important;}

  select::-ms-expand {display: none;}
  span{color: inherit; font-size: inherit; line-height: inherit; font-family: inherit;}
  label{cursor: pointer;}
  /* ie input icon display:none */
  input::-ms-clear, input::-ms-reveal {
    display: none;
  }
  .hidden{
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 0 !important;
    height: 0 !important;
    min-width: initial !important;
    min-height: initial !important;
    overflow: hidden !important;
    opacity: 0 !important;
    font-size: 0 !important;
    line-height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    border: none !important;
  }
  
  html {
    --white: #fff;

    --primary-50: #f9f9fd;
    --primary-100: #eff1fa;
    --primary-200: #E8EAFB;
    --primary-300: #C5CAF5;
    --primary-400: #9DA9EE;
    --primary-500: #7386E8;
    --primary-600: #515CDA;
    --primary-700: #3947A7;
    --primary-800: #242F86;
    --primary-900: #161E6F;
    --primary-950: #1E2148;
    --primary-1000: #2C2F42;

    --gray-50: #F9F9FA;
    --gray-100: #E9E9ED;
    --gray-200: #DFE0E6;
    --gray-300: #CBCDD7;
    --gray-400: #AFB2C1;
    --gray-500: #9B9FAF;
    --gray-600: #8A8E9C;
    --gray-700: #696C77;
    --gray-800: #5D5E67;
    --gray-900: #3E3F45;
    --gray-black: #282B39;

    --error-50: #FFE9EE;
    --error-100: #FFC8D2;
    --error-200: #F29099;
    --error-300: #E96272;
    --error-400: #F4324F;
    --error-500: #F90036;
    --error-600: #E90035;
    --error-700: #D7002F;
    --error-800: #CA0027;
    --error-900: #BB001C;
  }
`;

export default GlobalStyle;
