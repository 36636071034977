import React, { useState, useEffect } from "react";
import styled from "styled-components";

import NoContents from "./NoContents";
import { resetBannerGroupState } from "../../redux/BannerGroupSlice";
import { resetSelectedProducts } from "../../redux/ProductSlice";
import { emptyCategories } from "../../redux/CategorySlice";
import { useDispatch, useSelector } from "react-redux";
import { getCookies, setCookie } from "../../utils";
import { useNavigate } from "react-router-dom";
import { openModal } from "../../redux/ModalSlice";
import { EXPIRE_TRAIL_DUE_DATE, EXPIRE_TRAIL_INFO_DATE, MODAL_PAYLOAD } from "../../constants";
import moment from "moment/moment";
import { useQuery } from "@tanstack/react-query";
import paymentManager from "../../apis/paymentManager";

const Index = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let getPaymentInfo = useSelector( (state)=>{ return state.paymentInfo } );
    const currentDate = moment();
    const isExpireInfoDone = sessionStorage.getItem('isExpireInfoDone');
    useEffect(() => {
        if(getPaymentInfo.mall_id && getPaymentInfo.version === 'Expired'){
            dispatch(openModal({ ...MODAL_PAYLOAD.EXPIRE_TRAIL_WARN }));
            // 팝업 실행
        }
    }, [getPaymentInfo]);
    useEffect(() => {
        dispatch(resetBannerGroupState());
        dispatch(resetSelectedProducts());
        dispatch(emptyCategories());
    }, []);
    return <NoContents />;
};

export default Index;
