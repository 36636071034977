import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Loading } from "../components/common";
import { Navigate, Outlet } from "react-router-dom";
import Header from "../components/layouts/Header";
import SideNav from "../components/layouts/SideNav";
import { getCookies, setCookie } from "../utils";
import mallManager from "../apis/mallManager";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import {
    VERSION_2_UPDATE_DATE,
    UPDATE_POPUP_DUE_DATE,
    FAQ_V2_UPGRADE_URL, MODAL_PAYLOAD,
} from "../constants";
import { closeConfirm, openConfirm } from "../redux/ConfirmSlice";
import { json } from "react-router-dom";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../redux/ModalSlice";
import paymentManager from "../apis/paymentManager";



import { setPaymentVersion } from "../redux/PaymentInfoSlice";


const Root = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { isLoading } = useSelector((state) => state.globalLoading);
    const { data, refetch } = useQuery({
        queryKey: ["mallInfo"],
        queryFn: mallManager.getMallInfo,
        enabled: false,
    });
    const { data: paymentInfo } = useQuery({
        queryKey: ["paymentInfo"],
        queryFn: paymentManager.getPayment,
    });

    useEffect(() => {
        if(paymentInfo)
        dispatch(setPaymentVersion(paymentInfo));
    }, [paymentInfo]);

    useEffect(() => {
        const isTutorialDone = getCookies("isTutorialDone");
        if (isTutorialDone) {
            refetch();
        }
    }, []);

    const checkEntrance = () => {
        const queryToken = searchParams.get("t");
        const headerToken = getCookies("ivBanner_auth");
        const isRedirected = getCookies("isRedirected");
        const hostname = window.location.hostname;
        if (!isRedirected) {
            if (queryToken !== headerToken) {
                alert("비정상적인 접근입니다.\n카페24를 통해 이용해주세요.");
                window.close();
                throw json(
                    "비정상적인 접근입니다.\n카페24를 통해 이용해주세요.",
                    {
                        status: 400,
                        statusText: "wrong entrypoint",
                    }
                );
            } else {
                setCookie("isRedirected", true, 1);
            }
        }
    };

    const checkRenderPoint = () => {
        const IS_MAINTENANCE = process.env.IS_MAINTENANCE;

        if (IS_MAINTENANCE == 1) {
            return <Navigate to={"/maintenance"} />;
        }

        const auth = getCookies("ivBanner_auth");
        if (!auth) {
            return <Navigate to={"/error?status=401_mallId"} />;
        }
        return <Outlet />;
    };
    return (
        <>
            <Header />
            <SideNav />
            <ContentWrap>
                {isLoading ? (
                    <GlobalLoadingWrap>
                        <Loading isLoading={isLoading} />
                    </GlobalLoadingWrap>
                ) : null}
                <PageContainer>{checkRenderPoint()}</PageContainer>
            </ContentWrap>
        </>
    );
};

const GlobalLoadingWrap = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    top: 0;
    left: 0;
`;

const ContentWrap = styled.div`
    background-color: ${(props) => props.theme.bgColor};
    width: 100%;
    padding-top: 80px;
    padding-left: 320px;
    min-width: 720px;
    height: 100vh;
`;

const PageContainer = styled.article`
    height: 100%;
    //padding: 40px 0;
    position: relative;
    width: 100%;
    overflow: hidden;
    background-color: var(--white);
`;
export default Root;
