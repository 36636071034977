import { instance } from "./index";

const getMallInfo = () => {
    return instance.get("/mall/info");
};

const mallManager = {
    getMallInfo,
};

export default mallManager;
