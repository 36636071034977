import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { ModalBody } from "./Modal";
import { useDispatch } from "react-redux";
import { showToast } from "../../redux/ToastSlice";

import paymentManager from "../../apis/paymentManager";
import IcLight from "../../assets/images/icons/ic-light@2x.png";
import "./style/_banner-code-modal.scss";

const BannerCodeModal = ({ bannerGroup }) => {
    const dispatch = useDispatch();
    const [paymentInfo, setPaymentInfo] = useState({});
    useEffect(() => {
        getPaymentInfo();
    }, []);

    const getPaymentInfo = async () => {
        const result = await paymentManager.getPayment();
        setPaymentInfo(result);
    };
    const copyCode = async () => {
        await navigator.clipboard.writeText(
            `<div id="ivBanner_${bannerGroup.id}"></div>`
        );
        dispatch(showToast("코드가 복사되었습니다."));
    };

    return (
        <ModalBody id="bannerCodeModalContent">
            <div className="iv_txt">
                <ul>
                    <li>
                        <span>
                            <img src={IcLight} alt="아이콘" />
                        </span>
                        <p>
                            아래의 코드를 복사해서 쇼핑몰 html 파일 내 원하는
                            위치에 삽입해 주세요.
                        </p>
                    </li>
                </ul>
            </div>
            <div className="code_clone_wrap">
                <textarea
                    name="banner_code_text_area"
                    className="code_clone_in"
                    readOnly
                    value={`<div id="ivBanner_${bannerGroup.id}"></div>`}
                ></textarea>
            </div>
            <StyledBtnWrap>
                <button
                    type="button"
                    className="iv_btn clone_btn"
                    onClick={copyCode}
                >
                    코드 복사
                </button>
            </StyledBtnWrap>
        </ModalBody>
    );
};

const StyledBtnWrap = styled.div`
    padding: 16px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export default BannerCodeModal;
