import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import prevBtn from "../../assets/images/icons/ic-prev-btn.png";
import nextBtn from "../../assets/images/icons/ic-next-btn.png";

const Pagination = ({ currPage, totalCount, itemsPerPage, onPageChange }) => {
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        const calculatedTotalPages = Math.ceil(totalCount / itemsPerPage);
        setTotalPages(calculatedTotalPages);
    }, [totalCount, itemsPerPage]);

    const maxVisiblePages = 10; // 한 번에 표시할 최대 페이지 버튼 수
    const maxPageGroup = Math.ceil(totalPages / maxVisiblePages); // 전체 페이지를 maxVisiblePages 단위로 나눈 그룹 수
    const currentPageGroup = Math.ceil(currPage / maxVisiblePages); // 현재 페이지가 속한 그룹 번호
    const startPage = (currentPageGroup - 1) * maxVisiblePages + 1; // 시작 페이지
    const endPage = Math.min(startPage + maxVisiblePages - 1, totalPages); // 끝 페이지

    const goToPrevPageGroup = () => {
        const prevPageGroup = currentPageGroup - 1;
        if (prevPageGroup >= 1) {
            onPageChange((prevPageGroup - 1) * maxVisiblePages + 1);
        }
    };

    const goToNextPageGroup = () => {
        const nextPageGroup = currentPageGroup + 1;
        if (nextPageGroup <= maxPageGroup) {
            onPageChange((nextPageGroup - 1) * maxVisiblePages + 1);
        }
    };

    const goToPage = (pageNumber) => {
        if (pageNumber !== currPage) {
            onPageChange(pageNumber);
        }
    };

    return (
        <PaginationContainer>
            <PaginationWrap>
                <button
                    onClick={goToPrevPageGroup}
                    className="page-direction-btn prev-btn"
                    disabled={currentPageGroup === 1}
                ></button>
                <div className="page-number-btn-wrap">
                    {Array.from(
                        { length: endPage - startPage + 1 },
                        (_, i) => startPage + i
                    ).map((pageNumber) => (
                        <button
                            key={pageNumber}
                            onClick={() => goToPage(pageNumber)}
                            className={`page-numbers ${
                                pageNumber === currPage ? "active" : ""
                            }`}
                        >
                            {pageNumber}
                        </button>
                    ))}
                </div>
                <button
                    onClick={goToNextPageGroup}
                    disabled={currentPageGroup === maxPageGroup}
                    className="page-direction-btn next-btn"
                ></button>
            </PaginationWrap>
        </PaginationContainer>
    );
};

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const PaginationWrap = styled.div`
    // 항목이 10개면 min-width
    display: flex;
    align-items: center;
    gap: 16px;
    button {
        width: 30px;
        height: 30px;
        border-radius: 2px;
    }
    .prev-btn {
        background-image: url(${prevBtn});
        background-repeat: no-repeat;
        background-size: 30%;
        background-position: center;
    } 
    .next-btn {
        background-image: url(${nextBtn});
        background-repeat: no-repeat;
        background-size: 30%;
        background-position: center;
    }

    button.page-direction-btn {
        transition: all 0.2s ease-in-out;
        background-color: var(--primary-100);
        opacity: 0.5;

        &:not(:disabled) {
            &:hover {
                opacity: 0.8;
            }
        }

        &:disabled {
            cursor: default;
        }
    }

    .page-number-btn-wrap {
        transition: all 0.2s ease-in-out;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        button.page-numbers {
            color: var(--primary-500);
            background-color: #fff;
            &.active {
                cursor: default;
                color: #fff;
                background-color: var(--primary-500);
            }
            &:not(.active) {
                &:hover {
                    background-color: var(--primary-50);
                }
            }
        }
    }
`;

Pagination.propTypes = {};
Pagination.defaultProps = {};

export default Pagination;
