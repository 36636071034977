import { configureStore } from "@reduxjs/toolkit";

import modalReducer from "./ModalSlice";
import toastReducer from "./ToastSlice";
import confirmReducer from "./ConfirmSlice";
import imageModalReducer from "./ImageModalSlice";
import productReducer from "./ProductSlice";
import bannerGroupReducer from "./BannerGroupSlice";
import categoryReducer from "./CategorySlice";
import datePickerModalReducer from "./DatePickerModalSlice";
import globalLoadingReducer from "./GlobalLoadingSlice";
import folderReducer from "./FolderSlice";
import paymentReducer from "./PaymentInfoSlice";
export const store = configureStore({
    reducer: {
        modal: modalReducer,
        toast: toastReducer,
        confirm: confirmReducer,
        imageModal: imageModalReducer,
        product: productReducer,
        bannerGroup: bannerGroupReducer,
        category: categoryReducer,
        datePicker: datePickerModalReducer,
        globalLoading: globalLoadingReducer,
        folder: folderReducer,
        paymentInfo: paymentReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});
