import React, { useEffect, useState } from "react";
import { ModalBody } from "./Modal";

import styled from "styled-components";
import { Checkbox, Input, RoundButton, SelectBox } from "../common";
import { Tooltip } from "../index";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import productManager from "../../apis/productManager";
import Pagination from "../pagination/Pagination";
import { setSelectedProducts } from "../../redux/ProductSlice";

import "./style/_product-search-modal.scss";

const PRODUCT_SEARCH_TYPE_OPTIONS = [
    { label: "상품 명", value: "product_name" },
    { label: "상품 코드", value: "product_code" },
];

const SELLING_KEYWORD = {
    T: "판매 중",
    F: "판매 안함",
};

const DISPLAY_KEYWORD = {
    T: "진열 중",
    F: "진열 안함",
};

const ProductSearchModal = ({ handleCloseModal }) => {
    const selectedProducts = useSelector(
        (state) => state.product.selectedProducts
    );
    const dispatch = useDispatch();
    const [searchType, setSearchType] = useState(
        PRODUCT_SEARCH_TYPE_OPTIONS[0].value
    );
    const [isAllCheck, setIsAllCheck] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [checkOptions, setCheckOptions] = useState({
        selling_T: false,
        selling_F: false,
        display_T: false,
        display_F: false,
    });
    const [currPage, setCurrPage] = useState(1);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const { data: products, refetch } = useQuery({
        queryKey: ["getProducts"],
        queryFn: () => {
            return productManager.getProducts({
                searchText,
                searchType,
                checkOptions,
                currPage,
            });
        },
    });

    //
    useEffect(() => {
        if (selectedProducts.length) {
            setSelectedCheckboxes(selectedProducts);
        }
    }, []);

    useEffect(() => {
        refetch();
    }, [currPage]);

    useEffect(() => {
        if (products?.products) {
            const isAllSelected = products.products.every((product) =>
                selectedCheckboxes.some(
                    (selectedProduct) =>
                        selectedProduct.product_no === product.product_no
                )
            );

            setIsAllCheck(isAllSelected);
        }
    }, [selectedCheckboxes, products]);

    const toggleAllCheck = (e) => {
        const isChecked = e.target.checked;

        if (isChecked) {
            setSelectedCheckboxes((prevSelectedCheckboxes) => {
                const pageProducts = products?.products || [];
                return Array.from(
                    new Set([...prevSelectedCheckboxes, ...pageProducts])
                );
            });
        } else {
            setSelectedCheckboxes((prevSelectedCheckboxes) => {
                const pageProductIds =
                    products?.products?.map((product) => product.product_no) ||
                    [];
                return prevSelectedCheckboxes.filter((selectedProduct) => {
                    const selectedProductId = selectedProduct.product_no;
                    return !pageProductIds.includes(selectedProductId);
                });
            });
        }
    };

    // 한 항목을 체크했을때
    const onCheckProduct = (e, product) => {
        const isChecked = e.target.checked;

        setSelectedCheckboxes((prevSelectedCheckboxes) => {
            if (isChecked) {
                return [...prevSelectedCheckboxes, product];
            } else {
                return prevSelectedCheckboxes.filter(
                    (selectedProduct) =>
                        selectedProduct.product_no !== product.product_no
                );
            }
        });
    };

    const linkProduct = () => {
        dispatch(setSelectedProducts(selectedCheckboxes));
        handleCloseModal();
    };

    const onSelectSearchType = (val) => {
        setSearchType(val);
    };

    const onChangeSearchText = (e) => {
        setSearchText(e.target.value);
    };

    const searchProduct = () => {
        refetch();
    };

    const setFilter = (e) => {
        setCurrPage(1);
        setCheckOptions((prevState) => {
            return { ...prevState, [e.target.value]: e.target.checked };
        });
        setTimeout(() => {
            refetch();
        });
    };

    const onChangePage = (page) => {
        setCurrPage(page);
    };

    return (
        <ModalBody id={"productSearchModalContent"}>
            <div className="con_wrpa">
                <div className="set_sction search_wrap">
                    <div className="title_area">
                        <h2>상품 검색</h2>
                    </div>
                    <div className="bnr_search">
                        <table className="in_table">
                            <colgroup>
                                <col width="160" />
                                <col width="" />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>상품 검색</th>
                                    <td>
                                        <div className="wrap">
                                            <div className="prd_search">
                                                <SelectBox
                                                    options={
                                                        PRODUCT_SEARCH_TYPE_OPTIONS
                                                    }
                                                    value={searchType}
                                                    placeholder={"검색어 타입"}
                                                    size={"l"}
                                                    initialValue
                                                    onSelect={
                                                        onSelectSearchType
                                                    }
                                                />
                                                <Input
                                                    onChange={
                                                        onChangeSearchText
                                                    }
                                                    value={searchText}
                                                    placeHolder={
                                                        "검색어를 입력해주세요"
                                                    }
                                                    size={"m"}
                                                />
                                                <div className="btn">
                                                    <button
                                                        type="button"
                                                        className="search_btn"
                                                        onClick={searchProduct}
                                                    >
                                                        검색
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>판매/진열 상태</th>
                                    <td>
                                        <div className="wrap">
                                            <div className="chk_wrap">
                                                <div className="com_chk">
                                                    <Checkbox
                                                        id={`selling_T`}
                                                        onChange={setFilter}
                                                        label={"판매 중"}
                                                        isChecked={
                                                            checkOptions.selling_T
                                                        }
                                                        value={"selling_T"}
                                                    />
                                                </div>
                                                <div className="com_chk">
                                                    <Checkbox
                                                        id={`selling_F`}
                                                        onChange={setFilter}
                                                        label={"판매 안함"}
                                                        isChecked={
                                                            checkOptions.selling_F
                                                        }
                                                        value={"selling_F"}
                                                    />
                                                </div>
                                                <div className="com_chk">
                                                    <Checkbox
                                                        id={`display_T`}
                                                        onChange={setFilter}
                                                        label={"진열 중"}
                                                        isChecked={
                                                            checkOptions.display_T
                                                        }
                                                        value={"display_T"}
                                                    />
                                                </div>
                                                <div className="com_chk">
                                                    <Checkbox
                                                        id={`display_F`}
                                                        onChange={setFilter}
                                                        label={"진열 안함"}
                                                        isChecked={
                                                            checkOptions.display_F
                                                        }
                                                        value={"display_F"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="set_sction prd_list">
                    <div className="title_area title-with-tooltip">
                        <h2>상품 목록</h2>
                        <Tooltip
                            text={`상품 목록에서는 최대 5,000개까지 조회됩니다.\n[상품 검색] 기능을 이용하시면 모든 상품 조회가 가능합니다.`}
                        />
                    </div>
                    <div className="list_table">
                        <table role="table">
                            <thead>
                                <tr role="row">
                                    <th
                                        colSpan="1"
                                        role="columnheader"
                                        style={{ width: "60px" }}
                                    >
                                        <div className="chkAll_box">
                                            <Checkbox
                                                id={`checkAll`}
                                                isChecked={isAllCheck}
                                                onChange={toggleAllCheck}
                                            />
                                        </div>
                                    </th>
                                    <th
                                        colSpan="1"
                                        role="columnheader"
                                        style={{ width: "20px" }}
                                    >
                                        No
                                    </th>
                                    <th
                                        colSpan="1"
                                        role="columnheader"
                                        style={{ width: "auto" }}
                                    >
                                        상품명
                                    </th>
                                    <th
                                        colSpan="1"
                                        role="columnheader"
                                        style={{ width: "120px" }}
                                    >
                                        상품 번호
                                    </th>
                                    <th
                                        colSpan="1"
                                        role="columnheader"
                                        style={{ width: "120px" }}
                                    >
                                        판매 상태
                                    </th>
                                    <th
                                        colSpan="1"
                                        role="columnheader"
                                        style={{ width: "120px" }}
                                    >
                                        진열 상태
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {products?.products?.map((product, index) => (
                                    <tr key={index}>
                                        <td
                                            role="cell"
                                            style={{ width: "50px" }}
                                        >
                                            <div className="body_chk">
                                                <Checkbox
                                                    id={`product-${product.product_no}`}
                                                    isChecked={selectedCheckboxes.some(
                                                        (selectedProduct) =>
                                                            selectedProduct.product_no ===
                                                            product.product_no
                                                    )}
                                                    onChange={(e) =>
                                                        onCheckProduct(
                                                            e,
                                                            product
                                                        )
                                                    }
                                                />
                                            </div>
                                        </td>
                                        <td
                                            role="cell"
                                            style={{ width: "80px" }}
                                        >
                                            {index + 1 + (currPage - 1) * 10}
                                        </td>
                                        <td
                                            role="cell"
                                            style={{ width: "auto" }}
                                        >
                                            {product.product_name}
                                        </td>
                                        <td
                                            role="cell"
                                            style={{ width: "100px" }}
                                        >
                                            {product.product_code}
                                        </td>
                                        <td
                                            role="cell"
                                            style={{ width: "100px" }}
                                        >
                                            {SELLING_KEYWORD[product.selling]}
                                        </td>
                                        <td
                                            role="cell"
                                            style={{ width: "100px" }}
                                        >
                                            {DISPLAY_KEYWORD[product.display]}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="pagination_wrap">
                <Pagination
                    currPage={currPage}
                    totalCount={products?.count || 0}
                    itemsPerPage={10}
                    onPageChange={onChangePage}
                ></Pagination>
            </div>
            <div className="add_btn">
                <StyledRoundButton
                    onClick={linkProduct}
                    size={"l16"}
                    type={"fill"}
                    isDisabled={selectedCheckboxes.length === 0}
                >
                    추가
                </StyledRoundButton>
            </div>
        </ModalBody>
    );
};

const StyledRoundButton = styled(RoundButton)`
    background-color: var(--primary-500);
    border-color: var(--primary-500);
`;

export default ProductSearchModal;
