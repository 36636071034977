import React from "react";

import { ModalBody } from "./Modal";

import "./style/_script-install-modal.scss";

const ScriptInstallModal = (props) => {
    return (
        <ModalBody id="scriptInstallModalContent" $title>
            <div className="iv_txt">
                <ul>
                    <li>
                        <span>STEP 1</span>
                        <p>
                            카페24 관리자페이지 [디자인 관리] > [디자인
                            보관함]에 접속해 주세요.
                        </p>
                    </li>
                    <li>
                        <span>STEP 2</span>
                        <p>
                            배너를 적용하고자 하는 스킨의 [편집] 버튼을 눌러
                            주세요.
                        </p>
                    </li>
                    <li>
                        <span>STEP 3</span>
                        <p>
                            쇼핑몰에 공통으로 적용되는 레이아웃
                            &#60;body&#62;&#60;/body&#62; 태그 안쪽,
                            <br />맨 아래에 위 태그를 붙여 넣어 주세요.
                        </p>
                    </li>
                </ul>
            </div>
            <div className="script_wrap">
                <textarea
                    readOnly
                    name=""
                    id=""
                    className="script_in"
                    value={props.script_src}
                ></textarea>
            </div>
        </ModalBody>
    );
};

export default ScriptInstallModal;
