import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { TOAST_PROPS } from "../constants";

const toastSlice = createSlice({
    name: "toast",
    initialState: {
        toastMessage: "",
    },
    reducers: {
        showToast: (state, action) => {
            if (!action.payload) return console.log("no payload");
            toast(action.payload, TOAST_PROPS);
        },
    },
});

export const { showToast } = toastSlice.actions;
export default toastSlice.reducer;
