import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const BUTTON_STYLES_BY_SIZE = {
    disable: {
        bgColor: {
            fill: "var(--gray-100)",
            outline: "var(--gray-50)",
        },
        textColor: "var(--gray-300)",
        borderColor: "var(--gray-100)",
    },
    l16: {
        lineHeight: "24px",
        padding: {
            icon: "0 48px 0 42px",
            noIcon: "0 56px",
        },
        textColor: {
            fill: "#fff",
            outline: "var(--gray-black)",
        },
        height: "48px",
        fontSize: "16px",
        fillBgColor: "var(--gray-black)",
        border: {
            fill: "1px solid var(--gray-black)",
            outline: "1px solid var(--gray-black)",
        },
        icon: {
            paddingLeft: "34px",
            width: "24px",
        },
    },
    l: {
        lineHeight: "22px",
        padding: {
            icon: "0 40px 0 32px",
            noIcon: "0 40px",
        },
        textColor: {
            fill: "#fff",
            outline: "var(--primary-500)",
        },
        height: "48px",
        fontSize: "15px",
        fillBgColor: "var(--primary-500)",
        border: {
            fill: "1px solid var(--primary-500)",
            outline: "1px solid var(--primary-500)",
        },
        icon: {
            paddingLeft: "26px",
            width: "18px",
        },
    },
    m: {
        lineHeight: "22px",
        padding: {
            icon: "0 24px 0 20px",
            noIcon: "0 24px",
        },
        textColor: {
            fill: "#fff",
            outline: "var(--gray-800)",
        },
        height: "40px",
        fontSize: "14px",
        fillBgColor: "var(--primary-500)",
        border: {
            fill: "1px solid var(--primary-500)",
            fillGray: "1px solid var(--gray-800)",
            outline: "1px solid var(--gray-800)",
        },
        icon: {
            paddingLeft: "24px",
            width: "16px",
        },
    },
    s: {
        lineHeight: "20px",
        padding: {
            icon: "0 16px",
            noIcon: "0 16px",
        },
        textColor: {
            fill: "var(--gray-600)",
            outline: "var(--primary-500)",
        },
        height: "32px",
        fontSize: "13px",
        fillBgColor: "var(--primary-100)",
        border: {
            fill: "1px solid var(--primary-100)",
            outline: "1px solid var(--primary-500)",
        },
        icon: {
            paddingLeft: "20px",
            width: "16px",
        },
    },
};

const RoundButton = ({
    type,
    onClick,
    size,
    icon,
    disableIcon,
    children,
    isDisabled,
    className,
}) => {
    return (
        <Btn
            icon={isDisabled ? disableIcon : icon}
            type={type}
            size={size}
            onClick={onClick}
            disabled={isDisabled}
            className={className}
        >
            <span>{children}</span>
        </Btn>
    );
};

const Btn = styled.button`
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    padding: ${(props) =>
        props.icon
            ? BUTTON_STYLES_BY_SIZE[props.size].padding.icon
            : BUTTON_STYLES_BY_SIZE[props.size].padding.noIcon};
    height: ${(props) => BUTTON_STYLES_BY_SIZE[props.size].height};
    font-size: ${(props) => BUTTON_STYLES_BY_SIZE[props.size].fontSize};
    border: ${(props) => BUTTON_STYLES_BY_SIZE[props.size].border[props.type]};
    background-color: ${(props) =>
        props.type === "outline"
            ? "#fff"
            : BUTTON_STYLES_BY_SIZE[props.size].fillBgColor};
    border-radius: 50px;
    color: ${(props) =>
        BUTTON_STYLES_BY_SIZE[props.size].textColor[props.type]};
    font-weight: ${(props) => (props.size === "s" ? "500" : "700")};

    span {
        position: relative;
        line-height: ${(props) => BUTTON_STYLES_BY_SIZE[props.size].lineHeight};
        letter-spacing: -0.01em;
        padding-left: ${(props) =>
            props.icon
                ? BUTTON_STYLES_BY_SIZE[props.size].icon.paddingLeft
                : 0};
        background-repeat: no-repeat;
        background-size: ${(props) =>
            BUTTON_STYLES_BY_SIZE[props.size].icon.width};
        background-image: ${(props) =>
            props.icon ? `url(${props.icon})` : "none"};
        background-position: left center;
    }

    &:hover {
        &:not(:disabled) {
            opacity: 0.8;
        }
    }

    &:disabled {
        background-color: ${(props) =>
            BUTTON_STYLES_BY_SIZE.disable.bgColor[props.type]};
        border-color: ${(props) =>
            BUTTON_STYLES_BY_SIZE.disable.bgColor[props.type]};
        color: ${(props) => BUTTON_STYLES_BY_SIZE.disable.textColor};

        span {
            background-image: ${(props) => `url(${props.icon})`};
        }
    }
`;

RoundButton.propTypes = {
    type: PropTypes.oneOf(["fill", "outline"]),
    size: PropTypes.oneOf(["l16", "l", "m", "s"]),
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
};

RoundButton.defaultProps = {
    type: "fill",
    size: "l",
    className: "",
    icon: null,
    isDisabled: false,
};

export default RoundButton;
