import React from "react";
import styled from "styled-components";
import { ModalButtonWrap, ModalBody } from "./Modal";

import { RoundButton } from "../common";

const FolderConfirmModal = () => {
    return (
        <ModalBody>
            <ModalText>
                폴더 내에 등록된 모든 배너 그룹과 이미지가 영구 삭제됩니다. 정말
                폴더를 삭제하시겠습니까?
            </ModalText>

            <ModalButtonWrap>
                <RoundButton>취소</RoundButton>
                <RoundButton>삭제</RoundButton>
            </ModalButtonWrap>
        </ModalBody>
    );
};

const ModalText = styled.p`
    padding-top: 20px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #282b39;
`;

export default FolderConfirmModal;
