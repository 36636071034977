import React, { useEffect, useState, useRef } from "react";

import { InfoLabel } from "../../components";
import { RoundButton, ToggleSwitch, Input } from "../../components/common";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../redux/ModalSlice";
import { openConfirm } from "../../redux/ConfirmSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    MODAL_PAYLOAD,
    CONFIRM_TEXT,
    BANNER_SORT_DISPLAY,
} from "../../constants";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import bannerGroupManager from "../../apis/bannerGroupManager";

import IcPlus from "../../assets/images/icons/ic-plus-fill.png";
import "./_folder-detail.scss";
import { showToast } from "../../redux/ToastSlice";
import { closeConfirm } from "../../redux/ConfirmSlice";
import { resetBannerGroupState } from "../../redux/BannerGroupSlice";
import { resetSelectedProducts } from "../../redux/ProductSlice";
import { emptyCategories } from "../../redux/CategorySlice";
import { setGlobalLoading } from "../../redux/GlobalLoadingSlice";
import styled from "styled-components";
import NoContents from "../main/NoContents";

const FolderDetail = React.memo(() => {
    const [filter, setFilter] = useState(null);
    const [isSearched, setIsSearched] = useState(false);
    const [groupName, setGroupName] = useState("");
    const urlParams = useParams();
    const exposureRef = useRef();
    const finishRef = useRef();
    const [checkItems, setCheckItems] = useState([]);
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    let getPaymentInfo = useSelector( (state)=>{ return state.paymentInfo } );
    const { data: bannerGroups, refetch } = useQuery({
        queryKey: ["getBannerGroups"],
        queryFn: () => {
            dispatch(setGlobalLoading(false));
            return bannerGroupManager.getBanners({
                filter,
                groupName,
                folderId: urlParams.folderId,
            });
        },
        enabled: false,
    });

    const removeBannerGroupMutation = useMutation(
        bannerGroupManager.removeBanner,
        {
            onSuccess: async () => {
                dispatch(showToast("배너그룹이 삭제되었습니다."));
                dispatch(closeConfirm());
                setCheckItems([]);
                refetch();
                dispatch(setGlobalLoading(false));
            },
            onError: () => {
                dispatch(setGlobalLoading(false));
            },
        }
    );

    const updateBannerStatusMutation = useMutation(
        bannerGroupManager.updateStatus,
        {
            onSuccess: () => {
                dispatch(showToast("배너그룹의 활성화 상태가 변경되었습니다."));
                refetch();
            },
            onError: () => {
                dispatch(setGlobalLoading(false));
            },
        }
    );
    useEffect(() => {
        refetch();
        dispatch(resetBannerGroupState());
        dispatch(resetSelectedProducts());
        dispatch(emptyCategories());
    }, []);

    useEffect(() => {
        refetch();
    }, [location]);

    const openBannerGroupTutorial = () => {
        dispatch(openModal({ ...MODAL_PAYLOAD.TUTORIAL.BANNER_GROUP }));
    };

    const addBannerGroup = () => {
        navigate(`/banner-group?FolderId=${urlParams.folderId}`);
    };

    const updateBannerGroup = (bannerGroup) => {
        navigate(
            `/banner-group/${bannerGroup.id}?FolderId=${urlParams.folderId}`
        );
    };

    const removeBannerGroup = (bannerGroupIds) => {
        const options = {
            confirmText: "삭제",
            text: CONFIRM_TEXT.BANNER_GROUP_REMOVE_CONFIRM_TEXT,
            confirmFunc: () => {
                dispatch(setGlobalLoading(true));
                removeBannerGroupMutation.mutate({ ids: bannerGroupIds });
            },
        };
        dispatch(openConfirm(options));
    };

    const onStatusChange = (e, bannerGroup) => {
        const payload = {
            bannerId: bannerGroup.id,
            param: {
                status: e.target.checked,
            },
        };
        dispatch(setGlobalLoading(true));
        updateBannerStatusMutation.mutate(payload);
    };

    const openCopyCodeModal = (bannerGroup) => {
        let copyCodeModalProps = JSON.parse(
            JSON.stringify(MODAL_PAYLOAD.BANNER_CODE)
        );
        copyCodeModalProps.modalProps.bannerGroup = bannerGroup;
        dispatch(openModal({ ...copyCodeModalProps }));
    };

    const toggleAllCheck = (e) => {
        const check = e.target.checked;
        if (check) {
            const ids = bannerGroups.map((bannerGroup) => bannerGroup.id);
            setCheckItems(ids);
        } else {
            setCheckItems([]);
        }
    };

    const removeCheckedItems = () => {
        removeBannerGroup(checkItems);
    };

    const onCheckBannerGroups = (e, id) => {
        if (e.target.checked) {
            setCheckItems((prevCheckItems) => [...prevCheckItems, id]);
        } else {
            setCheckItems((prevCheckItems) =>
                prevCheckItems.filter((item) => item !== id)
            );
        }
    };

    const checkFilter = (e) => {
        const exposureCheck = exposureRef.current.checked;
        const finishedCheck = finishRef.current.checked;
        let checkFilter =
            exposureCheck === finishedCheck ? null : exposureCheck ? 1 : 0;
        setFilter(checkFilter);
        setTimeout(() => {
            refetch();
        });
    };

    const onChangeGroupName = (e) => {
        if (!e.target.value.length) {
            setIsSearched(false);
            setTimeout(() => {
                refetch();
            });
        }
        setGroupName(e.target.value);
    };

    const searchBannerGroup = () => {
        setIsSearched(true);
        refetch();
    };

    return (

            <div className="folder_detail">
                <div className="title_wrap">
                    <div className="title_area">
                        <h2>배너 그룹 관리</h2>
                        <InfoLabel
                            onClick={openBannerGroupTutorial}
                            text={"배너 그룹 사용법"}
                        />
                    </div>
                </div>
                <div className="bnr_filter">
                    <div className="filter_wrap">
                        <h3>배너 그룹 필터</h3>
                        <div className="checks_wrap">
                            <div className="com_chk bnr_exposure">
                                <input
                                    type="checkbox"
                                    id="filter_type1"
                                    className={"table-checkbox"}
                                    ref={exposureRef}
                                    onChange={checkFilter}
                                />
                                <label className={"g-10"} htmlFor="filter_type1">
                                    노출 중인 배너
                                </label>
                            </div>
                            <div className="com_chk bnr_close">
                                <input
                                    type="checkbox"
                                    id="filter_type2"
                                    className={"table-checkbox"}
                                    ref={finishRef}
                                    onChange={checkFilter}
                                />
                                <label className={"g-10"} htmlFor="filter_type2">
                                    종료된 배너
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="filter_search">
                        <Input
                            onChange={onChangeGroupName}
                            size={"m"}
                            value={groupName}
                            placeHolder={"그룹명으로 검색해 주세요."}
                        />
                        <button className="btn_search" onClick={searchBannerGroup}>
                            검색
                        </button>
                    </div>
                </div>
                <div className="folder_bnr_regist">
                    <div className="regist_btn">
                        <button
                            type="button"
                            className="select_remove"
                            disabled={checkItems.length < 1}
                            onClick={removeCheckedItems}
                        >
                            선택 삭제
                        </button>
                        <RoundButton
                            size={"m"}
                            type={"fill"}
                            icon={IcPlus}
                            onClick={addBannerGroup}
                        >
                            배너 그룹 추가
                        </RoundButton>
                    </div>
                    <div className="regist_table">
                        <table role="table">
                            <thead>
                                <tr role="row">
                                    <th
                                        colSpan="1"
                                        role="columnheader"
                                        style={{ width: "60px" }}
                                    >
                                        <div className="chkAll_box">
                                            <input
                                                type="checkbox"
                                                id="chkAll"
                                                className={"table-checkbox"}
                                                checked={
                                                    checkItems.length > 0 &&
                                                    bannerGroups?.length ===
                                                        checkItems.length
                                                }
                                                onChange={toggleAllCheck}
                                            />
                                            <label htmlFor="chkAll"></label>
                                        </div>
                                    </th>
                                    <th
                                        colSpan="1"
                                        role="columnheader"
                                        style={{ width: "20px" }}
                                    >
                                        No
                                    </th>
                                    <th
                                        colSpan="1"
                                        role="columnheader"
                                        style={{ width: "auto", minWidth: "120px" }}
                                    >
                                        그룹명
                                    </th>
                                    <th
                                        colSpan="1"
                                        role="columnheader"
                                        style={{ width: "150px" }}
                                    >
                                        노출 상태
                                    </th>
                                    <th
                                        colSpan="1"
                                        role="columnheader"
                                        style={{ width: "120px" }}
                                    >
                                        이미지 수
                                    </th>
                                    <th
                                        colSpan="1"
                                        role="columnheader"
                                        style={{ width: "120px" }}
                                    >
                                        배너 효과
                                    </th>
                                    <th
                                        colSpan="1"
                                        role="columnheader"
                                        style={{ width: "110px" }}
                                    >
                                        클릭 수
                                    </th>
                                    <th
                                        colSpan="1"
                                        role="columnheader"
                                        style={{ width: "230px" }}
                                    >
                                        배너 코드
                                    </th>
                                    <th
                                        colSpan="1"
                                        role="columnheader"
                                        style={{ width: "180px" }}
                                    >
                                        관리
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {bannerGroups?.length ? (
                                    <>
                                        {bannerGroups?.map((bannerGroup, index) => (
                                            <tr key={bannerGroup.id}>
                                                <td
                                                    role="cell"
                                                    style={{
                                                        width: "60px",
                                                    }}
                                                >
                                                    <div className="body_chk">
                                                        <input
                                                            type="checkbox"
                                                            id={`checkItem-${bannerGroup.id}`}
                                                            className={
                                                                "table-checkbox"
                                                            }
                                                            checked={
                                                                checkItems.indexOf(
                                                                    bannerGroup.id
                                                                ) > -1
                                                            }
                                                            onChange={(e) =>
                                                                onCheckBannerGroups(
                                                                    e,
                                                                    bannerGroup.id
                                                                )
                                                            }
                                                        />
                                                        <label
                                                            htmlFor={`checkItem-${bannerGroup.id}`}
                                                        ></label>
                                                    </div>
                                                </td>
                                                <td
                                                    role="cell"
                                                    style={{
                                                        width: "20px",
                                                    }}
                                                >
                                                    <span className="num">
                                                        {index + 1}
                                                    </span>
                                                </td>
                                                <td
                                                    role="cell"
                                                    style={{
                                                        width: "auto",
                                                    }}
                                                >
                                                    <span className="group_name">
                                                        {bannerGroup.group_name}
                                                    </span>
                                                </td>
                                                <td
                                                    role="cell"
                                                    style={{
                                                        width: "150px",
                                                    }}
                                                >
                                                    <ToggleSwitch
                                                        id={`toggleSwitch-${bannerGroup.id}`}
                                                        onChange={(e) => {
                                                            onStatusChange(
                                                                e,
                                                                bannerGroup
                                                            );
                                                        }}
                                                        value={bannerGroup.status}
                                                    />
                                                </td>
                                                <td
                                                    role="cell"
                                                    style={{
                                                        width: "120px",
                                                    }}
                                                >
                                                    <span className="img_cnt">
                                                        {bannerGroup.image_amount}
                                                    </span>
                                                </td>
                                                <td
                                                    role="cell"
                                                    style={{
                                                        width: "120px",
                                                    }}
                                                >
                                                    <span className="effect">
                                                        {
                                                            BANNER_SORT_DISPLAY[
                                                                bannerGroup
                                                                    .banner_sort
                                                            ]
                                                        }
                                                    </span>
                                                </td>
                                                <td
                                                    role="cell"
                                                    style={{
                                                        width: "110px",
                                                    }}
                                                >
                                                    <span className="click_cnt">
                                                        {bannerGroup.clicked_amount}
                                                    </span>
                                                </td>
                                                <td
                                                    role="cell"
                                                    style={{
                                                        width: "230px",
                                                    }}
                                                >
                                                    <button
                                                        type="button"
                                                        className="iv_btn clone_btn"
                                                        onClick={(e) =>
                                                            openCopyCodeModal(
                                                                bannerGroup
                                                            )
                                                        }
                                                    >
                                                        코드복사
                                                    </button>
                                                </td>
                                                <td
                                                    role="cell"
                                                    style={{
                                                        width: "180px",
                                                    }}
                                                >
                                                    <div className="edit_remove">
                                                        <button
                                                            type="button"
                                                            className="iv_btn edit_btn"
                                                            onClick={(e) =>
                                                                updateBannerGroup(
                                                                    bannerGroup
                                                                )
                                                            }
                                                        >
                                                            수정
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="iv_btn remove_btn"
                                                            onClick={() =>
                                                                removeBannerGroup([
                                                                    bannerGroup.id,
                                                                ])
                                                            }
                                                        >
                                                            삭제
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                ) : (
                                    <tr>
                                        <td colSpan="9">
                                            {groupName.length && isSearched ? (
                                                <p className="no_data">
                                                    검색 결과가 없습니다.
                                                    <br />
                                                    다른 키워드로 검색해 주세요.
                                                </p>
                                            ) : (
                                                <p className="no_data">
                                                    [배너 그룹 추가] 버튼을 눌러
                                                    <br />
                                                    배너 그룹을 등록해 주세요.
                                                </p>
                                            )}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

    );
});
export default FolderDetail;
