import React, { useState } from "react";
import styled from "styled-components";

import IcHomeLogo from "../assets/images/home_logo@2x.png";
import MaintenanceImg from "../assets/images/maintenance@2x.png";

const Maintenance = () => {
    return (
        <MaintenanceContainer>
            <ImgLogo src={IcHomeLogo} />
            <MaintenanceContentWrap>
                <img src={MaintenanceImg} alt="" />
                <h1>서비스 점검 중입니다.</h1>
                <div className="time-wrap">
                    <span className="title">점검시간</span>
                    <span className="time">
                        2023. 07. 03(월) 저녁 8시 ~ 12시 (4시간)
                    </span>
                </div>
                <p className="description">
                    {`더 나은 서비스를 위해 서비스 점검 및 업데이트 중입니다.\n점검 시간 동안은 이너뷰 배너관리앱 접속이 불가합니다.\n이용에 불편을 드려 죄송합니다.`}
                </p>
            </MaintenanceContentWrap>
        </MaintenanceContainer>
    );
};

const MaintenanceContentWrap = styled.div`
    max-width: 622px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
        display: block;
        margin: 0 auto;
        width: 160px;
    }
    h1 {
        margin-top: 48px;
        font-size: 72px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        letter-spacing: -2.88px;
        color: #fff;
    }
    div.time-wrap {
        margin-top: 48px;
        display: flex;
        align-items: center;
        gap: 20px;

        span {
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: -0.96px;
        }

        span.title {
            color: var(--primary-400);
        }
        span.time {
            color: #fff;
        }
    }

    p.description {
        white-space: break-spaces;
        margin-top: 48px;
        color: var(--gray-500);
        text-align: center;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: -0.42px;
    }
`;

const MaintenanceContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: var(--gray-black);
    min-width: 1280px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ImgLogo = styled.img`
    position: fixed;
    top: 60px;
    left: 60px;
    display: block;
    width: 72px;
    pointer-events: none;
`;

export default Maintenance;
