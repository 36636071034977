import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/home_logo@2x.png";
import { SquareButton } from "../common";

import { useDispatch } from "react-redux";
import { openModal } from "../../redux/ModalSlice";
import { FAQ_URL, MODAL_PAYLOAD } from "../../constants";
import { useQueryClient } from "@tanstack/react-query";

import IcDownload from "../../assets/images/icons/ic-download@2x.png";
import IcScript from "../../assets/images/icons/ic-script-fill.png";
import IcQuestion from "../../assets/images/icons/ic-question@2x.png";

import downloadManager from "../../apis/downloadManager";

const Header = () => {
    const dispatch = useDispatch();
    const useQuery = useQueryClient();
    const installScript = async () => {
        const result = await useQuery.getQueryData(["paymentInfo"]);
        const props = JSON.parse(JSON.stringify(MODAL_PAYLOAD.SCRIPT_INSTALL));
        props.modalProps.script_src = result.script_src;
        dispatch(openModal({ ...props }));
    };

    const downloadManual = async () => {
        const result = await downloadManager.downloadManual();
    };

    const openFAQ = () => {
        window.open(FAQ_URL, "_blank");
    };

    return (
        <HeaderContainer>
            <Link to={"/"}>
                <img id={"logo"} src={Logo} alt="logo" />
            </Link>

            <HeaderBtnWrap>
                <SquareButton onClick={installScript} icon={IcScript}>
                    스크립트 설치
                </SquareButton>
                <SquareButton onClick={downloadManual} icon={IcDownload}>
                    매뉴얼 다운로드
                </SquareButton>
                <SquareButton onClick={openFAQ} icon={IcQuestion}>
                    사용 FAQ
                </SquareButton>
            </HeaderBtnWrap>
        </HeaderContainer>
    );
};

const HeaderContainer = styled.section`
    min-width: 660px;
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    background-color: var(--primary-1000);
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 32px;

    img#logo {
        width: 46px;
    }
`;

const HeaderBtnWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 0 8px;
`;

export default Header;
