import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
};

const globalLoadingSlice = createSlice({
    name: "globalLoading",
    initialState: initialState,
    reducers: {
        setGlobalLoading: (state, action) => {
            state.isLoading = action.payload;
        },
    },
});

export const { setGlobalLoading } = globalLoadingSlice.actions;
export default globalLoadingSlice.reducer;
