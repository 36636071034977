import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import PlusIcon from "../../../assets/images/icons/ic_plus.svg";

const Index = ({ onClick, isDisabled }) => {
    return (
        <Button onClick={onClick} disabled={isDisabled}>
            <img src={PlusIcon} alt="button icon" />
        </Button>
    );
};

const Button = styled.button`
    transition: all 0.25s ease-in-out;
    cursor: pointer;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: var(--primary-400);
    border-radius: 50%;
    &:hover {
        &:not(:disabled) {
            background-color: var(--primary-500);
        }
    }

    &:disabled {
        background-color: var(--gray-100);
    }
`;

Index.propTypes = {
    onClick: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
};

Index.defaultProps = {
    isDisabled: false,
};
export default Index;
