import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import ProfileImg from "../../assets/images/profile@2x.png";
import IcFolder from "../../assets/images/icons/ic-folder@2x.png";
import IcPlus from "../../assets/images/icons/ic-plus-fill.png";
import IcArrow from "../../assets/images/icons/ic-right-arrow.svg";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../redux/ModalSlice";
import { openConfirm, closeConfirm } from "../../redux/ConfirmSlice";
import { showToast } from "../../redux/ToastSlice";
import { MODAL_PAYLOAD, CONFIRM_TEXT, EXPIRE_TRAIL_DUE_DATE } from "../../constants";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { setGlobalLoading } from "../../redux/GlobalLoadingSlice";

import { Label, RoundButton, SquareButton, Loading } from "../common";
import folderManager from "../../apis/folderManager";
import paymentManager from "../../apis/paymentManager";
import { resetFolderName } from "../../redux/FolderSlice";
import moment from "moment";

const NoFolderText = `[폴더 추가]\n버튼을 눌러 폴더를 추가해 주세요.`;

const SideNav = () => {
    const currentDate = moment();
    const [folderLoading, setFolderLoading] = useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const urlParams = useParams();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    let getPaymentInfo = useSelector( (state)=>{ return state.paymentInfo } );
    const openPayment = async () => {
        const result = await paymentManager.createPayment({
            redirect_url: window.location.href,
        });
        window.open(result, "_blank");
    };
    const { data: folders, refetch } = useQuery({
        queryKey: ["getFolders"],
        queryFn: folderManager.getFolders,
    });

    useEffect(() => {
        if (getPaymentInfo.mall_id && getPaymentInfo.shop_no !== 1) {
            dispatch(
                openConfirm({
                    confirmText: "확인",
                    closeDisabled: true,
                    confirmFunc: () => {
                        window.opener = null;
                        window.open("", "_self");
                        window.close();
                    },
                    hasImage: true,
                    isSingleButton: true,
                    title: `멀티쇼핑몰 미지원 안내`,
                    text: `현재는 멀티쇼핑몰은 지원하지 않아요.\n대표 쇼핑몰에서만 사용해 주세요.\n멀티쇼핑몰 기능은 업데이트 예정입니다.`,
                })
            );
        }
    }, [getPaymentInfo]);

    const updateFolderOrderMutation = useMutation(
        folderManager.updateFolderOrder,
        {
            onSuccess: async () => {
                dispatch(showToast("폴더 순서가 변경되었습니다."));
                setFolderLoading(false);
                refetch();
            },
        }
    );

    const removeFolderMutation = useMutation(folderManager.removeFolder, {
        onSuccess: async () => {
            dispatch(showToast("폴더가 삭제되었습니다."));
            dispatch(closeConfirm());
            refetch();
            await queryClient.prefetchQuery(["getFolders"]);
            const data = queryClient.getQueryData(["getFolders"]);
            navigate(`/folder/${data[0].id}`);
            dispatch(setGlobalLoading(false));
        },
        onError: () => {
            dispatch(setGlobalLoading(false));
        },
    });
    const createFolder = () => {

        if (getPaymentInfo.version === 'Expired') {
            return;
        }

        dispatch(
            openModal({
                ...MODAL_PAYLOAD.ADD_FOLDER,
                closeFunc: (closeCallback, folderName) => {
                    if (folderName.length > 0) {
                        dispatch(
                            openConfirm({
                                confirmText: "확인",
                                text: "작성중인 내용이 있습니다.\n페이지를 벗어나시겠습니까?",
                                confirmFunc: () => {
                                    dispatch(closeConfirm());
                                    dispatch(resetFolderName());
                                    closeCallback();
                                },
                            })
                        );
                    } else {
                        dispatch(resetFolderName());
                        closeCallback();
                    }
                },
            })
        );
    };

    const restoreBanner = () => {

        if (getPaymentInfo.version === 'Expired') {
            return;
        }

        dispatch(openModal({ ...MODAL_PAYLOAD.BANNER_RESTORE }));
    };

    const modifyFolder = (e, folder) => {

        if (getPaymentInfo.version === 'Expired') {
            return;
        }

        e.stopPropagation();
        const prevFolderName = folder.title;
        const updateFolderNameProps = JSON.parse(
            JSON.stringify(MODAL_PAYLOAD.UPDATE_FOLDER_NAME)
        );
        updateFolderNameProps.modalProps.folder = folder;
        dispatch(
            openModal({
                ...updateFolderNameProps,
                closeFunc: (closeCallback, folderName) => {
                    if (prevFolderName !== folderName) {
                        dispatch(
                            openConfirm({
                                confirmText: "확인",
                                text: "작성중인 내용이 있습니다.\n페이지를 벗어나시겠습니까?",
                                confirmFunc: () => {
                                    dispatch(closeConfirm());
                                    dispatch(resetFolderName());
                                    closeCallback();
                                },
                            })
                        );
                    } else {
                        dispatch(resetFolderName());
                        closeCallback();
                    }
                },
            })
        );
    };

    const openRemoveConfirm = (e, folder) => {

        if (getPaymentInfo.version === 'Expired') {
            return;
        }

        e.stopPropagation();
        const options = {
            confirmText: "삭제",
            text: CONFIRM_TEXT.FOLDER_REMOVE_CONFIRM_TEXT,
            confirmFunc: () => {
                dispatch(setGlobalLoading(true));
                removeFolderMutation.mutate(folder.id);
            },
        };
        dispatch(openConfirm(options));
    };

    const backupBanner = async (e, folder) => {

        if (getPaymentInfo.version === 'Expired') {
            return;
        }

        e.stopPropagation();
        const result = await folderManager.getBackupFolder(folder.id);
        const jsonStr = `data:text/json;charset=utf-8,${encodeURIComponent(
            JSON.stringify(result)
        )}`;
        const link = document.createElement("a");
        link.href = jsonStr;
        link.download = "data.json";

        link.click();
    };

    const clickFolder = (folder) => {

        if (getPaymentInfo.version === 'Expired') {
            return;
        }

        navigate(`/folder/${folder.id}`);
    };
    const openUserPlanInfoModal = () => {
        const userName = getPaymentInfo.mall_id;
        const props = JSON.parse(JSON.stringify(MODAL_PAYLOAD.USER_PLAN_INFO));
        props.modalProps.title = `${userName}님의 결제 정보 안내`;
        dispatch(openModal({ ...props }));
    };

    const dragItem = (result) => {

        if (getPaymentInfo.version === 'Expired') {
            return;
        }

        if (!result.destination) {
            return;
        }
        const fromIndex = result.source.index;
        const toIndex = result.destination.index;
        if (fromIndex === toIndex) {
            return null;
        }
        setFolderLoading(true);
        let folderCopy = folders;
        const itemToMove = folderCopy[fromIndex];
        folderCopy.splice(fromIndex, 1);
        folderCopy.splice(toIndex, 0, itemToMove);
        const orderedFolders = folderCopy.map((item, index) => {
            return { id: item.id, order: index };
        });
        updateFolderOrderMutation.mutate({ folders: orderedFolders });
    };



    return (
        <SideNavContainer>
            {getPaymentInfo.mall_id ? (
                <AccountInfoWrap>
                    <AccountInfo>
                        <img src={ProfileImg} alt="profile img" />
                        <span>{getPaymentInfo.mall_id}</span>
                    </AccountInfo>

                    <TrialWrap>
                        <Label>{`${getPaymentInfo.version === 'Expired'? 'Expired' : getPaymentInfo.version + ' ver.'}`} </Label>
                        <PlanWrap>
                            <span
                                id="leftTimeText"
                                className={
                                    getPaymentInfo.version === "Free" ? "hide" : ""
                                }
                            >
                                {getPaymentInfo.version === "Premium"
                                    ? "다음 결제"
                                    : "버전 만료"}
                                :{" "}
                                {getPaymentInfo.d_day < 0 ? (
                                    <span id={"leftTime"}>만료됨</span>
                                ) : (
                                    <span id="leftTime">
                                        D-{getPaymentInfo.d_day}
                                    </span>
                                )}
                            </span>

                            <span
                                id="requestPlan"
                                onClick={openUserPlanInfoModal}
                            >
                                {getPaymentInfo.version === "Premium"
                                    ? "결제 정보 확인 >"
                                    : "정기 결제 신청 >"}
                            </span>
                        </PlanWrap>
                    </TrialWrap>
                </AccountInfoWrap>
            ) : null}

            <FolderContainer>
                <PaddingWrap>
                    <h2>폴더 관리</h2>
                    <FolderBtnWrap>
                        <FolderAddBtn
                            type={"fill"}
                            icon={IcPlus}
                            onClick={createFolder}
                        >
                            폴더 추가
                        </FolderAddBtn>
                        <RestoreBannerBtn
                            type={"outline"}
                            onClick={restoreBanner}
                        >
                            배너 복원
                        </RestoreBannerBtn>
                    </FolderBtnWrap>
                </PaddingWrap>
                <DragDropContext onDragEnd={dragItem}>
                    <FolderWrap>
                        <Loading isLoading={folderLoading} />
                        {folders?.length ? (
                            <Droppable droppableId="folderDroppable">
                                {(provided) => (
                                    <FolderList
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {folders.map((folder, index) => (
                                            <Draggable
                                                key={folder.id}
                                                draggableId={folder.id.toString()}
                                                index={index}
                                            >
                                                {(provided) => (
                                                    <FolderListItem
                                                        className={
                                                            (urlParams?.folderId === folder.id.toString() || searchParams.get("FolderId") === folder.id.toString()) ?
                                                                "active" : ""
                                                        }
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        key={folder.id}
                                                        onClick={() => {
                                                            clickFolder(folder);
                                                        }}
                                                    >
                                                        <FolderName>
                                                            {folder.title}
                                                        </FolderName>
                                                        <FolderManageBtnWrap
                                                            className={
                                                                "folder-manage-btn-wrap"
                                                            }
                                                        >
                                                            <SquareButton
                                                                size={"xs"}
                                                                onClick={(e) => {
                                                                    modifyFolder(
                                                                        e,
                                                                        folder
                                                                    );
                                                                }}
                                                            >
                                                                수정
                                                            </SquareButton>
                                                            <SquareButton
                                                                size={"xs"}
                                                                type={
                                                                    "fillGray"
                                                                }
                                                                onClick={(e) =>
                                                                    openRemoveConfirm(
                                                                        e,
                                                                        folder
                                                                    )
                                                                }
                                                            >
                                                                삭제
                                                            </SquareButton>
                                                            <SquareButton
                                                                size={"xs"}
                                                                type={"outline"}
                                                                onClick={(e) =>
                                                                    backupBanner(
                                                                        e,
                                                                        folder
                                                                    )
                                                                }
                                                            >
                                                                배너 백업
                                                            </SquareButton>
                                                        </FolderManageBtnWrap>
                                                    </FolderListItem>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </FolderList>
                                )}
                            </Droppable>
                        ) : (
                            <NoFolderWrap>
                                <p>{NoFolderText}</p>
                            </NoFolderWrap>
                        )}
                    </FolderWrap>
                </DragDropContext>
            </FolderContainer>
        </SideNavContainer>
    );
};

const FolderWrap = styled.div`
    position: relative;
    padding: 20px;
    height: 100%;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 12px;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--gray-400);
        border-radius: 100px;
        border-right: 4px solid var(--primary-1000);
        border-left: 4px solid var(--primary-1000);
        border-top: 2px solid var(--primary-1000);
        border-bottom: 2px solid var(--primary-1000);
    }
    &::-webkit-scrollbar-track {
        width: 12px;
        background-color: transparent;
    }
`;

const FolderList = styled.ul`
    padding-bottom: 100px;
`;
const FolderListItem = styled.li`
    cursor: pointer;
    position: relative;
    padding: 16px 20px;
    background-color: #32394e;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    margin-bottom: 8px;
    &:last-of-type {
        margin-bottom: 0;
    }
    background-image: url(${IcArrow});
    background-position: top 17px right 12px;
    background-repeat: no-repeat;

    &.active {
        cursor: default;
        .folder-manage-btn-wrap {
            display: flex;
        }
    }
`;

const FolderManageBtnWrap = styled.div`
    display: none;
    margin-top: 16px;
    gap: 4px;
`;

const FolderName = styled.span`
    display: block;
    color: #fff;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
`;

const NoFolderWrap = styled.div`
    padding: 12px 0;
    p {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: var(--gray-500);
        white-space: pre-wrap;
    }
`;

const FolderAddBtn = styled(RoundButton)`
    padding: 0 6px 0 0;
    width: 136px;
`;

const RestoreBannerBtn = styled(RoundButton)`
    width: 136px;
    padding: 0;
`;

const FolderBtnWrap = styled.div`
    width: 100%;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    gap: 8px;
`;

const PaddingWrap = styled.div`
    padding: 0 20px;
`;

const FolderContainer = styled.div`
    padding: 24px 0;
    height: calc(100% - 214px);

    h2 {
        padding-left: 28px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
        color: var(--primary-100);
        background-image: url(${IcFolder});
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: left center;
    }
`;

const SideNavContainer = styled.section`
    position: fixed;
    z-index: 19;
    padding-top: 80px;
    top: 0;
    left: 0;
    background-color: var(--primary-1000);
    width: 320px;
    height: 100%;
`;

const AccountInfoWrap = styled.div`
    padding: 24px 20px;
    background-color: #252b3b;
    width: 100%;
`;

const TrialWrap = styled.div`
    margin-top: 16px;
    padding: 12px;
    width: 100%;
    background: rgba(50, 57, 78, 0.9);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
`;

const PlanWrap = styled.div`
    margin-top: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span#leftTimeText {
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: var(--gray-400);
        #leftTime {
            color: #9da9ee;
            font-weight: 700;
        }
        &.hide {
            opacity: 0;
            user-select: none;
            pointer-events: none;
        }
    }

    span#requestPlan {
        cursor: pointer;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.01em;
        color: var(--gray-500);
        border-bottom: 1px solid var(--gray-500);
    }
`;

const AccountInfo = styled.div`
    img {
        display: block;
        width: 56px;
        margin: 0 auto;
    }
    span {
        display: block;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: var(--gray-200);
    }
`;

export default SideNav;
