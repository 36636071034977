import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const BUTTON_STYLES_BY_SIZE = {
    disable: {
        bgColor: {
            fill: "var(--gray-100)",
            outline: "var(--gray-50)",
        },
        textColor: "var(--gray-300)",
        borderColor: "var(--gray-100)",
    },
    m: {
        lineHeight: "22px",
        padding: "0 20px",
        textColor: {
            fill: "#fff",
            fillGray: "#fff",
            outline: "var(--primary-500)",
        },
        fillBgColor: {
            outline: "#fff",
            fill: "var(--primary-500)",
            fillGray: "var(--gray-600)",
        },
        height: "40px",
        fontSize: "14px",
        border: {
            fill: "1px solid var(--primary-500)",
            fillGray: "1px solid var(--gray-600)",
            outline: "1px solid var(--primary-500)",
        },
    },
    s: {
        lineHeight: "20px",
        padding: "0 16px",
        textColor: {
            fill: "#fff",
            fillGray: "#fff",
            outline: "var(--primary-500)",
        },
        fillBgColor: {
            outline: "#fff",
            fill: "var(--primary-500)",
            fillGray: "var(--gray-600)",
        },
        height: "32px",
        fontSize: "13px",
        border: {
            fill: "1px solid var(--primary-500)",
            fillGray: "1px solid var(--gray-600)",
            outline: "1px solid var(--primary-500)",
        },
    },
    xs: {
        lineHeight: "18px",
        padding: "0 10px",
        textColor: {
            fill: "#fff",
            fillGray: "#fff",
            outline: "var(--primary-500)",
        },
        fillBgColor: {
            outline: "#fff",
            fill: "var(--primary-500)",
            fillGray: "var(--gray-600)",
        },
        height: "28px",
        fontSize: "12px",
        border: {
            fill: "1px solid var(--primary-500)",
            fillGray: "1px solid var(--gray-600)",
            outline: "1px solid var(--primary-500)",
        },
    },
};

const SquareButton = ({
    type,
    onClick,
    size,
    icon,
    disableIcon,
    children,
    isDisabled,
}) => {
    return !icon ? (
        <SquareBtn
            type={type}
            size={size}
            onClick={onClick}
            disabled={isDisabled}
        >
            <span>{children}</span>
        </SquareBtn>
    ) : (
        <ScriptInstallBtn
            icon={isDisabled ? disableIcon : icon}
            type={type}
            onClick={onClick}
            disabled={isDisabled}
        >
            <span>{children}</span>
        </ScriptInstallBtn>
    );
};

const SquareBtn = styled.button`
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    padding: ${(props) => BUTTON_STYLES_BY_SIZE[props.size].padding};
    height: ${(props) => BUTTON_STYLES_BY_SIZE[props.size].height};
    font-size: ${(props) => BUTTON_STYLES_BY_SIZE[props.size].fontSize};
    border: ${(props) => BUTTON_STYLES_BY_SIZE[props.size].border[props.type]};
    background-color: ${(props) =>
        BUTTON_STYLES_BY_SIZE[props.size].fillBgColor[props.type]};
    border-radius: 4px;
    font-weight: 500;

    span {
        color: ${(props) =>
            BUTTON_STYLES_BY_SIZE[props.size].textColor[props.type]};
        position: relative;
        line-height: ${(props) => BUTTON_STYLES_BY_SIZE[props.size].lineHeight};
        letter-spacing: -0.01em;
    }

    &:hover {
        &:not(:disabled) {
            opacity: 0.8;
        }
    }

    &:disabled {
        background-color: ${(props) =>
            BUTTON_STYLES_BY_SIZE.disable.bgColor[props.type]};
        border-color: ${(props) =>
            BUTTON_STYLES_BY_SIZE.disable.bgColor[props.type]};
        color: ${(props) => BUTTON_STYLES_BY_SIZE.disable.textColor};

        span {
            background-image: ${(props) => `url(${props.icon})`};
        }
    }
`;

const ScriptInstallBtn = styled.button`
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    padding: 0 24px 0 20px;
    height: 40px;
    font-weight: 700;
    font-size: 14px;
    background-color: ${(props) =>
        props.type === "fill" ? "#44475c" : "#fff"};
    border-radius: 4px;
    border: 1px solid #44475c;

    span {
        position: relative;
        line-height: 22px;
        letter-spacing: -0.01em;
        padding-left: 26px;
        background-repeat: no-repeat;
        background-size: 16px;
        background-image: ${(props) => `url(${props.icon})`};
        background-position: left center;
        color: ${(props) => (props.type === "fill" ? "#fff" : "#44475C")};
    }

    &:hover {
        &:not(:disabled) {
            opacity: 0.8;
        }
    }

    &:disabled {
        background-color: var(--gray-100);
        border-color: var(--gray-100);
        color: var(--gray-300);
    }
`;

SquareButton.propTypes = {
    type: PropTypes.oneOf(["fill", "fillGray", "outline"]),
    size: PropTypes.oneOf(["m", "s", "xs"]),
    onClick: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
};

SquareButton.defaultProps = {
    type: "fill",
    size: "m",
    icon: null,
    isDisabled: false,
};

export default SquareButton;
