import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Radio = ({ id, value, name, isChecked, isDisabled, onChange, label }) => {
    return (
        <RadioContainer>
            <Label htmlFor={id} $isChecked={isChecked} $isDisabled={isDisabled}>
                <input
                    type="radio"
                    id={id}
                    checked={isChecked}
                    name={name}
                    value={value}
                    disabled={isDisabled}
                    onChange={onChange}
                />
            </Label>
            <LabelText htmlFor={id}>{label}</LabelText>
        </RadioContainer>
    );
};

const returnColorByProps = ({ $isChecked, $isDisabled }) => {
    if ($isChecked) {
        return $isDisabled ? "" : "";
    } else {
        return $isDisabled ? "" : "";
    }
};

const RadioContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const LabelText = styled.label`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #282b39;
`;

const Label = styled.label`
    transition: all 0.25s ease-in-out;
    position: relative;
    cursor: ${(props) => (props.$isDisabled ? "not-allowed" : "pointer")};
    width: 18px;
    height: 18px;
    border-radius: 100%;
    border: ${(props) =>
        `1px solid ${
            props.$isDisabled
                ? "var(--gray-200)"
                : props.$isChecked
                ? "var(--primary-500)"
                : "var(--gray-300)"
        }`};
    background-color: ${(props) =>
        !props.isDisabled ? "var(--white)" : "var(--gray-50)"};

    &::before {
        content: " ";
        transition: all 0.2s ease-in-out;
        width: 8px;
        height: 8px;
        display: block;
        position: absolute;
        border-radius: 100%;
        top: 50%;
        left: 50%;
        transform: ${(props) =>
            `translate(-50%, -50%) scale(${props.$isChecked ? 1 : 0})`};
        background-color: ${(props) =>
            props.$isDisabled ? "var(--gray-200)" : "var(--primary-500)"};
    }

    input[type="radio"] {
        position: absolute;
        width: 1px;
        height: 1px;
        top: -1px;
        left: -1px;
        opacity: 0;
        visibility: hidden;
    }
`;

Radio.propTypes = {
    isChecked: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};

Radio.defaultProps = {
    isDisabled: false,
    isChecked: false,
};

export default Radio;
