import { instance } from "./index";

const getBanners = (params) => {
    const { folderId } = params;
    const payload = {};
    if (params.filter !== null) payload.filter = params.filter;
    if (params.groupName) {
        payload.group_name = params.groupName;
    }
    return instance.get(`/banner/folder/${folderId}`, {
        params: payload,
    });
};

const getBannerById = (bannerId) => {
    return instance.get(`/banner/${bannerId}`);
};

const createBanner = (param) => {
    let payload = {
        FolderId: param.FolderId,
        group_name: param.group_name,
        group_describe: param.group_describe,
        exposure_order: param.exposure_order,
        banner_sort: param.banner_sort,
        is_repeat: param.is_repeat,
        is_speed: param.is_speed,
        products: param.products || "",
        categories: param.categories || "",
        use_swiper_config: param.use_swiper_config || false,
        status: false,
    };

    if (param.is_speed) {
        if (param.speed) {
            payload.speed = param.speed;
        } else {
            payload.speed = 3;
        }
    }
    if (param.banner_sort === "custom") {
        payload.banner_code = param.banner_code;
        if (param.use_swiper_config) {
            payload.swiper_config = param.swiper_config || "";
        }
    }
    if (payload.products.length) {
        payload.products = "," + payload.products + ",";
    }
    if (payload.categories.length) {
        payload.categories = "," + payload.categories + ",";
    }

    return instance.post(`/banner`, payload);
};

const updateBanner = (param) => {
    let payload = {
        FolderId: param.FolderId,
        group_name: param.group_name,
        group_describe: param.group_describe,
        exposure_order: param.exposure_order,
        banner_sort: param.banner_sort,
        is_repeat: param.is_repeat,
        is_speed: param.is_speed,
        products: param.products || "",
        categories: param.categories || "",
        // swiper_config: param.swiper_config || null,
        use_swiper_config: param.use_swiper_config,
    };

    if (param.is_speed) {
        if (param.speed) {
            payload.speed = param.speed;
        } else {
            payload.speed = 3;
        }
    }
    if (param.banner_sort === "custom") {
        payload.banner_code = param.banner_code;
        if (param.use_swiper_config) {
            payload.swiper_config = param.swiper_config || "";
        }
    }
    if (payload.products.length) {
        payload.products = "," + payload.products + ",";
    }
    if (payload.categories.length) {
        payload.categories = "," + payload.categories + ",";
    }

    return instance.patch(`/banner/${param.id}`, payload);
};

const updateStatus = (data) => {
    const { bannerId, param } = data;

    return instance.patch(`/banner/${bannerId}`, param);
};

const removeBanner = (ids) => {
    return instance.delete(`/banner`, {
        headers: {
            "Content-Type": "application/json",
        },
        data: ids,
    });
};

const bannerGroupManager = {
    getBanners,
    getBannerById,
    createBanner,
    updateBanner,
    removeBanner,
    updateStatus,
};

export default bannerGroupManager;
