import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
    useLocation,
    useNavigate,
    useSearchParams,
    unstable_useBlocker,
    unstable_usePrompt,
} from "react-router-dom";
import { InfoLabel } from "../../components";
import { RoundButton } from "../../components/common";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../../redux/ModalSlice";
import { MODAL_PAYLOAD } from "../../constants";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { showToast } from "../../redux/ToastSlice";
import { resetBannerGroupState } from "../../redux/BannerGroupSlice";
import { resetSelectedProducts } from "../../redux/ProductSlice";
import { updateBannerGroupState } from "../../redux/BannerGroupSlice";
import { setSelectedProducts } from "../../redux/ProductSlice";
import { setCheckCategories } from "../../redux/CategorySlice";

import IcTabCheckInactive from "../../assets/images/icons/ic_tab_check_inactive.svg";
import IcTabImageInactive from "../../assets/images/icons/ic_tab_image_inactive.svg";
import IcTabCheckActive from "../../assets/images/icons/ic_tab_check_active.svg";
import IcTabImageActive from "../../assets/images/icons/ic_tab_image_active.svg";

import BannerConfig from "./BannerConfig";
import ImageManage from "./ImageManage";
import bannerGroupManager from "../../apis/bannerGroupManager";
import productManager from "../../apis/productManager";
import categoryManager from "../../apis/categoryManager";
import "./_banner-group.scss";
import {
    setChips,
    setLinkedCategories,
    emptyCategories,
} from "../../redux/CategorySlice";
const BannerGroup = (props) => {
    const [isInitialized, setIsinitialized] = useState(false);
    const [isSomethingChanged, setIsSomethingChanged] = useState(-1);
    const urlParams = useParams();
    const [searchParams] = useSearchParams();
    const [currentTab, setCurrentTab] = useState("banner");
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const selectedProducts = useSelector(
        (state) => state.product.selectedProducts
    );
    const { checkedCategories, linkedCategories, spreadedCategories, chips } =
        useSelector((state) => state.category);
    const bannerGroupState = useSelector((state) => state.bannerGroup);

    const { isUpdate } = bannerGroupState;
    const createBannerGroupMutation = useMutation(
        bannerGroupManager.createBanner,
        {
            onSuccess: (data) => {
                dispatch(showToast("배너 그룹이 생성되었습니다."));
                dispatch(resetBannerGroupState());
                dispatch(resetSelectedProducts());
                // dispatch(resetCategories());
                setTimeout(() => {
                    navigate(
                        `/banner-group/${data.id}?FolderId=${searchParams.get(
                            "FolderId"
                        )}`,
                        {
                            replace: true,
                        }
                    );
                });
            },
        }
    );

    unstable_usePrompt({
        when:
            (isUpdate ? isSomethingChanged > 0 : isSomethingChanged > 0) &&
            currentTab === "banner",
        message:
            "작성중인 내용이 있습니다. 페이지를 벗어나시겠습니까?\n작성된 내용은 저장되지 않습니다.",
    });

    useEffect(() => {
        setTimeout(() => {
            setIsinitialized(true);
        }, 500);
    }, []);

    const updateBannerGroupMutation = useMutation(
        bannerGroupManager.updateBanner,
        {
            onSuccess: () => {
                dispatch(showToast("배너 그룹이 수정되었습니다."));
                dispatch(resetBannerGroupState());
                dispatch(resetSelectedProducts());
                dispatch(emptyCategories());
                setTimeout(() => {
                    navigate(`/folder/${searchParams.get("FolderId")}`);
                });
            },
        }
    );

    useEffect(() => {
        if (isInitialized) {
            setIsSomethingChanged(0);
        }
    }, [isInitialized]);

    useEffect(() => {
        if (isInitialized) {
            setIsSomethingChanged(isSomethingChanged + 1);
        }
    }, [selectedProducts, bannerGroupState, checkedCategories, isInitialized]);

    useEffect(() => {
        dispatch(updateBannerGroupState({ isUpdate: !!urlParams.bannerId }));
        if (urlParams.bannerId) {
            getBannerGroupInfo(urlParams.bannerId);
        }
    }, [location.pathname, urlParams.bannerId]);

    const openTutorialModal = (tutorialProps) => {
        dispatch(openModal({ ...tutorialProps }));
    };

    const getBannerGroupInfo = async (bannerId) => {
        const data = await bannerGroupManager.getBannerById(bannerId);
        let payload = {
            banner_sort: data.banner_sort,
            exposure_order: data.exposure_order,
            group_name: data.group_name,
            group_describe: data.group_describe,
            is_repeat: data.is_repeat,
            is_speed: data.is_speed,
            swiper_config: data.swiper_config,
            use_swiper_config: data.use_swiper_config,
        };
        if (data.speed) payload.speed = data.speed;
        if (data.banner_code) {
            payload.banner_code = data.banner_code;
        }
        dispatch(updateBannerGroupState(payload));
        if (data.products.length) getLinkProducts(data.id);
        if (data.categories.length) getLinkCategories(data.id);
    };

    useEffect(() => {
        if (
            Object.keys(checkedCategories).length &&
            Object.keys(spreadedCategories).length &&
            linkedCategories.length
        ) {
            if (!isInitialized) {
                linkCategoriesToCheckedCategories();
            }
        }
    }, [checkedCategories, spreadedCategories, linkedCategories]);

    const linkCategoriesToCheckedCategories = () => {
        const copyCheckedCategories = JSON.parse(
            JSON.stringify(checkedCategories)
        );
        linkedCategories.forEach((cate) => {
            copyCheckedCategories[cate.category_no] = true;
        });
        dispatch(setCheckCategories(copyCheckedCategories));
        // chipsGenerator(copyCheckedCategories);
    };

    const getLinkCategories = async (bannerId) => {
        const data = await queryClient.fetchQuery({
            queryKey: ["getLinkCategories", bannerId],
            queryFn: () => categoryManager.getLinkedCategories(bannerId),
        });
        dispatch(setLinkedCategories(data));
    };

    const getLinkProducts = async (bannerId) => {
        const data = await queryClient.fetchQuery({
            queryKey: ["getProducts", bannerId],
            queryFn: () => productManager.getLinkedProducts(bannerId),
        });
        dispatch(setSelectedProducts(data));
    };

    // const chipsGenerator = (checkedCategories) => {
    //     let checkedArr = [];
    //     let chips = [];
    //     for (let categoryNo in checkedCategories) {
    //         if (checkedCategories[categoryNo]) {
    //             checkedArr.push(spreadedCategories[categoryNo]);
    //         }
    //     }
    //     checkedArr.forEach((item) => {
    //         if (item.children.length === 0) {
    //             let parentCategoryNos = [];
    //             let parentCategoryNames = [];
    //             pushParent(item, parentCategoryNos, parentCategoryNames);
    //             chips.push({
    //                 self: item,
    //                 parentCategoryNos: parentCategoryNos.reverse(),
    //                 parentCategoryNames: parentCategoryNames.reverse(),
    //             });
    //         }
    //     });
    //     dispatch(setChips(chips));
    // };

    const pushParent = (item, parentCategoryNos, parentCategoryNames) => {
        if (item.parent_category_no === 1) return;
        const parentNode = spreadedCategories[item.parent_category_no];
        parentCategoryNos.push(parentNode.category_no);
        parentCategoryNames.push(parentNode.category_name);
        if (parentNode.parent_category_no !== 1) {
            pushParent(parentNode, parentCategoryNos, parentCategoryNames);
        }
    };

    const onSave = () => {
        if (!bannerGroupState.group_name.trim().length) {
            return dispatch(showToast("배너 그룹명을 입력해주세요."));
        }
        let products = "";
        if (selectedProducts.length > 0) {
            products = selectedProducts
                .map((product) => product.product_no)
                .join(",");
        }
        let categories = Object.entries(checkedCategories)
            .filter(([key, value]) => value === true)
            .map(([key]) => Number(key))
            .join(",");

        setIsSomethingChanged(-10);
        if (isUpdate) {
            updateBannerGroupMutation.mutate({
                ...bannerGroupState,
                id: urlParams.bannerId,
                products,
                categories: categories.length ? categories : null,
            });
        } else {
            createBannerGroupMutation.mutate({
                FolderId: searchParams.get("FolderId"),
                ...bannerGroupState,
                products,
                categories: categories.length ? categories : null,
            });
        }
    };

    const changeTab = (tab) => {
        if (tab === "image" && !isUpdate) return;
        setCurrentTab(tab);
    };

    return (
        <div className="banner_group">
            <div className="set_sction header_nav">
                <div className="title_wrap">
                    <div className="title_area">
                        <h2>배너 그룹 관리</h2>
                        {currentTab === "banner" ? (
                            <InfoLabel
                                onClick={(e) =>
                                    openTutorialModal(
                                        MODAL_PAYLOAD.TUTORIAL.BANNER_CONFIG
                                    )
                                }
                                text={"배너 설정 사용법"}
                            />
                        ) : (
                            <InfoLabel
                                onClick={(e) =>
                                    openTutorialModal(
                                        MODAL_PAYLOAD.TUTORIAL.IMAGE_MANAGE
                                    )
                                }
                                text={"이미지 관리 사용법"}
                                리
                            />
                        )}
                    </div>
                    {currentTab === "banner" ? (
                        <RoundButton
                            size={"l16"}
                            type={"fill"}
                            onClick={onSave}
                            isDisabled={
                                createBannerGroupMutation.isLoading ||
                                updateBannerGroupMutation.isLoading
                            }
                        >
                            저장
                        </RoundButton>
                    ) : null}
                </div>
                <div className="tab-wrap bnr_state state_nav iv_box">
                    <div
                        className={`com_manage bnr_manage ${
                            currentTab === "banner" ? "on" : ""
                        }`}
                        onClick={(e) => changeTab("banner")}
                    >
                        <img
                            src={
                                currentTab === "banner"
                                    ? IcTabCheckActive
                                    : IcTabCheckInactive
                            }
                            alt="icon"
                        />
                        <span>배너 설정 관리</span>
                    </div>
                    <div
                        className={`com_manage img_manage ${
                            currentTab === "image" ? "on" : ""
                        } ${!isUpdate ? "disabled" : ""}`}
                        onClick={(e) => changeTab("image")}
                    >
                        <img
                            src={
                                currentTab === "image"
                                    ? IcTabImageActive
                                    : IcTabImageInactive
                            }
                            alt="icon"
                        />
                        <span>이미지 관리</span>
                    </div>
                </div>
            </div>

            <ScrollContainer>
                {currentTab === "banner" ? (
                    <BannerConfig openTutorialModal={openTutorialModal} />
                ) : (
                    <ImageManage openTutorialModal={openTutorialModal} />
                )}
            </ScrollContainer>
        </div>
    );
};

const ScrollContainer = styled.div`
    overflow: auto;
    height: calc(100% - 178px);
    padding: 0 40px 40px;
    &::-webkit-scrollbar {
        width: 12px;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--gray-400);
        border-radius: 100px;
        border-right: 4px solid #fff;
        border-left: 4px solid #fff;
        border-top: 2px solid #fff;
        border-bottom: 2px solid #fff;
    }
    &::-webkit-scrollbar-track {
        width: 12px;
        background-color: transparent;
    }
`;

export default BannerGroup;
