import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Checkbox = ({
    isChecked,
    isDisabled,
    id,
    onChange,
    name,
    value,
    label,
}) => {
    return (
        <CheckboxContainer>
            <Label htmlFor={id} $isChecked={isChecked} $isDisabled={isDisabled}>
                <input
                    type={"checkbox"}
                    id={id}
                    checked={isChecked}
                    disabled={isDisabled}
                    onChange={onChange}
                    name={name}
                    value={value}
                />
            </Label>
            {label ? <LabelText htmlFor={id}>{label}</LabelText> : null}
        </CheckboxContainer>
    );
};

const returnBgColorByProps = ({ $isChecked, $isDisabled }) => {
    if ($isChecked) {
        return !$isDisabled ? "var(--primary-500)" : "var(--gray-200)";
    } else {
        return !$isDisabled ? "var(--white)" : "var(--gray-50)";
    }
};

const returnBorderColorByProps = ({ $isChecked, $isDisabled }) => {
    if ($isChecked) {
        return !$isDisabled ? "var(--primary-500)" : "var(--gray-200)";
    } else {
        return !$isDisabled ? "var(--gray-300)" : "var(--gray-200)";
    }
};

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;
const LabelText = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #282b39;
`;

const Label = styled.label`
    transition: all 0.2s ease-in-out;
    position: relative;
    cursor: ${(props) => (props.$isDisabled ? "not-allowed" : "pointer")};
    display: inline-block;
    width: 18px;
    height: 18px;
    overflow: hidden;
    border: ${(props) => `1px solid ${returnBorderColorByProps(props)}`};
    border-radius: 2px;
    background-color: ${(props) => returnBgColorByProps(props)};

    &::before {
        content: " ";
        z-index: 1;
        display: block;
        width: 2px;
        height: 6px;
        background-color: #fff;
        position: absolute;
        left: 4px;
        top: 11px;
        transform: translate(0, -50%) rotate(-45deg);
        border-radius: 10px;
    }
    &::after {
        content: " ";
        z-index: 1;
        display: block;
        width: 2px;
        background-color: #fff;
        position: absolute;
        height: 9px;
        left: 9px;
        top: 8px;
        transform: translate(0, -50%) rotate(40deg);
        border-radius: 10px;
    }

    input {
        position: absolute;
        width: 1px;
        height: 1px;
        top: -1px;
        left: -1px;
        opacity: 0;
        visibility: hidden;
    }
`;

Checkbox.propTypes = {
    isChecked: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func,
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    value: PropTypes.string,
};

Checkbox.defaultProps = {
    isChecked: false,
    isDisabled: false,
};

export default Checkbox;
