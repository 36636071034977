import React from "react";
import styled from "styled-components";
import { ModalButtonWrap, ModalBody } from "./Modal";

import { RoundButton } from "../common";

const WarningModal = () => {
    const onClickContact = () => {
        window.open("https://forms.gle/w55y3YoBb3cw2gSS7", "_blank");
    };

    return (
        <ModalBody>
            <ModalTextTitle>배너관리앱 업데이트 안내</ModalTextTitle>
            <ModalText>
                {`버그 수정을 위해 2023-10-26 업데이트를 진행했습니다.\n배너 오류 발생 시 아래 버튼을 눌러 문의해 주세요.`}
            </ModalText>
            <ModalButtonWrap>
                <RoundButton onClick={onClickContact}>문의하기</RoundButton>
            </ModalButtonWrap>
        </ModalBody>
    );
};

const ModalTextTitle = styled.h1`
    padding-top: 20px;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.01em;
    color: #000;
    text-align: center;
`;

const ModalText = styled.p`
    margin-top: 32px;
    margin-bottom: 8px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #282b39;
    white-space: pre-line;
`;

export default WarningModal;
