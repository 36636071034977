import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    folderName: "",
};

const folderSlice = createSlice({
    name: "folder",
    initialState,
    reducers: {
        setFolderName: (state, action) => {
            state.folderName = action.payload;
        },
        resetFolderName: (state) => {
            state.folderName = "";
        },
    },
});

export const { setFolderName, resetFolderName } = folderSlice.actions;
export default folderSlice.reducer;
