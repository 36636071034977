import axios from "axios";
import { store } from "../redux/store";
import { showToast } from "../redux/ToastSlice";
import { getCookies } from "../utils";

const BASE_URL = process.env.REACT_APP_API_URL;
const instance = axios.create({
    baseURL: BASE_URL,
    timeout: 10000,
});

instance.interceptors.request.use(
    (config) => {
        const authToken = getCookies("ivBanner_auth");
        config.headers.Authorization = `${authToken}`;
        return config;
    },
    (e) => {
        return Promise.reject(e);
    }
);

instance.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        if (
            error?.response?.data?.message !==
            `Cannot read properties of null (reading 'mall_id')`
        ) {
            store.dispatch(
                showToast(error.response?.data?.message || error.message)
            );
            return Promise.reject(error);
        }
    }
);

export { instance };
