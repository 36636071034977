import { instance } from "./index";

const getCategories = () => {
    return instance.get(`/category`);
};

const getLinkedCategories = (bannerId) => {
    return instance.get(`/category/registered/${bannerId}`);
};

const categoryManager = {
    getCategories,
    getLinkedCategories,
};

export default categoryManager;
