import { createSlice, current } from "@reduxjs/toolkit";

import { IMAGE_MODAL_TAB_NAMES } from "../constants";

const initialState = {
    BannerId: "",
    create_type: IMAGE_MODAL_TAB_NAMES.MANUAL,
    set_target: "_self",
    texts: ["", ""],
    url: "",
    file_url: "",
    imageName: "",
    video_url: "",
    imageExternalUrl: "",
    loopPlay: false,
    autoPlay: false,
    isInitialized: false,
};

const keysToCheck = [
    "set_target",
    "texts",
    "url",
    "file_url",
    "imageName",
    "video_url",
    "imageExternalUrl",
    "loopPlay",
    "autoPlay",
];

const imageModalSlice = createSlice({
    name: "imageModal",
    initialState: initialState,
    reducers: {
        updateImageModalState: (state, action) => {
            const hasAnyKey = keysToCheck.some((key) => key in action.payload);
            if (hasAnyKey && Object.keys(action.payload).length === 1) {
                if (action.payload)
                    Object.assign(state, {
                        ...action.payload,
                        isInitialized: true,
                    });
            } else {
                if (
                    "imageName" in action.payload &&
                    "file_url" in action.payload &&
                    Object.keys(action.payload).length === 2
                ) {
                    Object.assign(state, {
                        ...action.payload,
                        isInitialized: true,
                    });
                } else {
                    if (action.payload)
                        Object.assign(state, { ...action.payload });
                }
            }
        },
        resetImageModal: (state) => {
            Object.assign(state, { ...initialState });
        },
    },
});

export const { updateImageModalState, resetImageModal } =
    imageModalSlice.actions;
export default imageModalSlice.reducer;
