import React, { useState, useEffect } from "react";
import { ModalButtonWrap, ModalBody } from "../Modal";

import ImageManualUpload from "./ImageManualUpload";
import ImageURLUpload from "./ImageURLUpload";
import VideoUpload from "./VideoUpload";

import { RoundButton } from "../../common";
import { useSelector, useDispatch } from "react-redux";
import {
    resetImageModal,
    updateImageModalState,
} from "../../../redux/ImageModalSlice";
import { showToast } from "../../../redux/ToastSlice";
import { IMAGE_MODAL_TAB_NAMES } from "../../../constants";

import { useQueryClient, useMutation } from "@tanstack/react-query";
import imageManager from "../../../apis/imageManager";

import "../style/_image-manage-modal.scss";
import styled from "styled-components";
import { removeCloseFunc } from "../../../redux/ModalSlice";

const ImageManageModal = ({ handleCloseModal, imageData, bannerId }) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const [imgError, setImgError] = useState(false);
    const [blockSave, setBlockSave] = useState(false);
    const imageModalState = useSelector((state) => state.imageModal);
    const { create_type } = imageModalState;
    useEffect(() => {
        if (imageData) {
            initUpdateModal(imageData);
        }
        dispatch(updateImageModalState({ BannerId: bannerId }));
    }, []);

    const initUpdateModal = (imageData) => {
        let options = {
            autoPlay: imageData.autoPlay,
            loopPlay: imageData.loopPlay,
            texts: imageData.texts,
            create_type: imageData.create_type,
            is_video: imageData.is_video,
            set_target: imageData.set_target,
            url: imageData.url,
        };
        if (imageData.create_type === IMAGE_MODAL_TAB_NAMES.VIDEO) {
            options.video_url = imageData.file_url;
        } else if (imageData.create_type === IMAGE_MODAL_TAB_NAMES.MANUAL) {
            options.file_url = imageData.file_url;
        } else {
            options.imageExternalUrl = imageData.file_url;
        }
        dispatch(updateImageModalState(options));
    };

    const changeTabMenu = (name) => {
        dispatch(updateImageModalState({ create_type: name }));
    };
    const createImageMutation = useMutation(imageManager.createImage, {
        onSuccess: () => {
            dispatch(showToast("이미지가 생성되었습니다."));
            handleCloseModal();
            dispatch(resetImageModal());
            queryClient.invalidateQueries({
                queryKey: ["bannerImages", { bannerId: bannerId }],
            });
        },
    });

    const updateImageMutation = useMutation(imageManager.updateImage, {
        onSuccess: () => {
            dispatch(showToast("이미지가 수정되었습니다."));
            handleCloseModal();
            dispatch(resetImageModal());
            queryClient.invalidateQueries({
                queryKey: ["bannerImages", { bannerId: bannerId }],
            });
        },
    });

    const saveImage = () => {
        if (create_type === IMAGE_MODAL_TAB_NAMES.VIDEO) {
            if (!imageModalState.video_url.length) {
                return dispatch(showToast("영상 링크가 없습니다."));
            }
        } else if (create_type === IMAGE_MODAL_TAB_NAMES.MANUAL) {
            if (!imageModalState.file_url.length) {
                return dispatch(showToast("이미지 파일을 첨부해주세요."));
            }
        }

        // URL 이미지 등록 유효성검사 제거
        // else {
        //     if (!imageModalState.imageExternalUrl.length) {
        //         return dispatch(showToast("이미지 링크를 삽입해주세요."));
        //     }
        // }
        const is_video = create_type === IMAGE_MODAL_TAB_NAMES.VIDEO;
        dispatch(removeCloseFunc());
        if (imageData) {
            updateImageMutation.mutate({
                imageId: imageData.id,
                param: { ...imageModalState, is_video },
            });
        } else {
            createImageMutation.mutate({ ...imageModalState, is_video });
        }
    };
    const onUrlInputChange = (e) => {
        dispatch(updateImageModalState({ url: e.target.value }));
    };
    const onRadioChange = (e) => {
        dispatch(updateImageModalState({ set_target: e.target.value }));
    };
    const onBannerTextsInputChange = (e, index) => {
        const value = e.target.value;
        // if (value.length > 1000) {
        //     setBlockSave(true);
        // } else {
        //     setBlockSave(false);
        // }
        const texts = [...imageModalState.texts];
        texts[index] = value;
        dispatch(updateImageModalState({ texts }));
    };

    const removeRow = (index) => {
        dispatch(
            updateImageModalState({
                texts: imageModalState.texts.filter((_, i) => i !== index),
            })
        );
    };

    const addRow = () => {
        dispatch(
            updateImageModalState({
                texts: [...imageModalState.texts, ""],
            })
        );
    };

    return (
        <ModalBody id="imageManageModalContent">
            <div className="regist_tab">
                <ul className="tab_in">
                    <li
                        className={
                            create_type === IMAGE_MODAL_TAB_NAMES.MANUAL
                                ? "on"
                                : ""
                        }
                    >
                        <button
                            type="button"
                            onClick={(e) =>
                                changeTabMenu(IMAGE_MODAL_TAB_NAMES.MANUAL)
                            }
                        >
                            이미지 직접 등록
                        </button>
                    </li>
                    <li
                        className={
                            create_type === IMAGE_MODAL_TAB_NAMES.URL
                                ? "on"
                                : ""
                        }
                    >
                        <button
                            type="button"
                            onClick={(e) =>
                                changeTabMenu(IMAGE_MODAL_TAB_NAMES.URL)
                            }
                        >
                            URL로 이미지 등록
                        </button>
                    </li>
                    <li
                        className={
                            create_type === IMAGE_MODAL_TAB_NAMES.VIDEO
                                ? "on"
                                : ""
                        }
                    >
                        <button
                            type="button"
                            onClick={(e) =>
                                changeTabMenu(IMAGE_MODAL_TAB_NAMES.VIDEO)
                            }
                        >
                            영상 등록
                        </button>
                    </li>
                </ul>
            </div>
            {create_type === IMAGE_MODAL_TAB_NAMES.MANUAL ? (
                <ImageManualUpload
                    onUrlInputChange={onUrlInputChange}
                    onRadioChange={onRadioChange}
                    onBannerTextsInputChange={onBannerTextsInputChange}
                    removeRow={removeRow}
                    addRow={addRow}
                />
            ) : create_type === IMAGE_MODAL_TAB_NAMES.URL ? (
                <ImageURLUpload
                    onUrlInputChange={onUrlInputChange}
                    onRadioChange={onRadioChange}
                    onBannerTextsInputChange={onBannerTextsInputChange}
                    removeRow={removeRow}
                    addRow={addRow}
                    imgError={imgError}
                    setImgError={setImgError}
                />
            ) : (
                <VideoUpload />
            )}
            <div className="save_btn">
                <StyledRoundButton
                    onClick={saveImage}
                    size={"l16"}
                    isDisabled={
                        createImageMutation.isLoading ||
                        updateImageMutation.isLoading
                    }
                    type={"fill"}
                >
                    저장
                </StyledRoundButton>
            </div>
        </ModalBody>
    );
};

const StyledRoundButton = styled(RoundButton)`
    background-color: var(--primary-500);
    border-color: var(--primary-500);
`;

export default ImageManageModal;
