import { createSlice, current } from "@reduxjs/toolkit";
import {
    EXPOSURE_ORDERS,
    BANNER_SORT_TYPES,
    DEFAULT_CUSTOM_CODE,
} from "../constants";

//TODO custom 라디오 선택 시 프리미엄 팝업
const initialState = {
    group_name: "",
    group_describe: "",
    exposure_order: EXPOSURE_ORDERS.IN_ORDER,
    banner_sort: BANNER_SORT_TYPES.SLIDE,
    is_repeat: false,
    is_speed: false,
    speed: 3,
    banner_code: DEFAULT_CUSTOM_CODE,
    swiper_config: "",
    use_swiper_config: false,
    isUpdate: false,
};

const bannerGroupSlice = createSlice({
    name: "bannerGroup",
    initialState: initialState,
    reducers: {
        updateBannerGroupState: (state, action) => {
            Object.assign(state, { ...action.payload });
        },
        resetBannerGroupState: (state) => {
            Object.assign(state, { ...initialState });
        },
    },
});

export const { resetBannerGroupState, updateBannerGroupState } =
    bannerGroupSlice.actions;
export default bannerGroupSlice.reducer;
