import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { openModal } from "../../redux/ModalSlice";
import { useDispatch } from "react-redux";
import { RoundButton } from "../common";
import paymentManager from "../../apis/paymentManager";
import { MODAL_PAYLOAD } from "../../constants";
import { useQueryClient } from "@tanstack/react-query";

const SubscribeDimmer = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const clickSubscribe = async () => {
        const paymentInfo = queryClient.getQueryData(["paymentInfo"]);
        const userName = paymentInfo.mall_id;
        const props = JSON.parse(JSON.stringify(MODAL_PAYLOAD.USER_PLAN_INFO));
        props.modalProps.title = `${userName}님의 결제 정보 안내`;
        dispatch(openModal({ ...props }));
    };
    return (
        <SubscribeDimmerContainer>
            <RoundButton onClick={clickSubscribe} type={"fill"} size={"l"}>
                구독하기
            </RoundButton>
            <p>를 누르면 다시 이용하실 수 있어요.</p>
        </SubscribeDimmerContainer>
    );
};

const SubscribeDimmerContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;
    background: rgba(115, 134, 232, 0.05);
    backdrop-filter: blur(12.5px);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.01em;
        color: #515cda;
    }
`;
export default SubscribeDimmer;
