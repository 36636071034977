import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import IcInfo from "../../assets/images/icons/ic-info.svg";

const InfoLabel = ({ text, onClick }) => {
    return (
        <InfoLabelContainer onClick={onClick}>
            <img src={IcInfo} alt="icon" />
            <span>{text}</span>
        </InfoLabelContainer>
    );
};

const InfoLabelContainer = styled.div`
    cursor: pointer;
    height: 22px;
    background: var(--gray-700);
    border-radius: 50px;
    padding-right: 8px;
    padding-left: 3px;
    display: flex;
    align-items: center;
    gap: 4px;

    span {
        display: block;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #ffffff;
    }
`;

InfoLabel.propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};
InfoLabel.defaultProps = {};
export default InfoLabel;
