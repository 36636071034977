import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ToggleSwitch = ({ onChange, value, id }) => {
    return (
        <ToggleContainer>
            <input
                type="checkbox"
                id={id}
                value={value}
                defaultChecked={value}
                onChange={onChange}
            />
            <Label className={value ? "active" : ""} htmlFor={id}>
                <ToggleSwitchBtn />
            </Label>
        </ToggleContainer>
    );
};

const ToggleContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    input {
        position: absolute;
        height: 0;
        width: 0;
        visibility: hidden;
        &:checked + label {
            span {
                left: calc(100% - 3px);
                transform: translateX(-100%);
            }
        }
    }
`;

const ToggleSwitchBtn = styled.span`
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transition: all 0.2s ease 0s;
    background: rgb(255, 255, 255);
`;

const Label = styled.label`
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    cursor: pointer;
    width: 46px;
    height: 26px;
    background: rgba(232, 234, 251, 1);
    border-radius: 39px;
    position: relative;
    transition: background-color 0.2s ease 0s;

    &::after {
        content: none !important;
    }
    &::before {
        content: none !important;
    }
    &.active {
        // 특정 클래스 active 라고 지어줌
        background: rgba(115, 134, 232, 1);
    }
`;

ToggleSwitch.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
};
ToggleSwitch.defaultProps = {};
export default ToggleSwitch;
