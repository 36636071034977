import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isOpen: false,
    callback: null,
    selectedDate: new Date(),
    times: {
        hours: 0,
        minutes: 0,
    },
    startDateLimit: null,
    endDateLimit: null,
    timeType: "am",
};

const datePickerModalSlice = createSlice({
    name: "datePickerModal",
    initialState,
    reducers: {
        openDatePicker: (state, action) => {
            state.isOpen = true;
            state.callback = action.payload.callback;
        },
        updateDateState: (state, action) => {
            Object.assign(state, { ...action.payload });
        },
        closeDatePicker: (state) => {
            Object.assign(state, { ...initialState });
        },
    },
});

export const { updateDateState, openDatePicker, closeDatePicker } =
    datePickerModalSlice.actions;
export default datePickerModalSlice.reducer;
