import React from "react";
import styled from "styled-components";
import { ModalButtonWrap, ModalBody } from "./Modal";
import { RoundButton } from "../common";
import paymentManager from "../../apis/paymentManager";
import { showToast } from "../../redux/ToastSlice";
import { useDispatch } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import SadFace from "../../assets/images/sad-face.png";
import "./style/_expire-trail-modal.scss";
import { EXPIRE_TRAIL_INFO_URL, FAQ_URL } from "../../constants";

const ExpireTrailInfoModal = () => {
    const dispatch = useDispatch();
    const { data: paymentInfo } = useQuery({
        queryKey: ["paymentInfo"],
        queryFn: paymentManager.getPayment,
    });
    const onClickContact = async () => {
        try {
            const result = await paymentManager.createPayment({
                redirect_url: window.location.href,
            });
            window.open(result, "_blank");
        } catch (e) {
            console.log(e);
            dispatch(showToast("에러가 발생했습니다.\n이너뷰에 문의해주세요."));
        }
    };

    const onClickContactDetail = () => {
        window.open(EXPIRE_TRAIL_INFO_URL, "_blank");
    }
    return (
        <ModalBody id={"ExpireTrailModal"}>
            <ImgWrapping><img src={SadFace} alt="icon" /></ImgWrapping>
            <ModalTextTitle>무료 기능 지원 종료 안내</ModalTextTitle>
            <ModalText>
                배너관리앱이 <span className={"point"}>8월 13일(화)</span>부터 <span className={"line"}>유료로 전환</span>됩니다.<br />
                가격이 인상되기 전, 지금 바로 구독해 주세요!
            </ModalText>
            <ModalButtonWrap className={"btn-wrap"}>
                <RoundButton type={"outline"} onClick={onClickContactDetail}>자세히 보기</RoundButton>
                <RoundButton onClick={onClickContact}>구독하기</RoundButton>
            </ModalButtonWrap>
        </ModalBody>
    );
};



const ImgWrapping = styled.div`
    text-align: center;
    img{width: 56px; height: 56px;}
`;

const ModalTextTitle = styled.h1`
    padding-top: 20px;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.01em;
    color: #282B39;
    text-align: center;
`;

const ModalText = styled.p`
    margin-top: 12px;
    margin-bottom: 8px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #696C77;
    white-space: pre-line;
    span{
        font-weight: 700;
    }
    .point{
        color: #7386E8;
    }
    .line{
        text-decoration: underline;
    }
`;

export default ExpireTrailInfoModal;
