import { instance } from "./index";

const getProducts = (params) => {
    let payload = {
        page: params.currPage
    };
    if (params.searchText.length) {
        payload[params.searchType] = params.searchText;
    }
    const { checkOptions } = params;
    if (checkOptions.selling_T !== checkOptions.selling_F) {
        payload.selling = checkOptions.selling_T ? "T" : "F";
    }
    if (checkOptions.display_T !== checkOptions.display_F) {
        payload.display = checkOptions.display_T ? "T" : "F";
    }
    return instance.get(`/product`, {
        params: {
            since_product_no: 0,
            ...payload,
        },
    });
};

const getLinkedProducts = (bannerId) => {
    return instance.get(`/product/registered/${bannerId}`);
};

const productManager = {
    getProducts,
    getLinkedProducts,
};

export default productManager;
