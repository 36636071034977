import PropTypes from "prop-types";
import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

import IconSelectArrow from "../../../assets/images/icons/ic-select-arrow.svg";

const SELECT_BOX_PROPS_BY_SIZE = {
    l: {
        width: "240px",
        BoxHeight: "40px",
        lineHeight: "22px",
        padding: "0 16px",
        fontSize: "14px",
        optionHeight: "34px",
    },
    m: {
        width: "160px",
        BoxHeight: "36px",
        lineHeight: "22px",
        padding: "0 16px",
        fontSize: "14px",
        optionHeight: "32px",
    },
    s: {
        width: "80px",
        BoxHeight: "32px",
        lineHeight: "20px",
        padding: "0 12px",
        fontSize: "13px",
        optionHeight: "28px",
    },
};

const SelectBox = ({
    options,
    onSelect,
    size,
    isDisabled,
    initialValue,
    placeholder,
    value,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(value || "");

    const selectBoxRef = useRef(null);

    useEffect(() => {
        if (initialValue) {
            setSelectedOption(options[0].label);
        }
        const handleClickOutside = (event) => {
            if (
                selectBoxRef.current &&
                !selectBoxRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleOptionClick = (option) => {
        setSelectedOption(option.label);
        setIsOpen(false);
        onSelect(option.value);
    };

    const toggleOptions = () => {
        setIsOpen(!isOpen);
    };

    return (
        <SelectBoxWrapper ref={selectBoxRef} $size={size}>
            <SelectedOption
                $size={size}
                onClick={toggleOptions}
                className={isOpen ? "open" : ""}
            >
                <span
                    className={`option-name ${
                        !selectedOption ? "not-selected" : ""
                    }`}
                >
                    {selectedOption || placeholder}
                </span>
                <ArrowIcon $size={size} className={isOpen ? "open" : ""}>
                    <img src={IconSelectArrow} alt="select icon" />
                </ArrowIcon>
            </SelectedOption>
            {isOpen && (
                <OptionListWrap>
                    <OptionList className={isOpen ? "open" : ""} $size={size}>
                        {options.map((option, index) => (
                            <OptionItem
                                $size={size}
                                key={index}
                                onClick={() => handleOptionClick(option)}
                            >
                                {option.label}
                            </OptionItem>
                        ))}
                    </OptionList>
                </OptionListWrap>
            )}
        </SelectBoxWrapper>
    );
};

const SelectBoxWrapper = styled.div`
    position: relative;
    width: ${(props) => SELECT_BOX_PROPS_BY_SIZE[props.$size].width};
`;

const SelectedOption = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    padding: ${(props) => SELECT_BOX_PROPS_BY_SIZE[props.$size].padding};
    background: #ffffff;
    border: 1px solid var(--gray-100);
    height: ${(props) => SELECT_BOX_PROPS_BY_SIZE[props.$size].BoxHeight};
    border-radius: 3px;
    cursor: pointer;

    span.option-name {
        font-weight: 400;
        font-size: ${(props) => SELECT_BOX_PROPS_BY_SIZE[props.$size].fontSize};
        line-height: ${(props) =>
            SELECT_BOX_PROPS_BY_SIZE[props.$size].lineHeight};
        letter-spacing: -0.01em;
        color: var(--gray-black);

        &.not-selected {
            color: var(--gray-400);
        }

        padding-right: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &.open {
        border-color: var(--primary-400);
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
`;

const ArrowIcon = styled.div`
    position: absolute;
    right: ${(props) => (props.$size === "s" ? "10px" : "14px")};
    top: 50%;
    opacity: 0.4;
    transform: translate(0, -50%) rotate(180deg);
    transition: transform 0.2s ease-in-out;
    img {
        display: block;
    }
    &.open {
        transform: translate(0, -50%);
    }
`;

const OptionListWrap = styled.div`
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    z-index: 10;
`;

const OptionList = styled.ul`
    width: 100%;
    padding: 0;
    background-color: #fff;
    border: 1px solid var(--primary-400);
    border-top: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    max-height: 160px;
    overflow-y: auto;
`;

const OptionItem = styled.li`
    transition: all 0.2s ease-in-out;
    display: block;
    align-items: center;
    height: ${(props) => SELECT_BOX_PROPS_BY_SIZE[props.$size].optionHeight};
    padding: ${(props) => SELECT_BOX_PROPS_BY_SIZE[props.$size].padding};
    cursor: pointer;
    color: var(--gray-700);
    font-weight: 400;
    font-size: ${(props) => SELECT_BOX_PROPS_BY_SIZE[props.$size].fontSize};
    line-height: ${(props) =>
        SELECT_BOX_PROPS_BY_SIZE[props.$size].optionHeight};
    letter-spacing: -0.01em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        background-color: var(--primary-100);
        color: var(--gray-black);
    }
`;

SelectBox.propTypes = {
    options: PropTypes.array.isRequired,
    onSelect: PropTypes.func,
    size: PropTypes.oneOf(["l", "m", "s"]),
    isDisabled: PropTypes.bool,
    placeholder: PropTypes.string,
};
SelectBox.defaultProps = {
    size: "l",
    isDisabled: false,
    placeholder: "옵션을 선택해주세요.",
};

export default SelectBox;
