import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { RoundButton } from "../common";
import { useDispatch, useSelector } from "react-redux";
import {
    closeDatePicker,
    updateDateState,
} from "../../redux/DatePickerModalSlice";
import { ko } from "date-fns/locale";

import moment from "moment";

import IcCalendar from "../../assets/images/icons/ic-calendar.svg";
import IcClock from "../../assets/images/icons/ic-clock.svg";
import DatePicker from "react-datepicker";

const currentDate = new Date();
const oneYearLater = new Date(
    currentDate.getFullYear() + 1,
    currentDate.getMonth(),
    currentDate.getDate()
);
let oneMonthAgo;
if (currentDate.getMonth() === 0) {
    oneMonthAgo = new Date(
        currentDate.getFullYear() - 1,
        11,
        currentDate.getDate()
    );
} else {
    oneMonthAgo = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 1,
        currentDate.getDate()
    );
}
//TODO:: 시작일이 설정되어있으면 시작일기준, 종료일이 설정되어있으면 종료일기준으로 선택안되게
const DatePickerModal = ({ className }) => {
    const dispatch = useDispatch();
    const {
        isOpen,
        callback,
        selectedDate,
        times,
        timeType,
        startDateLimit,
        endDateLimit,
    } = useSelector((state) => state.datePicker);
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Escape" && isOpen) {
                handleClose();
            }
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [isOpen, dispatch]);

    const setSelectedDate = (date) => {
        dispatch(updateDateState({ selectedDate: date }));
    };
    const setTimes = (times) => {
        dispatch(updateDateState({ times }));
    };
    const setTimeType = (type) => {
        dispatch(updateDateState({ timeType: type }));
    };

    const handleClose = () => {
        dispatch(closeDatePicker());
    };

    //TODO:: 예외처리
    const submit = () => {
        callback({ selectedDate, times, timeType });
    };

    const onChangeInput = (e, limit, key) => {
        if (e.target.value > limit) {
            e.target.value = limit;
        }
        if (e.target.value.length > e.target.maxLength) {
            e.target.value = e.target.value.slice(0, e.target.maxLength);
        }

        setTimes({ ...times, [key]: e.target.value });
    };

    const changeTimeType = (timeType) => {
        setTimeType(timeType);
    };

    if (!isOpen) {
        return null;
    }

    return (
        <Dimmer className={className}>
            <DatePickerContainer>
                <SectionWrap>
                    <div className="dp-title-wrap">
                        <div className="icon-wrap">
                            <img src={IcCalendar} alt="icon" />
                        </div>
                        <h2>날짜</h2>
                    </div>
                    <DatePickerComponentWrap>
                        <DatePicker
                            inline
                            formatWeekDay={(nameOfDay) =>
                                nameOfDay.substring(0, 1)
                            }
                            dateFormat="yyyy-MM-dd"
                            dateFormatCalendar={"yyyy년 MM월"}
                            minDate={startDateLimit || oneMonthAgo}
                            maxDate={endDateLimit || oneYearLater}
                            showMonthYearDropdown
                            selected={startDateLimit || selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                        />
                    </DatePickerComponentWrap>
                </SectionWrap>
                <SectionWrap>
                    <div className="dp-title-wrap">
                        <div className="icon-wrap">
                            <img src={IcClock} alt="icon" />
                        </div>
                        <h2>시간</h2>
                    </div>

                    <TimeSelectorWrap>
                        <div className="time-input-wrap">
                            <input
                                type="number"
                                maxLength={2}
                                value={times.hours}
                                onChange={(e) => onChangeInput(e, 12, "hours")}
                            />
                            <span>:</span>
                            <input
                                type="number"
                                maxLength={2}
                                value={times.minutes}
                                onChange={(e) =>
                                    onChangeInput(e, 59, "minutes")
                                }
                            />
                        </div>
                        <div className="day-night-button-wrap">
                            <button
                                className={`day-night-btn ${
                                    timeType === "am" ? "active" : ""
                                }`}
                                onClick={(e) => changeTimeType("am")}
                            >
                                오전
                            </button>
                            <button
                                className={`day-night-btn ${
                                    timeType === "pm" ? "active" : ""
                                }`}
                                onClick={(e) => changeTimeType("pm")}
                            >
                                오후
                            </button>
                        </div>
                    </TimeSelectorWrap>
                </SectionWrap>
                <ButtonWrap>
                    <CustomOutlineButton
                        onClick={handleClose}
                        size={"l16"}
                        type={"outline"}
                    >
                        취소
                    </CustomOutlineButton>
                    <CustomFillButton
                        onClick={submit}
                        size={"l16"}
                        type={"fill"}
                    >
                        확인
                    </CustomFillButton>
                </ButtonWrap>
            </DatePickerContainer>
        </Dimmer>
    );
};

const DatePickerComponentWrap = styled.div`
    width: 100%;
    background: #f9f9fa;
    border-radius: 4px;
    padding-bottom: 16px;
`;

const TimeSelectorWrap = styled.div`
    padding: 24px 20px;
    background: #f9f9fa;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 12px;

    .time-input-wrap {
        display: flex;
        gap: 6px;
        align-items: center;
        span {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            text-align: center;
            letter-spacing: -0.01em;
            color: #5d5e67;
        }
        input {
            transition: all 0.25s ease-in-out;
            width: 100px;
            height: 60px;
            background: #ffffff;
            border: 1px solid #dfe0e6;
            border-radius: 4px;
            text-align: center;
            font-size: 20px;
            color: #282b39;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            -moz-appearance: textfield;

            &:focus {
                border: 1px solid #9da9ee;
                box-shadow: 0px 0px 10px rgba(115, 134, 232, 0.15);
            }
        }
    }

    .day-night-button-wrap {
        button.day-night-btn {
            transition: all 0.25s ease-in-out;
            display: block;
            width: 42px;
            height: 28px;
            border: 1px solid #dfe0e6;

            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: -0.01em;
            color: #afb2c1;

            &:first-of-type {
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
            }
            &:last-of-type {
                border-top: 0;
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 3px;
            }

            &.active {
                color: #fff;
                background: #7386e8;
                border-color: #7386e8;
            }
        }
    }
`;

const CustomDatePicker = styled(DatePicker)`
    width: 100%;
`;

const CustomOutlineButton = styled(RoundButton)`
    width: 50%;
    border-color: var(--primary-500);
    color: var(--primary-500);
`;

const CustomFillButton = styled(RoundButton)`
    width: 50%;
    background-color: var(--primary-500);
    border-color: var(--primary-500);
`;

const SectionWrap = styled.section`
    margin-top: 20px;

    .dp-title-wrap {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        gap: 6px;
        .icon-wrap {
            width: 32px;
            height: 32px;
            background: #eff1fa;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                display: block;
            }
        }
        h2 {
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.01em;
            color: #7386e8;
        }
    }
`;

const ButtonWrap = styled.section`
    padding: 32px 0;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
`;

const Dimmer = styled.div`
    position: fixed;
    z-index: 110;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const DatePickerContainer = styled.article`
    box-sizing: border-box;
    width: 360px;
    padding: 0 24px;
    box-shadow: 0 0 10px rgba(98, 120, 233, 0.1);
    background-color: #fff;
    border: 1px solid #cbcbcb;
`;

export default DatePickerModal;
