import React, { useEffect } from "react";
import styled from "styled-components";
import { RoundButton } from "../../components/common";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openModal } from "../../redux/ModalSlice";
import { MODAL_PAYLOAD } from "../../constants";
import moment from "moment";
//push

const NoContents = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const bannerManual = () => {
        navigate("/tutorial");
    };

    useEffect(() => {
        const currentDate = moment();

        // 11월 3일 날짜 만들기
        const november3 = moment("2023-11-03");

        // 현재 날짜가 11월 3일 이후인지 확인
        if (currentDate.isAfter(november3)) {
            //TODO 11월 3일 이후 이 코드 삭제
        } else {
            dispatch(openModal({ ...MODAL_PAYLOAD.WARNING }));
        }
    }, []);

    return (
        <NoContentsWrap>
            <p>배너를 관리할 폴더를 선택하면 배너 등록이 가능합니다.</p>
            <RoundButton onClick={bannerManual}>
                배너 관리 앱 사용법
            </RoundButton>
        </NoContentsWrap>
    );
};

const NoContentsWrap = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    gap: 24px;

    p {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        color: var(--gray-700);
    }
`;
export default NoContents;
