const ROUTES = {
    ROOT: {
        path: "/",
    },
    FOLDER_DETAIL: {
        path: "folder/:folderId",
    },
    BANNER_GROUP_CREATE: {
        path: "banner-group",
    },
    BANNER_GROUP_UPDATE: {
        path: "banner-group/:bannerId",
    },
    TUTORIAL: {
        path: "tutorial",
    },
    MAINTENANCE: {
        path: "maintenance",
    },
};

export default ROUTES;
