import React, { useState } from "react";
import styled from "styled-components";

import { openModal } from "../../redux/ModalSlice";
import { showToast } from "../../redux/ToastSlice";
import { useDispatch } from "react-redux";
import { openConfirm, closeConfirm } from "../../redux/ConfirmSlice";
import { MODAL_PAYLOAD, CONFIRM_TEXT } from "../../constants";
import { setGlobalLoading } from "../../redux/GlobalLoadingSlice";

import { Radio, RoundButton, ToggleSwitch } from "../../components/common";
import IcPlus from "../../assets/images/icons/ic-plus-no-circle@2x.png";
import IcDrag from "../../assets/images/icons/ic-drag@2x.png";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import imageManager from "../../apis/imageManager";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import IcCalendar from "../../assets/images/icons/ic-calendar@2x.png";
import {
    closeDatePicker,
    openDatePicker,
    updateDateState,
} from "../../redux/DatePickerModalSlice";
import moment from "moment";

import NoImgImg from "../../assets/images/no-img@2x.png";
import { filter } from "lodash";

const ImageManage = () => {
    const urlParams = useParams();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const [selectedImages, setSelectedImages] = useState([]);
    const [checkItems, setCheckItems] = useState([]);
    const { data: imageData, error } = useQuery({
        queryKey: ["bannerImages", { bannerId: urlParams?.bannerId }],
        queryFn: async ({ queryKey }) => {
            if (queryKey.length < 2) return null;
            const { bannerId } = queryKey[1];
            return await imageManager.getImages(bannerId);
        },
    });
    const updateImageOrderMutate = useMutation(imageManager.updateImageGroup, {
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["bannerImages", { bannerId: urlParams.bannerId }],
            });
            dispatch(showToast("이미지 순서가 변경되었습니다."));
            dispatch(setGlobalLoading(false));
        },
        onError: () => {
            dispatch(setGlobalLoading(false));
        },
    });
    const updateImageMutate = useMutation(imageManager.updateImage, {
        onSuccess: (e) => {
            queryClient.invalidateQueries({
                queryKey: ["bannerImages", { bannerId: urlParams.bannerId }],
            });
            dispatch(showToast("이미지 상태가 변경되었습니다."));
            dispatch(closeDatePicker());
            dispatch(setGlobalLoading(false));
        },
        onError: () => {
            dispatch(setGlobalLoading(false));
        },
    });
    const removeImageMutate = useMutation(imageManager.removeImage, {
        onSuccess: () => {
            setCheckItems([]);
            queryClient.invalidateQueries({
                queryKey: ["bannerImages", { bannerId: urlParams.bannerId }],
            });
            dispatch(showToast("배너 이미지가 삭제되었습니다."));
            dispatch(closeConfirm());
            dispatch(setGlobalLoading(false));
        },
        onError: () => {
            dispatch(setGlobalLoading(false));
        },
    });

    const removeImages = (imageIds) => {
        const options = {
            confirmText: "삭제",
            text: CONFIRM_TEXT.IMAGE_REMOVE_CONFIRM_TEXT,
            confirmFunc: () => {
                dispatch(setGlobalLoading(true));
                removeImageMutate.mutate({
                    ids: imageIds,
                    BannerId: urlParams.bannerId,
                });
            },
        };
        dispatch(openConfirm(options));
    };

    const openDateTimeSelector = (image, type) => {
        const OPPOSITE_TYPE = {
            period_end: "period_start",
            period_start: "period_end",
        };
        if (image[type] !== null) {
            const dateObj = moment(image[type]);
            const selectedDate = new Date(dateObj.format("YYYY-MM-DD"));
            const hours = dateObj.format("HH");
            const minutes = dateObj.format("mm");
            const amOrPm = dateObj.format("a");

            dispatch(
                updateDateState({
                    selectedDate,
                    times: {
                        hours,
                        minutes,
                    },
                    timeType: amOrPm,
                })
            );
        }

        if (image[OPPOSITE_TYPE[type]] !== null) {
            let obj = {};
            const oppositeDate = moment(image[OPPOSITE_TYPE[type]]);
            if (OPPOSITE_TYPE[type] === "period_start") {
                obj.startDateLimit = new Date(
                    oppositeDate.format("YYYY-MM-DD")
                );
            } else {
                obj.endDateLimit = new Date(oppositeDate.format("YYYY-MM-DD"));
            }
            dispatch(updateDateState(obj));
        }

        dispatch(
            openDatePicker({
                callback: (params) => updateDateAndTime(image, type, params),
            })
        );
    };

    const updateDateAndTime = (image, type, params) => {
        let formattedDate = params.selectedDate.toISOString().split("T")[0];

        let formattedTime = moment({
            hour: params.times.hours,
            minute: params.times.minutes,
        });

        if (params.timeType === "pm" && params.times.hours !== 12) {
            formattedTime = formattedTime.add(12, "hours");
        } else if (params.timeType === "am" && params.times.hours === 12) {
            formattedTime = formattedTime.subtract(12, "hours");
        }
        formattedTime = formattedTime.format("HH:mm");
        const fullDate = formattedDate + " " + formattedTime;
        const payload = {
            imageId: image.id,
            param: {
                [type]: fullDate,
            },
        };
        dispatch(setGlobalLoading(true));
        updateImageMutate.mutate({ ...payload });
    };

    const openImageCreateModal = () => {
        let props = JSON.parse(JSON.stringify(MODAL_PAYLOAD.IMAGE_MANAGE));
        props.modalProps.bannerId = urlParams.bannerId;
        dispatch(
            openModal({
                ...props,
                closeFunc: (closeCallback, isInitialized) => {
                    if (isInitialized) {
                        dispatch(
                            openConfirm({
                                confirmText: "확인",
                                text: "작성중인 내용이 있습니다.\n페이지를 벗어나시겠습니까?",
                                confirmFunc: () => {
                                    dispatch(closeConfirm());
                                    closeCallback();
                                },
                            })
                        );
                    } else {
                        closeCallback();
                    }
                },
            })
        );
    };

    const openImageUpdateModal = (image) => {
        let props = JSON.parse(JSON.stringify(MODAL_PAYLOAD.IMAGE_MANAGE));
        props.modalProps.bannerId = urlParams.bannerId;
        props.modalProps.imageData = image;
        dispatch(
            openModal({
                ...props,
                closeFunc: (closeCallback, isInitialized) => {
                    if (isInitialized) {
                        dispatch(
                            openConfirm({
                                confirmText: "확인",
                                text: "작성중인 내용이 있습니다.\n페이지를 벗어나시겠습니까?",
                                confirmFunc: () => {
                                    dispatch(closeConfirm());
                                    closeCallback();
                                },
                            })
                        );
                    } else {
                        closeCallback();
                    }
                },
            })
        );
    };

    const onChangeExposure = (e, item) => {
        const payload = {
            imageId: item.id,
            param: {
                exposure: e.target.checked,
            },
        };
        dispatch(setGlobalLoading(true));
        updateImageMutate.mutate({ ...payload });
    };

    const updateExposurePeriod = (e, item) => {
        const payload = {
            imageId: item.id,
            param: {
                exposure_period: e.target.value,
            },
        };
        dispatch(setGlobalLoading(true));
        updateImageMutate.mutate({ ...payload });
    };

    const toggleAllCheck = (e) => {
        const check = e.target.checked;
        if (check) {
            const ids = imageData.map((image) => image.id);
            setCheckItems(ids);
        } else {
            setCheckItems([]);
        }
    };

    const removeCheckItems = () => {
        removeImages(checkItems);
    };

    const onCheckImageItems = (e, id) => {
        if (e.target.checked) {
            setCheckItems((prevCheckItems) => [...prevCheckItems, id]);
        } else {
            setCheckItems((prevCheckItems) =>
                prevCheckItems.filter((item) => item !== id)
            );
        }
    };

    const dragItem = (result) => {
        if (!result.destination) {
            return;
        }
        const fromIndex = result.source.index;
        const toIndex = result.destination.index;

        if (fromIndex === toIndex) {
            return;
        }
        dispatch(setGlobalLoading(true));
        let images = imageData;
        const itemToMove = images[fromIndex];
        images.splice(fromIndex, 1);
        images.splice(toIndex, 0, itemToMove);
        const filteredImages = images.map((img, index) => {
            return { id: img.id, order: index };
        });
        updateImageOrderMutate.mutate({ images: filteredImages });
    };

    return (
        <div className={`set_sction iv_box`}>
            {/*<Loading isLoading={isLoading || updateImageMutate.isLoading} />*/}
            <div className="bnr_regist">
                <div className="regist_btn">
                    <button
                        type="button"
                        className="select_remove"
                        disabled={checkItems.length < 1}
                        onClick={removeCheckItems}
                    >
                        선택 삭제
                    </button>
                    <RoundButton
                        type={"fill"}
                        size={"m"}
                        icon={IcPlus}
                        onClick={openImageCreateModal}
                    >
                        이미지 등록
                    </RoundButton>
                </div>
                <DragDropContext onDragEnd={dragItem}>
                    <div className="regist_table">
                        <table role="table">
                            <thead>
                                <tr role="row">
                                    <th
                                        colSpan="1"
                                        role="columnheader"
                                        style={{ width: "50px" }}
                                    >
                                        <div className="chkAll_box">
                                            <input
                                                type="checkbox"
                                                id="chkAll"
                                                className={"table-checkbox"}
                                                checked={
                                                    checkItems.length > 0 &&
                                                    imageData?.length ===
                                                        checkItems.length
                                                }
                                                onChange={toggleAllCheck}
                                            />
                                            <label htmlFor="chkAll"></label>
                                        </div>
                                    </th>
                                    <th
                                        colSpan="1"
                                        role="columnheader"
                                        style={{ width: "32px" }}
                                    >
                                        No
                                    </th>
                                    <th
                                        colSpan="1"
                                        role="columnheader"
                                        style={{ width: "200px" }}
                                    >
                                        이미지
                                    </th>
                                    <th
                                        colSpan="1"
                                        role="columnheader"
                                        style={{ width: "60px" }}
                                    >
                                        사용 여부
                                    </th>
                                    <th
                                        colSpan="1"
                                        role="columnheader"
                                        style={{ width: "110px" }}
                                    >
                                        노출 방식
                                    </th>
                                    <th
                                        colSpan="1"
                                        role="columnheader"
                                        style={{ width: "230px" }}
                                    >
                                        노출 기간
                                    </th>
                                    <th
                                        colSpan="1"
                                        role="columnheader"
                                        style={{ width: "60px" }}
                                    >
                                        노출 수
                                    </th>
                                    <th
                                        colSpan="1"
                                        role="columnheader"
                                        style={{ width: "60px" }}
                                    >
                                        클릭 수
                                    </th>
                                    <th
                                        colSpan="1"
                                        role="columnheader"
                                        style={{ width: "60px" }}
                                    >
                                        클릭 률
                                    </th>
                                    <th
                                        colSpan="1"
                                        role="columnheader"
                                        style={{ width: "160px" }}
                                    >
                                        관리
                                    </th>
                                    <th
                                        colSpan="1"
                                        role="columnheader"
                                        style={{ width: "70px" }}
                                    >
                                        이동
                                    </th>
                                </tr>
                            </thead>
                            <Droppable droppableId="droppable">
                                {(provided) => (
                                    <tbody
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {imageData && imageData.length ? (
                                            <>
                                                {imageData.map(
                                                    (item, index) => (
                                                        <Draggable
                                                            key={item.id}
                                                            draggableId={item.id.toString()}
                                                            index={index}
                                                        >
                                                            {(provided) => (
                                                                <tr
                                                                    key={index}
                                                                    ref={
                                                                        provided.innerRef
                                                                    }
                                                                    {...provided.draggableProps}
                                                                >
                                                                    <td
                                                                        role="cell"
                                                                        style={{
                                                                            width: "30px",
                                                                        }}
                                                                    >
                                                                        <div className="body_chk">
                                                                            <input
                                                                                type="checkbox"
                                                                                id={`checkItem-${item.id}`}
                                                                                className={
                                                                                    "table-checkbox"
                                                                                }
                                                                                checked={
                                                                                    checkItems.indexOf(
                                                                                        item.id
                                                                                    ) >
                                                                                    -1
                                                                                }
                                                                                onChange={(
                                                                                    e
                                                                                ) =>
                                                                                    onCheckImageItems(
                                                                                        e,
                                                                                        item.id
                                                                                    )
                                                                                }
                                                                            />
                                                                            <label
                                                                                htmlFor={`checkItem-${item.id}`}
                                                                            ></label>
                                                                        </div>
                                                                    </td>
                                                                    <td
                                                                        role="cell"
                                                                        style={{
                                                                            width: "30px",
                                                                        }}
                                                                    >
                                                                        {index +
                                                                            1}
                                                                    </td>
                                                                    <td
                                                                        role="cell"
                                                                        style={{
                                                                            width: "auto",
                                                                        }}
                                                                    >
                                                                        <div className="img_in">
                                                                            {item.is_video ? (
                                                                                <ReactPlayer
                                                                                    url={
                                                                                        item.file_url
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    src={
                                                                                        item.file_url &&
                                                                                        item
                                                                                            .file_url
                                                                                            .length
                                                                                            ? item.file_url
                                                                                            : NoImgImg
                                                                                    }
                                                                                    alt="image"
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                    <td
                                                                        role="cell"
                                                                        style={{
                                                                            width: "80px",
                                                                        }}
                                                                    >
                                                                        <ToggleSwitch
                                                                            id={`toggleSwitch-${item.id}`}
                                                                            onChange={(
                                                                                e
                                                                            ) => {
                                                                                onChangeExposure(
                                                                                    e,
                                                                                    item
                                                                                );
                                                                            }}
                                                                            value={
                                                                                item.exposure
                                                                            }
                                                                        />
                                                                    </td>
                                                                    <td
                                                                        role="cell"
                                                                        style={{
                                                                            width: "80px",
                                                                            minWidth:
                                                                                "80px",
                                                                        }}
                                                                    >
                                                                        <div className="radios_wrap">
                                                                            <Radio
                                                                                name={`exposure-${item.id}`}
                                                                                id={`always-exposure-${item.id}`}
                                                                                label={
                                                                                    "상시 노출"
                                                                                }
                                                                                value={
                                                                                    false
                                                                                }
                                                                                isChecked={
                                                                                    !item.exposure_period
                                                                                }
                                                                                onChange={(
                                                                                    e
                                                                                ) =>
                                                                                    updateExposurePeriod(
                                                                                        e,
                                                                                        item
                                                                                    )
                                                                                }
                                                                            />
                                                                            <Radio
                                                                                name={`exposure-${item.id}`}
                                                                                id={`term-exposure-${item.id}`}
                                                                                value={
                                                                                    true
                                                                                }
                                                                                label={
                                                                                    "특정 기간"
                                                                                }
                                                                                isChecked={
                                                                                    item.exposure_period
                                                                                }
                                                                                onChange={(
                                                                                    e
                                                                                ) =>
                                                                                    updateExposurePeriod(
                                                                                        e,
                                                                                        item
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td
                                                                        role="cell"
                                                                        style={{
                                                                            width: "150px",
                                                                        }}
                                                                    >
                                                                        {item.exposure_period ? (
                                                                            <div className="date_in">
                                                                                <div
                                                                                    className="date-input start_date"
                                                                                    onClick={(
                                                                                        e
                                                                                    ) =>
                                                                                        openDateTimeSelector(
                                                                                            item,
                                                                                            "period_start"
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <img
                                                                                        src={
                                                                                            IcCalendar
                                                                                        }
                                                                                        alt="icon"
                                                                                    />
                                                                                    {item.period_start ? (
                                                                                        <span
                                                                                            className={
                                                                                                "date-text"
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                item.period_start
                                                                                            }
                                                                                        </span>
                                                                                    ) : (
                                                                                        <span
                                                                                            className={
                                                                                                "date-text placeholder"
                                                                                            }
                                                                                        >
                                                                                            yyyy/mm/dd/
                                                                                            hh:mm
                                                                                        </span>
                                                                                    )}
                                                                                </div>
                                                                                <div
                                                                                    className="date-input end_date"
                                                                                    onClick={(
                                                                                        e
                                                                                    ) =>
                                                                                        openDateTimeSelector(
                                                                                            item,
                                                                                            "period_end"
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <img
                                                                                        src={
                                                                                            IcCalendar
                                                                                        }
                                                                                        alt="icon"
                                                                                    />
                                                                                    {item.period_end ? (
                                                                                        <span className="date-text">
                                                                                            {
                                                                                                item.period_end
                                                                                            }
                                                                                        </span>
                                                                                    ) : (
                                                                                        <span className="date-text placeholder">
                                                                                            yyyy/mm/dd/
                                                                                            hh:mm
                                                                                        </span>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <span className="no-date">
                                                                                -
                                                                            </span>
                                                                        )}
                                                                    </td>
                                                                    <td
                                                                        role="cell"
                                                                        style={{
                                                                            width: "80px",
                                                                        }}
                                                                    >
                                                                        {
                                                                            item.exposure_amount
                                                                        }
                                                                    </td>
                                                                    <td
                                                                        role="cell"
                                                                        style={{
                                                                            width: "80px",
                                                                        }}
                                                                    >
                                                                        {
                                                                            item.clicked_amount
                                                                        }
                                                                    </td>
                                                                    <td
                                                                        role="cell"
                                                                        style={{
                                                                            width: "80px",
                                                                        }}
                                                                    >
                                                                        {
                                                                            item.click_rate
                                                                        }
                                                                    </td>
                                                                    <td
                                                                        role="cell"
                                                                        style={{
                                                                            width: "170px",
                                                                        }}
                                                                    >
                                                                        <div className="edit_remove">
                                                                            <button
                                                                                type="button"
                                                                                className="iv_btn edit_btn"
                                                                                onClick={() =>
                                                                                    openImageUpdateModal(
                                                                                        item
                                                                                    )
                                                                                }
                                                                            >
                                                                                수정
                                                                            </button>
                                                                            <button
                                                                                type="button"
                                                                                className="iv_btn remove_btn"
                                                                                onClick={() =>
                                                                                    removeImages(
                                                                                        [
                                                                                            item.id,
                                                                                        ]
                                                                                    )
                                                                                }
                                                                            >
                                                                                삭제
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                    <td
                                                                        role="cell"
                                                                        style={{
                                                                            width: "110px",
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="drg_in"
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <DragIcon>
                                                                                <img
                                                                                    src={
                                                                                        IcDrag
                                                                                    }
                                                                                    alt="icon"
                                                                                />
                                                                            </DragIcon>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </Draggable>
                                                    )
                                                )}
                                                {provided.placeholder}
                                            </>
                                        ) : (
                                            <tr>
                                                <td colSpan="11">
                                                    <p className="no_data">
                                                        [이미지 등록] 버튼을
                                                        눌러
                                                        <br />
                                                        배너 이미지를 등록해
                                                        주세요.
                                                    </p>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                )}
                            </Droppable>
                        </table>
                    </div>
                </DragDropContext>
            </div>
        </div>
    );
};

const DragIcon = styled.div`
    display: block;
    cursor: grab;
    img {
        display: block;
        width: 24px;
        height: 24px;
        pointer-events: none;
    }
`;

export default ImageManage;
