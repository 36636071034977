import React, { useState } from "react";
import { ModalButtonWrap, ModalBody } from "./Modal";
import styled from "styled-components";

import { useDispatch } from "react-redux";
import { showToast } from "../../redux/ToastSlice";
import { RoundButton } from "../../components/common";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FILE_TYPES } from "../../constants";

import folderManager from "../../apis/folderManager";

import IcLight from "../../assets/images/icons/ic-light@2x.png";
import "./style/_banner-restore-modal.scss";

const BannerRestoreModal = ({ title, handleCloseModal }) => {
    const [file, setFile] = useState({ name: "" });
    const [payload, setPayload] = useState();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const restoreFolderMutate = useMutation(folderManager.restoreFolder, {
        onSuccess: () => {
            dispatch(showToast("배너 복원이 완료되었습니다."));
            handleCloseModal();
            queryClient.invalidateQueries({ queryKey: ["getFolders"] });
        },
    });

    const onFileUpload = (e) => {
        const uploadFile = e.target.files[0];
        if (uploadFile.type !== FILE_TYPES.JSON) {
            return dispatch(showToast(".json 확장자만 업로드 가능합니다."));
        }
        setFile(uploadFile);
        const fileReader = new FileReader();
        fileReader.readAsText(uploadFile, "UTF-8");
        fileReader.onload = (e) => {
            let obj = {};
            obj.data = JSON.parse(e.target.result);
            setPayload(obj);
        };
    };

    const restoreFolder = () => {
        restoreFolderMutate.mutate(payload);
    };

    return (
        <ModalBody id="bannerRestoreModalContent" $title={title}>
            <div className="iv_txt">
                <ul>
                    <li>
                        <span>
                            <img src={IcLight} alt="아이콘" />
                        </span>
                        <p>
                            [파일첨부] 버튼을 눌러, 백업한 배너 파일(.json)을
                            업로드해 주세요.
                            <br />
                            폴더 단위로 배너 그룹, 그룹 내 이미지 경로가 다시
                            복원됩니다.
                        </p>
                    </li>
                </ul>
            </div>
            <div className="attach_wrap">
                <div className="backup_txt">
                    <span>백업 파일 업로드</span>
                </div>
                <div className="input_wrap">
                    <input
                        type="text"
                        readOnly
                        className="attach_in"
                        placeholder="선택된 파일 없음"
                        value={file.name}
                    />
                    <label
                        className="attach_btn"
                        htmlFor="restoreFileUploadBtn"
                    >
                        <span>파일 첨부</span>
                        <input
                            onChange={onFileUpload}
                            type="file"
                            id={"restoreFileUploadBtn"}
                        />
                    </label>
                </div>
            </div>
            <ModalButtonWrap>
                <RestoreButton
                    isDisabled={!payload || restoreFolderMutate.isLoading}
                    size={"l"}
                    onClick={restoreFolder}
                    type={"fill"}
                >
                    복원하기
                </RestoreButton>
            </ModalButtonWrap>
        </ModalBody>
    );
};

const RestoreButton = styled(RoundButton)`
    min-width: 172px;
`;

export default BannerRestoreModal;
