export const getCookies = (name) => {
    const cookieString = document.cookie;
    const cookies = cookieString.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + "=")) {
            return cookie.substring(name.length + 1);
        }
    }

    return null;
};

export const setCookie = (name, value, days) => {
    const expirationDate = days
        ? new Date(new Date().getTime() + days * 24 * 60 * 60 * 1000)
        : null;

    const cookieValue =
        encodeURIComponent(value) +
        (expirationDate ? "; expires=" + expirationDate.toUTCString() : "");
    document.cookie = name + "=" + cookieValue;
};
